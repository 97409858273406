import React, { useState, useRef, useEffect} from 'react';
import JoditEditor from 'jodit-react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogs } from '../../redux/slice/blogs';

const CreateNewBlog = ({ isCreate ,  setIsCreate,  setEditBlog, blogData , currentPage , sortBlog}) => {
    const loginData = JSON.parse(window.localStorage.getItem("login"));
    let token;
    let id;
    const dispatch = useDispatch();
    const blogid = blogData?.id
    const [title, setTitle] = useState('');
    const [content, setContent] = useState("");
    const [featuredImg, setFeaturedImg] = useState('');
    const navigate = useNavigate();
    const editor = useRef(null);

    const [isLoadings, setIsLoadings] = useState(false);
    if (loginData) { token = loginData.token; id = loginData?.user_data?._id }
    
    const handleUpload = (e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
        if (!file) {
            toast.error("No image is selected");
            return;
        } else if (file.type === "image/jpeg" || file.type === "image/png") {
            if (file.size > maxSizeInBytes) {
                toast.error("File size should be under 2MB");
                return;
            } else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
                    setFeaturedImg(base64String);
                };
                reader.readAsDataURL(file);
            }
        } else {
            toast.error("Please select a valid JPG or PNG file.");
        };
    };

   useEffect(() => {
        if (blogData) {
            setTitle(blogData?.title);
            setContent(blogData?.content);
            setFeaturedImg(blogData?.featured_image)
        } else {
            setTitle('');
            setContent('');
            setFeaturedImg('');
        }
    },[blogData])


    // const handleSubmit = async (e, isUpdate) => {
    //     e.preventDefault();
    //     if (title.trim() === "" || content.trim() === "") {
    //         toast.error("Title and content are required");
    //         return;
    //     }
    //     const apiUrl = isUpdate 
    //         ? `${process.env.REACT_APP_PCMA_BACKEND}/api/update-blog?id=${blogid}` 
    //         : `${process.env.REACT_APP_PCMA_BACKEND}/api/publish-post/${id}`;
    //     const method = isUpdate ? 'post' : 'post';

    //     try {
    //         const response = await axios[method](apiUrl, {
    //             title,
    //             content,
    //             featured_image: featuredImg,
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         if (response?.data?.message === (isUpdate ? "Blog Updated!" : "Post Published")) {
    //             toast.success(response?.data?.message);
    //             dispatch(fetchBlogs({ id, currentPage, sortBlog }));
    //             if (isUpdate) setEditBlog(false);
    //             else setIsCreate(false);
    //         }
    //     } catch (error) {
    //         if (error?.response?.data?.message === "Unauthorized User") {
    //             localStorage.removeItem('login');
    //             navigate("/");
    //             toast.error("Unauthorized user!");
    //         } else {
    //             toast.error(error?.response?.data?.error || "An error occurred");
    //         }
    //         console.error("Error:", error);
    //     }
    // };

    function decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }
    
    function stripHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value.replace(/<\/?[^>]+>/gi, '');
    }
    
    const createBlog = async (e) => {
        e.preventDefault();
        setIsLoadings(true)
    
        const decodedContent = decodeHtml(content);
        const strippedContent = stripHtml(decodedContent).trim();
    
        if (title.trim() === "" || strippedContent === "") {
            toast.error("Title and content are required");
            setIsLoadings(false);
            return;
        }else if (title.trim() === "") {
            toast.error("Title is required");
            setIsLoadings(false);
            return;
        } else if (strippedContent === "") {
            toast.error("Content is required");
            setIsLoadings(false);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/publish-post/${id}`,
                {
                    title: title,
                    content: content,
                    featured_image: featuredImg,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                },)
                if (response?.data?.message === "Post Published") {
                    toast.success(response?.data?.message);
                    dispatch(fetchBlogs({ id: id, currentPage: currentPage , sortBlog:sortBlog}));
                }
                setTimeout(() => {
                     setIsLoadings(false);
                }, 1000);
               
        } catch (error) {
             if (error?.data?.message === "Unauthozied User") {
                    localStorage.removeItem('login');
                    navigate("/");
                    toast.error(`Unauthozied user !`);
             } else {
                  console.error("Error:", error);
            }
            setIsLoadings(false);
           
        }
            setIsCreate(false);
                
    };

    const updateBlog = async(e) => {
        e.preventDefault();
        if (title === "" && content === "") {
            toast.error("All fields are required");
            return;
        }
        else if (title === "") {
            toast.error("Title is required");
            return;
        } else if (content === "") {
            toast.error("Content is required");
            return;
        } else {
            try {
                const response = await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/update-blog?id=${blogid}`,
                    {
                        title: title,
                        content: content,
                        featured_image: featuredImg,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    },)
                if (response?.data?.message === "Blog Updated!") {
                    toast.success(response?.data?.message);
                }
            } catch (error) {
                if (error?.data?.message === "Unauthozied User") {
                    localStorage.removeItem('login');
                    navigate("/");
                    toast.error(`Unauthozied user !`);
                } else {
                    toast.error(error?.response?.data?.error);
                }
                console.error("Error:", error);
            }
            setEditBlog(false);
        }        
    };

    const handleCancel = () => {
        setTitle('');
        setContent('');
        setIsCreate(false);
    };

     const handleExit = () => {
        setTitle('');
        setContent('');
        setEditBlog(false);
    };

    const config = {
        placeholder: 'Write blog description',
        height: 300,
        allowResizeX: false,
        allowResizeY: false,
        limitChars: 1000,
        spellcheck: true,
        toolbar: true,
        toolbarButtonSize: "medium",
        toolbarAdaptive: false,
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        enableDragAndDropFileToEditor: true,
        defaultActionOnPaste: "insert_clear_html",
        defaultActionOnPasteFromWord: 'insert_as_html',
        enableDragAndDropFileToEditor: true,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
        withCredentials: false,
        buttons: [
        'bold', 'italic', 'underline', 'strikethrough','ol', 'ul',
        'font', 'fontsize', 'paragraph', 'spellcheck','brush',
        'indent', 'outdent', 'align', 'undo', 'redo', 'cut',
        'hr', 'link','image', 'unlink', 'table', 'fullsize','preview' , 'print'
        ],
         uploader: {
         insertImageAsBase64URI: true
             
    },
    };


    return (<>
        <div className="col-sm-12 col-md-12 col-lg-12">
                <div className=" j_account_information mt-4" style={{ background: "#137CD9" }}>
                     <span className="px-3 d-flex align-items-center" style={{ color: "white", fontSize: '20px' }}>
                        {isCreate ? "Create A New Blog" : "Update Blog Post"} 
                    </span>
                </div> 
        </div>
        <div className="col-sm-12 col-lg-10 col-xl-9 col-xxl-8 ">
    {isCreate ? (        
            <form className="blog_form row my-4">
                <div className="col-12">
                    <div className="d-flex flex-column flex-sm-row align-items-center " style={{ columnGap: '30px' }}>
                            <div className='blog_box d-flex align-items-center justify-content-center' >
                                {featuredImg ? (
                                    <img src={featuredImg} className='img-fluid blog_img' alt="Blog Image"  />
                                ) : (<div className='d-flex align-items-center justify-content-center'><img src="/asset/photo_camera.png" className='img-fluid ' alt="defualt" /></div>)}
                            </div>   
                            <button className="btn_upload position-relative  mt-sm-0 mt-3" type="button" style={{overflow:'hidden'}}><i className="fa-solid fa-upload me-2" style={{ color: '#757677' }}></i>
                                Upload Image
                            <input type="file" className="position-absolute" style={{ opacity: 0, height:'100%', width: "300px", top: "0", right: "0", outline: 'none', border: 'none' , cursor:"pointer"}} onChange={handleUpload} />
                            </button>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <label className="form-label mb-3">Blog Name
                        <svg
                            className="ms-2 "
                            
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                        >
                            <path
                            d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                            fill="#F92828"
                            />
                        </svg>
                    </label>
                    <input type="text" id="title" name='title' className=" blog_form_input form-control mb-3" placeholder="Write blog name" value={title?.trimStart()} onChange={(e) => setTitle(e.target.value)} />
                
                </div> 
                <div className="col-12">
                        <label className="form-label mb-3">Blog Body
                            <svg
                            className="ms-2 "
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                        >
                            <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                            />
                        </svg> <span>(Max. 1000 characters)</span>
                       
                    </label>
                    <JoditEditor
                            name='content'
                            ref={editor}
                            value={content}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setContent(newContent)}
                            onChange={newContent => (e) => setContent(newContent)}
                />
                </div>         
                <div className="col-md-6 col-lg-12">
                    {/* <h3 className="form-label mt-3 mb-3">Comment Privacy</h3>  */}
                        {/* <div className="d-md-flex align-content-center justify-content-between">
                                <div className="">
                                    <input className="" type="checkbox" value="" id="flexCheckDefault" /> 
                                    <label className="form_checkbox_label ms-2" htmlFor="flexCheckDefault">Locked (Only I may comment)</label>
                                </div> 
                                <div>
                                    <input className="" type="checkbox" value="" id="flexCheckDefault" /> 
                                    <label className="form_checkbox_label ms-2" htmlFor="flexCheckDefault">Open (Other members may comment)</label>
                                </div> 
                        </div> */}
                        <div className="d-sm-flex align-content-center mt-3">
                            <button type="button" className="btn_create" onClick={createBlog} disabled={isLoadings}>Create</button>
                            <button type="button" className="btn_cancel ms-4" onClick={handleCancel}>Cancel</button>
                        </div>   
                            
                </div>
            </form>
    ) : (
           <form className="blog_form row my-4">
                <div className="col-12">
                    <div className="profile_box d-flex flex-column flex-sm-row align-items-center " style={{columnGap:'40px'}}>
                        <div className={` blog_box d-flex align-items-center justify-content-center w-100 ${!featuredImg ? 'upload_background' : ''}`}>
                             {featuredImg ? (<img src={featuredImg} className='img-fluid blog_img' alt="defualt" style={{ borderRadius: "8.037px" }} />) : (
                                    <img src="/asset/photo_camera.png" className='img-fluid' alt="defualt" style={{ borderRadius: "8.037px" }} />
                                )}        
                        </div>
                                <button className="btn_upload position-relative  mt-sm-0 mt-3" type="button" style={{overflow:'hidden'}}><i className="fa-solid fa-upload me-2" style={{ color: '#757677' }}></i>
                                Upload Image
                            <input type="file" className="position-absolute" style={{ opacity: 0, height:'100%', width: "300px", top: "0", right: "0", outline: 'none', border: 'none' , cursor:"pointer"}} onChange={handleUpload} />
                            </button>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <label className="form-label mb-3">Blog Name
                        <svg
                            className="ms-2 "
                            
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                        >
                            <path
                            d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                            fill="#F92828"
                            />
                        </svg>
                    </label>
                            <input type="text" id="title" name='title' className=" blog_form_input form-control mb-3" placeholder="Write blog name" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div> 
                <div className="col-12">
                    <label className="form-label mb-3">Blog Body <span>(Max. 1000 characters)</span>
                        <svg
                            className="ms-2 "
                            
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                        >
                            <path
                            d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                            fill="#F92828"
                            />
                        </svg>
                    </label>
                    <JoditEditor
                        name='content'
                        ref={editor}
                        value={content}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setContent(newContent)}
                        onChange={newContent => (e) => setContent(newContent)}
                />
                </div>         
                <div className="col-md-6 col-lg-12">
                    {/* <label className="form-label mt-3 mb-3">Comment Privacy</label>  */}
                        {/* <div className="d-md-flex align-content-center justify-content-between">
                                <div className="">
                                    <input className="" type="checkbox" value="" id="flexCheckDefault" /> 
                                    <label className="form_checkbox_label ms-2" htmlFor="flexCheckDefault">Locked (Only I may comment)</label>
                                </div> 
                                <div>
                                    <input className="" type="checkbox" value="" id="flexCheckDefault" /> 
                                    <label className="form_checkbox_label ms-2" htmlFor="flexCheckDefault">Open (Other members may comment)</label>
                                </div> 
                        </div> */}
                        <div className="d-sm-flex align-content-center mt-3">
                                <button type="button" className="btn_create" onClick={updateBlog}>Update</button>
                                <button type="button" className="btn_cancel ms-4" onClick={handleExit}>Cancel</button>
                        </div>   
                            
                </div>
            </form>         
            )}    
        </div>
    </>
 
    );
 };
export default CreateNewBlog;
