import React, { useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvent } from '../../redux/slice/event';
import { fetchNews } from '../../redux/slice/news';
import { Link} from "react-router-dom";
const MemberMenu = () => {

    const dispatch = useDispatch();
    const eventState = useSelector((state) => state.events);
    const newsState = useSelector((state) => state.news);
   
    useEffect(() => {
        dispatch(fetchEvent());
        dispatch(fetchNews());
    }, []);
    
    const eventNews = eventState?.data;
    const latestNews = newsState?.data;
    let singlePage = eventState?.data?.latest_news?.pagination?.page;


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        } else {
            return date.getDate() + (date.getDate() % 10 === 1 && date.getDate() !== 11 ? 'st' : date.getDate() % 10 === 2 && date.getDate() !== 12 ? 'nd' : date.getDate() % 10 === 3 && date.getDate() !== 13 ? 'rd' : 'th') + ' ' + date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
        }
    };
    
    return (
        <>
            <div className="latest_news_events_sidebar">
                <div className="latest_news_box">
                    <div className=" d-flex align-items-center justify-content-between">
                            <span><i className="fa-solid fa-newspaper me-2"></i>LATEST NEWS</span>
                            <span><Link to='https://pcma1dev.wpenginepowered.com/pcma-news/' ><i className="fa-solid fa-arrow-right"></i></Link></span>
                    </div>
                    {latestNews?.map((items) => (
                        <>
                            <div key={items?.id} className="latest_news_list_card d-sm-flex flex-row-reverse align-items-center justify-content-between active mt-2 ">
                                <div className="news_img mb-2 mb-lg-0">
                                    {items?.featured_image?.length > 0 ? (<img src={`${items?.featured_image}`} alt="latest news" />): (<img src="/asset/news_img.png" alt="latest news" />)}
                                  
                                </div>
                                <div className="me-lg-3">
                                    <h6>{formatDate(items?.date)}</h6>
                                    <Link to={items?.permalink}><p className="mb-0">{items?.title}</p></Link>
                                </div>
                            </div>
                        </>
                     ))}
                          
            </div>
                        <div className="latest_news_box events mt-4">
                                    <div className=" d-flex align-items-center justify-content-between" style={{ paddingBottom: '15px', borderBottom: '1px solid #fff' }}>
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" className="bi bi-calendar-event me-2"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                                                <path
                                                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                            </svg>EVENTS</span>
                                        <span><Link className="text-decoration-none" to="https://pcma1dev.wpenginepowered.com/event-calander-page/"><i className="fa-solid fa-arrow-right"></i></Link></span>
                                    </div>
                                    {eventNews?.map((items, index) => (
                                        <>
                                            <div  key={items?.id} className="events_date_body d-flex align-items-center mt-3">
                                                    <div className="date_box">
                                                    <h5 className="mb-0">{items?.date?.slice(-2)}</h5>
                                                        <p className="mb-0">{new Date(items?.date).toLocaleDateString('en-US', { month: 'short', year: '2-digit' }).toUpperCase()}</p>
                                                    </div>
                                            <div className="date_box_text ms-4">
                                                <Link to={items?.permalink}><p className="mb-0">{items?.title}</p></Link>
                                                <p className="mb-0 time mt-1">
                                                    <i className="fa-regular fa-clock me-2"></i>{items?.time?.toUpperCase()}
                                                </p>
                                            </div>
                                            </div>
                                        </>
                                    ))}
                                </div>

            </div>
                   
        </>
    )

};
export default MemberMenu;