import { useEffect } from 'react';

// Custom hook to disable the Tab key and set tabIndex to -1 for focusable elements
const useDisableTabKey = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault();
      }
    };

    // Disable tab focus by setting tabIndex=-1 on all focusable elements
    const disableTabFocus = () => {
      const focusableElements = document.querySelectorAll(
        'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
      );
      focusableElements.forEach((element) => {
        element.setAttribute('tabindex', '-1');
      });
    };

    // Enable tab focus by resetting tabIndex to its default value
    const enableTabFocus = () => {
      const focusableElements = document.querySelectorAll(
        'a, button, input, textarea, select, [tabindex="-1"]'
      );
      focusableElements.forEach((element) => {
        element.removeAttribute('tabindex');
      });
    };

    // Add the event listener for the Tab key
    window.addEventListener('keydown', handleKeyDown);
    disableTabFocus();

    // Cleanup the event listener and reset tabIndex on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      enableTabFocus();
    };
  }, []);
};

export default useDisableTabKey;
