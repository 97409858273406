// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Member Information */

/* 
.member-information {
    padding: 1rem 6rem;
} */

.submit_btn{
    border-radius: 4px;
    background: linear-gradient(90deg, #0F66B4 0%, #147FDE 100%);
    outline: none;
}
.form_label {
    min-width:  180px;
}

.invalid-feedback {
    text-align: center;
    margin: auto;
    padding: 0px 1rem;
}

.register_memeber_form label{
    position: relative;
}

.register_memeber_form label svg {
    position: absolute;
    top: 4px;
}

/* @media only screen and (max-width: 768px) {
    .member-information {
        padding: 1rem 1rem;
    }
} */
`, "",{"version":3,"sources":["webpack://./src/styles/RegisterForm.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;;;GAGG;;AAEH;IACI,kBAAkB;IAClB,4DAA4D;IAC5D,aAAa;AACjB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;;;;GAIG","sourcesContent":["/* Member Information */\n\n/* \n.member-information {\n    padding: 1rem 6rem;\n} */\n\n.submit_btn{\n    border-radius: 4px;\n    background: linear-gradient(90deg, #0F66B4 0%, #147FDE 100%);\n    outline: none;\n}\n.form_label {\n    min-width:  180px;\n}\n\n.invalid-feedback {\n    text-align: center;\n    margin: auto;\n    padding: 0px 1rem;\n}\n\n.register_memeber_form label{\n    position: relative;\n}\n\n.register_memeber_form label svg {\n    position: absolute;\n    top: 4px;\n}\n\n/* @media only screen and (max-width: 768px) {\n    .member-information {\n        padding: 1rem 1rem;\n    }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
