import React, { useEffect } from "react";
import { Container, Row, Col, Button, FormControl } from "react-bootstrap";
import Select from "react-select";

const UserMessage = () => {

  useEffect(() => {
    document.title = "PCMA || Message Center";
  }, []);


  return (
    <Container fluid className="pt-4">
      <div className=" j_account_information" style={{ background: "#137CD9" }}>
        <span className="px-3 d-flex align-items-center" style={{ color: "white", fontSize: '20px' }}>
        Message Center
        </span>
      </div>

      <Row className="g-2" style={{ paddingBottom: "10px" }}>
        <Col
          sm={10}
          md={10}
          lg={10}
          className="py-3 d-flex justify-content-start align-items-start flex-column"
        >
          <h4 className="network_h4">Quick Jump To</h4>

          <Col
            sm={4}
            md={4}
            lg={4}
            className="pb-3 d-flex justify-content-start align-items-start flex-column"
          >
            <Select
              // value={selectedOption2}
              // onChange={handleChange2}
              // options={options1}
              placeholder="Inbox"
              className="w-100"
            />
          </Col>

          <Col
            sm={6}
            md={6}
            lg={6}
            className="py-3 d-flex justify-content-start align-items-start flex-column"
            style={{ opacity:'0.6'}}
          >
            Use the links below to manage and organize your messages. Send
            messages to members, a connection or all of your connections.
          </Col>
        </Col>

        <Row className="d-flex align-items-end g-3 mt-0">
          <Col sm={12} md={12} lg={3} className="mt-0">
            <Button
              style={{
                border: "0px",
                borderRadius: "4px",
                background: "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)",
              }}
            >
              Create New Folder +
            </Button>
          </Col>
          <Col sm={12} md={12} lg={3}>
            <h5>Url</h5>
            <FormControl
              type="text"
              placeholder="Enter URL"
            // value={url}
            // onChange={handleUrlChange}
            />
          </Col>
          <Col sm={12} md={12} lg={3} className="d-flex align-items-end">
            <div>
              <Button
                style={{
                  border: "0px",
                  borderRadius: "4px",
                  background:
                    "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)",
                }}
              >
                Create
              </Button>{" "}
              <Button
                style={{
                  border: "1px solid rgb(19, 124, 217)",
                  borderRadius: "4px",
                  background: "white",
                  color: "rgb(19, 124, 217)",
                }}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <div className="table-responsive" style={{borderRadius:'6px'}}>
            <table className="userinvoice_table" style={{ width: "100%" }}>
              <thead style={{ background: "blue" }}>
                <tr className="table-heading-row">
                  <th width="450px" className="py-2">Message Center</th>
                  <th>Details</th>
                  <th  width="120px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Your Inbox</td>
                  <td>0 Messages Total, 0 Unread</td>
                </tr>
                <tr>
                  <td>Sent Messages</td>
                  <td>0 Messages Total</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>

        {/* <Col sm={8} md={6} lg={9} className='py-3'>
<Button style={{borderRadius:"4px",border:"0px",background: "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)"}}>PAY Selected Invoice</Button>
</Col> */}
      </Row>

      <Row>
        <Col sm={12} md={12} lg={12} className="mt-4">
          <div className="table-responsive" style={{borderRadius:'6px'}}>
            <table className="userinvoice_table" style={{ width: "100%" }}>
              <thead style={{ background: "blue" }}>
                <tr className="table-heading-row">
                  <th width="450px" className="py-2">Message Options</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Message a Member</td>
                  <td>Search for a member and click "Message Member."</td>
                </tr>
                <tr>
                  <td>Message a Connection</td>
                  <td>Send a message to a member of your connection list.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>

        {/* <Col sm={8} md={6} lg={9} className='py-3'>
<Button style={{borderRadius:"4px",border:"0px",background: "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)"}}>PAY Selected Invoice</Button>
</Col> */}
      </Row>
    </Container>
  );
};

export default UserMessage;
