// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* li{
padding: 1rem 0.5rem;
}

li > a{
   text-decoration: none;
} */

`, "",{"version":3,"sources":["webpack://./src/styles/leftsidebar.css"],"names":[],"mappings":"AAAA;;;;;;GAMG","sourcesContent":["/* li{\npadding: 1rem 0.5rem;\n}\n\nli > a{\n   text-decoration: none;\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
