import React from 'react';
import { Table } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

const TaxManagement = () => {
    return (
        <>
            <div className="row pt-5 mb-1">
                <div className="col-12">
                    <div style={{ background: "#137CD9", color: "#fff", borderRadius: '5px' }}>
                        <h4 className="mb-0 py-2 px-3">Tax Management</h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className='tax_management_country d-flex justify-content-start align-items-center px-4 my-4'>
                        <p className='mb-0'>Country</p>
                        <div className='tax_management_box ms-3'>
                            <p className='mb0'>Canada</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" >
                        <div className="subcription_table">
                            <div className="table-responsive firms_card_box">
                                <Table className="w-100 mb-0 notice_table_body">
                                    <thead className="wrappingBox">
                                        <tr className="notice_table_heading">
                                            <th className="text-start" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}>PROVINCE</th>
                                            <th className="text-start" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0", paddingLeft: "13px", }}>TAX (%)</th>
                                            <th className="text-center" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}>LAST MODIFIED</th>
                                            <th className="text-center" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: "0" }}> REMARKS <span> (50 Characters Max.)</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
 };
export default TaxManagement;