import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { Col, Row } from "react-bootstrap";

const PromoCodeEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        discount: '',
        start_date: '',
        end_date: '',
        status: false
    });

    const [errors, setErrors] = useState({
        name: '',
        discount: '',
        start_date: '',
        end_date: '',
        status: false
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/find-promo-code/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { name, discount, status } = response.data.data;
            setFormData({
                name,
                discount,
                status
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const booleanValue = value === 'true' || value === true;

        setFormData({
            ...formData,
            [name]: value
        });

        validateField(name, value);
    };

    const validateField = (fieldName, value) => {
        let newErrors = { ...errors };

        if (fieldName === 'name') {
            newErrors.name = value.trim() === '' ? 'Code name is required!' : '';
        }
        if (fieldName === 'discount') {
            newErrors.discount = value.trim() === '' ? 'Discount is required!' : '';
        }
        if (fieldName === 'start_date') {
            newErrors.start_date = value.trim() === '' ? 'Start date is required!' : '';
        }
        if (fieldName === 'end_date') {
            newErrors.end_date = value.trim() === '' ? 'End date is required!' : '';
        }
        if (fieldName === 'status') {
            newErrors.status = value.trim() === '' ? 'Status name is required!' : '';
        }

        setErrors(newErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let statusBoolean = null;
        if (formData.status === 'true') {
            statusBoolean = true;
        } else if (formData.status === 'false') {
            statusBoolean = false;
        }

        if (!Object.values(errors).some((error) => error !== '')) {
            try {
                const token = getToken();
                await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/update-promo-code/${id}`, {
                    name: formData.name,
                    discount: formData.discount,
                    status: statusBoolean
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                navigate('/admin/promo-code');
            } catch (error) {
                console.error("Error updating data:", error);
            }
        }
    };

    const getToken = () => {
        const logindata = JSON.parse(window.localStorage.getItem("loginAdmin"));
        return logindata?.Token || '';
    };

    const handleBack = () => {
        if (window.history.length === 1) {
            navigate('/login');
        } else {
            navigate(-1);
        }
    };
    return (
        <>

            <Row className="g-2" style={{ paddingBottom: "10px" }} >
                <Col lg={3} md={3} sm={12} >
                    <span
                        onClick={handleBack}
                        style={{
                            cursor: "pointer",
                            background: "#0F66B4",
                            color: "white",
                            fontWeight: "500",
                            display: 'inline-block'
                        }}
                        className=" user_firm_details_back_btn  position-static"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                            />
                        </svg>{" "}
                        Back
                    </span>
                </Col>
                <Col lg={9} md={9} sm={12}></Col>
            </Row>
            <div className="row pt-4 mb-4">
                <div className="col-12">
                    <div style={{ background: "#137CD9", color: "#fff", borderRadius: '5px' }}>
                        <h4 className="mb-0 py-2 px-3">Edit Promo Code</h4>
                    </div>
                </div>
                <div className="col-12 mt-4 px-3">
                    <div className="row">
                        <div className="col-md-8 col-xl-6 px-4 px-xl-5">
                            <form className="row g-3 promocode_form mt-1" onSubmit={handleSubmit}>
                                <div className="col-sm-6">
                                    <label className="form-label mb-3">Promo Code Name</label>
                                    <input type="text" name='name' className="form-control mb-2 py-2" value={formData.name} onChange={handleChange} />
                                    <span style={{ color: "red" }}>{errors.name}</span>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-3">Discount </label>
                                    <div className="d-flex align-items-center">
                                        <input type="number" name='discount' className="form-control mb-2 py-2" value={formData.discount} onChange={handleChange} />
                                        <span>%</span>
                                    </div>
                                    <span style={{ color: "red" }}>{errors.discount}</span>
                                </div>
                                {/* <div className="col-sm-6">
                                    <label className="form-label mb-3">Start Date</label>
                                    <input type="date" name='start_date' className="form-control mb-2  py-2" value={formData.start_date} onChange={handleChange} />
                                    <span style={{ color: "red" }}>{errors.start_date}</span>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-3">End Date </label>
                                    <input type="date" name='end_date' className="form-control mb-2  py-2" value={formData.end_date} onChange={handleChange} />
                                    <span style={{ color: "red" }}>{errors.end_date}</span>
                                </div> */}
                                <div className="col-sm-6">
                                    <label className="form-label mb-3">Status</label>
                                    <select className="form-select py-2" name='status' value={formData.status} onChange={handleChange}>
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                    </select>
                                    <span style={{ color: "red" }}>{errors.status}</span>
                                </div>
                                <div className="col-12 mt-5">
                                    <div className=' d-flex align-items-center flex-wrap' style={{ gap: '15px' }}>
                                        <button type="submit" className="subcription_add_new" style={{ border: '2px solid #0F66B4' }}>Save</button>
                                        <Link to="/admin/promo-code" className="subcription_add_new fw-bold px-4" style={{ background: 'transparent', color: '#0F66B5', border: '2px solid #0F66B5' }}>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4 col-xl-6"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PromoCodeEdit;
