// import React, { useEffect } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';  

// import '../../styles/leftsidebar.css';
// import '../../styles/adminglobal.css'


// const AdminTopBar = (props) => {
//   const user = JSON?.parse(window?.localStorage?.getItem('loginAdmin'));
//   let adminName = user?.admin?.name;

//   useEffect(() => {
//     document.title = 'PCMA || Admin Dashboard';
//   }, []);

//   const sidbarToggle = () => {
//     props.sidbarToggle(!props.sidebarToggleData)
//   }
//   return (
//     <section className="admin_header">
//       <Container fluid>
//         <Row>
//           <Col>
//             <header className="dashboard_header d-flex justify-content-between py-4">
//               <div className=" d-flex align-items-center">
//                 {props.sidebarToggleData ? (
//                   <button
//                     className="menu_close"
//                     onClick={sidbarToggle}
//                   >
//                     <i className="fa-solid fa-xmark" style={{fontSize:'27px'}}></i>
//                   </button>
//                 ) : (
//                   <button
//                     className="menu_open"
//                     onClick={sidbarToggle}
//                   >
//                     <i className="fa-solid fa-bars"></i>
//                   </button>
//                 )}

//                 <Link to="/admin">
//                   <img
//                     src="/asset/logo.png"
//                     className="header_img_logo"
//                     alt="imagees"
//                     style={{ maxWidth: "155px", maxHeight: "65px" }}
//                   />
//                 </Link>
//               </div>
//               <div class="d-flex justify-content-center align-items-center ">
//                 {adminName}
//                 <Link to="adminprofile">
//                   <img
//                     src="/asset/users.png"
//                     className="header_img_logo ms-2"
//                     alt="imagees"
//                     style={{
//                       maxWidth: "50px",
//                       maxHeight: "50px",
//                       borderRadius: "50%",
//                       fontWeight: "bold",
//                     }}
//                   />
//                 </Link>
//               </div>
//             </header>
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// export default AdminTopBar;


import React, { useEffect } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import '../../styles/leftsidebar.css';
import '../../styles/adminglobal.css';
import toast from 'react-hot-toast';

const AdminTopBar = (props) => {
  const user = JSON?.parse(window?.localStorage?.getItem('loginAdmin'));
  let adminName = user?.admin?.name;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'PCMA || Admin Dashboard';
  }, []);

  const sidbarToggle = () => {
    props.sidbarToggle(!props.sidebarToggleData);
  }

  const handleLogout = () => {
    // Remove user data from local storage
    localStorage.removeItem('loginAdmin');
    // Navigate to the login page
    toast.success('You have logged out successfully.');
    navigate('/admin/login');
  }

  return (
    <section className="admin_header">
      <Container fluid>
        <Row>
          <Col>
            <header className="dashboard_header d-flex justify-content-between py-4">
              <div className="d-flex align-items-center">
                {props.sidebarToggleData ? (
                  <button
                    className="menu_close"
                    onClick={sidbarToggle}
                  >
                    <i className="fa-solid fa-xmark" style={{ fontSize: '27px' }}></i>
                  </button>
                ) : (
                  <button
                    className="menu_open"
                    onClick={sidbarToggle}
                  >
                    <i className="fa-solid fa-bars"></i>
                  </button>
                )}

                <Link to="/admin">
                  <img
                    src="/asset/logo.png"
                    className="header_img_logo"
                    alt="logo"
                    style={{ maxWidth: "155px", maxHeight: "65px" }}
                  />
                </Link>
              </div>
              {/* <div className="d-flex justify-content-center align-items-center" style={{ paddingRight: '10px' }}>
                {adminName}
                <Dropdown>
                  <Dropdown.Toggle as="div" id="dropdown-custom-components" className="dropdown-toggle-custom">
                    <img
                      src="/asset/users.png"
                      className="header_img_logo ms-2"
                      alt="admin profile"
                      style={{
                        maxWidth: "50px",
                        maxHeight: "50px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="right">
                    <Dropdown.Item as={Link} to="/admin/adminprofile">Admin Profile</Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}

              <div className="d-flex justify-content-center align-items-center" style={{ paddingRight: '10px' }}>
                {adminName}
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle dropdown-toggle-custom"
                    type="button"
                    id="dropdown-custom-components"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src="/asset/users.png"
                      className="header_img_logo ms-2"
                      alt="admin profile"
                      style={{
                        maxWidth: "50px",
                        maxHeight: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-custom-components">
                    <li>
                      <a className="dropdown-item" href="/admin/adminprofile">Admin Profile</a>
                    </li>
                    <li><a className="dropdown-item"><button className="logout w-100" onClick={handleLogout}>Logout</button></a></li>
                    {/* <li>
        <span className="dropdown-item"  onClick={handleLogout}>Logout00000</span>
      </li> */}
                  </ul>
                </div>
              </div>

            </header>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AdminTopBar;



