import { createSlice } from '@reduxjs/toolkit';

// Define the initial state of the address
const initialState = {
  address: 'toranto, ON, Canada',
};

const registrantnearmeaddress = createSlice({
  name: 'registrantnearmeaddress',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload.address || state.address;

    },
    clearAddress: (state) => {
      state.address = '';
    },
  },
});

// Export actions
export const { setAddress, clearAddress } = registrantnearmeaddress.actions;

// Export the reducer
export default registrantnearmeaddress.reducer;
