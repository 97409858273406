import React, { useEffect, useState,useRef } from 'react';
import { Link, Navigate, redirect } from 'react-router-dom';
import '../../styles/Global.css'
import { useNavigate } from 'react-router-dom';
const NavbarGlobal = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const [toggleData , setToggleView] =useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggleView(false);
        setIsOpen(false)
      }
    }

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const MemberShipToggle=()=>{
    setToggleView(!toggleData);
  }


  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const redirectContactPage=()=>{
   window.location.href = 'https://pcma1dev.wpenginepowered.com/contact-us/';
  }



  // const [activeLink, setActiveLink] = useState('');


  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={`header_wordpress  p-0  ${scrollPosition >= 100 ? 'active' : ''}`}>
            <nav className="navbar navbar-expand-lg navbar-light">
               <div className="container-fluid">
                  <Link to="https://pcma1dev.wpenginepowered.com/" className="navbar-brand">
                  <img src="https://pcma1dev.wpenginepowered.com/wp-content/uploads/2024/02/logo_old1.png"
                     alt="pcm" width="164" height="77" className="logo-img d-inline-block align-text-top" />
                  </Link>
                  <button className="navbar-toggler ms-sm-auto" type="button" data-bs-toggle="collapse"
                     data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                     aria-expanded="false" aria-label="Toggle navigation">
                     <i className="fa-solid fa-bars" style={{fontSize:"27px"}}></i>  
                  </button>
                  <div className="collapse navbar-collapse ms-auto navbar_nav" id="navbarSupportedContent">
                     <ul className="navbar-nav ms-auto mb-2 mb-lg-0 me-2 " id="navbar_close">
                        <li className="nav-item text-start text-lg-center menu-item d-none d-lg-block">
                           <Link to="https://pcma1dev.wpenginepowered.com/" id="homeActive" className="nav-link" aria-current="page">Home</Link>
                        </li>
                        <li className="nav-item dropdown dropdown-mega position-static">
                            <a className="nav-link dropdown-toggle mega-dropdown-menu-btn" href="#" data-bs-toggle="dropdown"  data-bs-auto-close="true" aria-expanded="false">More</a>
                            <div className="dropdown-menu shadow mega-dropdown-menu mt-2">
                                <div className="mega-content p-2 p-lg-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="d-block d-md-none mb-2">
                                                <div className="col-12">
                                                    <div className="sub_menu">
                                                         <Link to="https://pcma1dev.wpenginepowered.com/" id="homeActive" className="nav-link" aria-current="page">Home</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-sm-6">
                                                <nav className="toggle_sections">
                                                    <div className="nav nav-tabs select_button flex-sm-row text-center border-0" id="nav-tab" role="tablist">
        
                                                        <button className=" nav-link active flex-sm-fill border-0 " id="nav-about-tab" data-bs-toggle="tab" data-bs-target="#nav-about" type="button" role="tab" aria-controls="nav-about" aria-selected="false">About</button>
        
                                                        <button className="nav-link   flex-sm-fill border-0 " id="nav-membership-tab" data-bs-toggle="tab" data-bs-target="#nav-membership"
                                                            type="button" role="tab" aria-controls="nav-membership" aria-selected="true">Membership</button>
        
                                                        <button className=" nav-link flex-sm-fill border-0 " id="nav-articles-tab" data-bs-toggle="tab"
                                                                data-bs-target="#nav-articles" type="button" role="tab" aria-controls="nav-articles" aria-selected="false">PCMA Articles & Eucation </button>
                                                        <button className="nav-link flex-sm-fill border-0 " id="nav-advocacy-tab" data-bs-toggle="tab" data-bs-target="#nav-advocacy"
                                                                type="button" role="tab" aria-controls="nav-advocacy" aria-selected="false">Advocacy</button>
                                                        <button className=" nav-link flex-sm-fill border-0 " id="nav-events-tab" data-bs-toggle="tab"
                                                                data-bs-target="#nav-events" type="button" role="tab" aria-controls="nav-events" aria-selected="false">Events & Meet-Ups</button>
        
                                                        <button className="nav-link flex-sm-fill border-0 " id="nav-sponsors-tab" data-bs-toggle="tab" data-bs-target="#nav-sponsors"
                                                                type="button" role="tab" aria-controls="nav-sponsors" aria-selected="false">Resources</button>
        
                                                        <button className=" nav-link flex-sm-fill border-0 " id="nav-media-tab" data-bs-toggle="tab" data-bs-target="#nav-media" 
                                                                type="button" role="tab" aria-controls="nav-media" aria-selected="false">Media</button>
                                                     </div>
                                                </nav>
                                            </div>
                                            <div className="col-lg-12 col-sm-6">  
                                                <div className="tab-content" id="nav-tabContent">
                                                    <div className="tab-pane show active" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                                                         <div className="row">
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/about-our-story/'}>Who is the PCMA</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/what-are-the-private-capital-markets/'}>About Private Capital Market</Link>
                                                                </div>
                                                            </div> 
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/leadership/'}>Leadership</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/pcma-news/'}>Articles</Link>
                                                                </div>
                                                            </div> 
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/message-from-the-chair/'}>Chair Messages</Link>
                                                                </div>
                                                            </div>
                                                             <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" >Committees</Link>
                                                                </div>
                                                            </div>      
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                           Chapters
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                            <li><Link className="dropdown-item" to={'https://pcma1dev.wpenginepowered.com/the-western-chapter/'}>Western Chapter</Link></li>
                                                                            <li><Link className="dropdown-item" to={'https://pcma1dev.wpenginepowered.com/the-quebec-chapter/'}>Quebec  Chapter</Link></li> 
                                                                            <li><Link className="dropdown-item" to={'https://pcma1dev.wpenginepowered.com/the-eastern-chapter/'}>Eastern Chapter</Link></li>                               
                                                                        </ul>
                                                                      </div>
                                                                </div>
                                                            </div>      
                                                            
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/contact-us/'}>Contact Information</Link>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-membership" role="tabpanel" aria-labelledby="nav-membership-tab">
                                                        <div className="row">
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            Become a member
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                          <li><Link to={'https://firm.pcma.klizos.com/register'} className="dropdown-item" href="#">New</Link></li>
                                                                          <li><Link to={'https://firm.pcma.klizos.com/login'} className="dropdown-item" href="#">Renewal</Link></li>
                                                                        </ul>
                                                                      </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            Networking Opportunities
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                          <li><Link to={'https://pcma1dev.wpenginepowered.com/chapters-landing-page/'} className="dropdown-item">Join a Chapter</Link></li>
                                                                          <li><Link to={'https://pcma1dev.wpenginepowered.com/become-a-volunteer/'} className="dropdown-item">Become a Volunteer</Link></li>
                                                                          <li><Link  className="dropdown-item">Join a Committee</Link></li>
                                                                          <li><Link  className="dropdown-item">Meet-Ups & Forums</Link></li>
                                                                        </ul>
                                                                      </div>
                                                                </div>
                                                            </div>      
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <Link to={'https://pcma1dev.wpenginepowered.com/membership-recognition-and-awards/'} className="text-decoration-none">Member Recognition & Awards</Link>
                                                                </div>
                                                            </div>
                                                                  
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <Link  className="text-decoration-none">Member Spotlights</Link>
                                                                </div>
                                                            </div>      
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" to={'https://firm.pcma.klizos.com/members-search'}>Member Portal</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" >Diversity & Inclusion</Link>
                                                                </div>
                                                            </div>      
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                           Directories
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                          <li><Link to={'https://firm.pcma.klizos.com/'} className="dropdown-item">Members Only</Link></li>
                                                                          <li><Link to={'https://firm.pcma.klizos.com/our-members/'} className="dropdown-item">Searchable Membership Database</Link></li>
                                                                        <li><Link className="dropdown-item"  >Join a Committee</Link></li>
                                                                        </ul>
                                                                      </div>
                                                                </div>
                                                            </div>                                                                
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" >Member Resources</Link>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade show" id="nav-articles" role="tabpanel" aria-labelledby="nav-articles-tab">
                                                        <div className="row">
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                           Seminars
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                          <li><Link  className="dropdown-item">In-person</Link></li>
                                                                          <li><Link  className="dropdown-item">Webinars/Virtual</Link></li>
                                                                        </ul>
                                                                      </div>
                                                                </div>
                                                            </div> 
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                          Certificates & Courses
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                          <li><Link  className="dropdown-item">Registration & Compliance</Link></li>
                                                                          <li><Link  className="dropdown-item">Capital Raising</Link></li>
                                                                        </ul>
                                                                      </div>
                                                        
                                                                    </div>        
                                                            </div>
                                                                  
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/pcma-news/'}>Articles/Blog Posts</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" >Content Submission Guidelines</Link>
                                                                </div>
                                                            </div>       
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" >PCMA Weekly E-News</Link>
                                                                </div>
                                                            </div>
                                                           
                                                            
                                                          </div>
                                                    </div>
                                                    <div className="tab-pane fade show" id="nav-advocacy" role="tabpanel" aria-labelledby="nav-advocacy-tab">
                                                            <div className="row">
                                                                <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                    <div className="sub_menu">                                             
                                                                        <div className="dropdown">
                                                                            <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                               Advocacy Committee
                                                                            </button>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                            <li><Link className="dropdown-item" >Members</Link></li>
                                                                            <li><Link className="dropdown-item" >How to join</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                    <div className="sub_menu">                                             
                                                                        <div className="dropdown">
                                                                            <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                              Advocacy & Action
                                                                            </button>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                            <li><Link className="dropdown-item" to={'https://pcma1dev.wpenginepowered.com/comment-letters-page/'} >Comment Letters</Link></li>
                                                                            <li><Link className="dropdown-item" >Meetings with Regulators & Government</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>  
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" >PCMA Research Papers</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" > Member Roundtables</Link>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                
                                                    </div>
                                                    <div className="tab-pane fade show" id="nav-events" role="tabpanel" aria-labelledby="nav-events-tab">
                                                          <div className="row">
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none"  style={{ cursor: 'default' }}>Conferences</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none"  style={{cursor:'default'}}>Meetups</Link>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade show" id="nav-sponsors" role="tabpanel" aria-labelledby="nav-sponsors-tab">
                                                          <div className="row">
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            Members
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                            <li><Link className="dropdown-item">Compliance Resources</Link></li>
                                                                            <li><Link  className="dropdown-item">CSA Webinars/Seminars</Link></li>
                                                                            <li><Link to={'https://firm.pcma.klizos.com/'} className="dropdown-item">Member Only Tools</Link></li>
                                                                            <li><Link  className="dropdown-item">Template Documents</Link></li>
                                                                            <li><Link  className="dropdown-item">Member Surveys & Results</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                            <div className="sub_menu">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        Public
                                                                    </button>
                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                        <li><Link className="dropdown-item">Find an EMD</Link></li>
                                                                        <li><Link className="dropdown-item">Glossary & Terms to Know</Link></li>
                                                                        <li><Link  className="dropdown-item">Key CSA Forms</Link></li>
                                                                         <li><Link to={'https://pcma1dev.wpenginepowered.com/key-regulatory-documents/'} className="dropdown-item">Key Regulatory Documents</Link></li>
                                                                        <li><Link className="dropdown-item">Report a Compliant</Link></li>
                                                                        <li><Link to={'https://pcma1dev.wpenginepowered.com/industry-links/'} className="dropdown-item">Industry Links</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            Sponsors
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                            <li><Link className="dropdown-item">Existing Sponsors</Link></li>
                                                                            <li><Link className="dropdown-item">Sponsorship Opportunities</Link></li>                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>      
                                                            
                                                          </div>
                                                    </div>
                                                    <div className="tab-pane fade show" id="nav-media" role="tabpanel" aria-labelledby="nav-media-tab">
                                                        <div className="row">
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 ">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none">PCMA Press Releases</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none" >PCMA in the News</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none">CSA News Releases</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none">Press/Social Media kit</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none">Press/Social Media Guidelines</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                    <Link className="text-decoration-none"to={"https://pcma1dev.wpenginepowered.com/private-investor-magazine/"}>PCMA Magazine</Link>
                                                                </div>
                                                            </div>       
                                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                                <div className="sub_menu">
                                                                 
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton10" data-bs-toggle="dropdown" aria-expanded="false">
                                                                              Social Media Links
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                                                                            <li><Link className="dropdown-item" >LinkedIn</Link></li>
                                                                            <li><Link className="dropdown-item" >FaceBook</Link></li>
                                                                            <li><Link className="dropdown-item">YouTube</Link></li>
                                                                            {/* <li><Link className="dropdown-item" >Twitter</Link></li> 
                                                                            <li><Link className="dropdown-item" >Podcast</Link></li>       */}
                                                                        </ul>
                                                                      </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                
                                                </div>
                                            </div>
                                            <div className="d-block d-md-none">
                                                <div className="col-12">
                                                    <div className="sub_menu">
                                                        <Link className="text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/contact-us/'}>Contact Us</Link>
                                                    </div>
                                                </div>
                                            </div> 
                                         
                                        </div>
                                            
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                     </ul>
                        <div className="d-grid gap-2 d-lg-flex justify-content-center justify-content-lg-end d-none">
                              <Link className="btn btn-primary btn-nav text-decoration-none" to={'https://pcma1dev.wpenginepowered.com/contact-us/'}>Contact Us</Link>
                     </div>
                  </div>
               </div>
            </nav>
      </div>
   </>
  );
};

export default NavbarGlobal;