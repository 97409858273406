import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Row,
    Col,
    Table,
    Modal,
    Button,
    Form,
} from "react-bootstrap";
import axios from "axios";
import ResponsivePagination from "react-responsive-pagination";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate, Link } from "react-router-dom";
import Firmdetail from "./Firmdetail";
import { useDispatch, useSelector } from 'react-redux';
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import Select from "react-select";
import { Country, State } from "country-state-city";
import { setPageNumber } from "../../redux/slice/firmdNumber";
import CustomPagination from "../../components/pagination/CustomPagination";

const AdminRegistrant = () => {
    let token;
    const navigate = useNavigate();
    const [editId, setEditId] = useState();
    const [nrd, setNrd] = useState();
    const [editOpen, setEditOpen] = useState(false);
    const [totalUsers, setTotalUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modelshow, setModelShow] = useState(false);
    const userData = JSON.parse(window.localStorage.getItem("loginAdmin"));
    const [count, setCount] = useState([0]);
    const [incriment, setIncriment] = useState(1);
    const [file, setFile] = useState();
    const [totalPages, setTotalPages] = useState(null);
    const [search, setSearch] = useState("");
    const [NrdVlue, setNrdVlue] = useState();
    const [Nrderror, setNrderror] = useState('');
    const [sort, setSort] = useState(null);
    const [orderName, setOrderName] = useState("");
    const [shortstatus, setshortstatus] = useState(null);
    const [shortName, setshortName] = useState(null);
    const render = useRef(0);
    const [spinner, setSpinner] = useState(false);
    const [regioncatgoricesValue, setRegioncatgoricesValue] = useState('');
    const [otherName, setotherName] = useState('');
    const [specialitiesVlaue, setSpecialitiesVlaue] = useState('');
    const [isdisabled, setIsDisabled] = useState(true);
    const [reginIsdisabled, reginSetIsDisabled] = useState(true);
    const pageNumberStore = useSelector(state => state.pageNumber);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(pageNumberStore.value);
    const [goToPage, setGoTOPage] = useState(0);



    dispatch(setPageNumber(1));


    const handlePageSearch = (val) => {
        const inputPage = parseInt(val);
        if (!isNaN(inputPage) && inputPage > 0 && inputPage <= totalPages) {
            setCurrentPage(inputPage);
        } else if (inputPage >= totalPages) {
            toast.error(`Page ${inputPage} does not exist`)
        }
    };


    const [addressIndex, setAddressIndex] = useState();
    const [address, setAddress] = useState({
        country: null,
        state: null,
    });
    const [resionAddress, setResionAddress] = useState({
        country: null,
        state: null,
    });
    const countries = Country.getAllCountries();
    const [countryCode, setCountryCode] = useState("IN");
    const [resionCountryCode, setResionCountryCode] = useState("IN");

    const [regionData, setRegionData] = useState([]);
    const excludedCountryNames = [
        "Aland Islands",
        "American Samoa",
        "Anguilla",
        "Antarctica",
        "Aruba",
        "Bouvet Island",
        "Cayman Islands",
        "Cook Islands",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji Islands",
        "Heard Island and McDonald Islands",
        'British Indian Ocean Territory',
        'Christmas Island',
        'Cocos (Keeling) Islands',
        'French Guiana',
        'French Polynesia',
        'French Southern Territories',
        'Gibraltar',
        'Greenland',
        'Guadeloupe',
        'Guam',
        'Guernsey and Alderney',
        'Jersey',
        'Macau S.A.R.',
        'Man (Isle of)'
    ];
    let countryName = "";
    let stateName = "";
    let resionCountryName = "";
    let resionStateName = "";

    const resionUpdatedCountries = countries.filter(country => !excludedCountryNames?.includes(country.name))
        .map(country => ({
            label: country.name,
            value: country.id,
            ...country,
        }));

    const updatedCountries = countries.filter(country => !excludedCountryNames?.includes(country.name))
        .map(country => ({
            label: country.name,
            value: country.id,
            ...country,
        }));


    const resionUpdatedStates = Object.values(
        State.getStatesOfCountry(resionCountryCode)
    ).map((state) => ({
        label: state.name,
        value: state.id,
        ...state,
    }));

    const updatedStates = Object.values(
        State.getStatesOfCountry(countryCode)
    ).map((state) => ({
        label: state.name,
        value: state.id,
        ...state,
    }));

    const [newRegion, setNewRegion] = useState({
        region_name: '',
        region_country: '',
        categories: []
    });



    const selectInputRef = useRef();
    const countryInputRef = useRef();




    const [catagorisDatanew, setcatagorisDatanew] = useState([]);
    const [arrayOtherName, setArrayOtherName] = useState([]);
    const [arrayspecialities, setArrayspecialities] = useState([]);
    const reginCategoresAdd = () => {
        if (regioncatgoricesValue) {
            setcatagorisDatanew(newRegion.categories.push(regioncatgoricesValue))
            setRegioncatgoricesValue('')
        }

    }


    const deleteItemCategore = (index) => {
        let data = newRegion.categories.splice(index, 1);
        setcatagorisDatanew(data)

    }



    const [formData, setFormData] = useState({
        category: '',
        country: '',
        city: '',
        description: '',
        firm: '',
        FirmType: '',
        geographicArea: '',
        headOfficeStreetName: '',
        headOfficeBuildingName: '',
        headOfficeCity: '',
        headOfficePostalCode: '',
        headOfficePhone: '',
        numberOfPeople: '',
        lastFundingRaised: '',
        lastFundingCurrency: '',
        lastFundingDate: '',
        lastFundingType: '',
        linkedinUrl: '',
        location: '',
        nrd: '',
        otherName: [],
        postalCode: '',
        phoneNumber: '',
        province: '',
        longitude: '',
        latitude: '',
        specialities: [],
        tagline: '',
        website: '',
        yearFounded: '',
        region: []
    });





    const [errors, setErrors] = useState({
        firm: '',
        nrd: '',
        location: '',
        city: '',
        postalCode: '',
        headOfficeStreetName: '',
        headOfficeCity: '',
        headOfficePostalCode: '',
        headOfficePhone: '',
        // geographicArea: '',
        category: '',
        website: '',
        linkedinUrl: '',
        lastFundingCurrency: '',
        country: '',
        province: '',
        tagline: '',
        longitude: '',
        latitude: '',
        yearFounded: '',
        phoneNumber: '',
    });


    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;


    const otherNameAdd = () => {
        if (otherName) {
            setArrayOtherName(formData.otherName.push(otherName))
            setotherName('')
        }

    }
    const specialitiesAdd = () => {
        if (specialitiesVlaue) {
            setArrayspecialities(formData.specialities.push(specialitiesVlaue))
            setSpecialitiesVlaue('')
        }

    }

    const deleteOtherName = (index) => {
        let data = formData.otherName.splice(index, 1);
        setArrayOtherName(data)
    }
    const deleteSpecialities = (index) => {
        let data = formData.specialities.splice(index, 1);
        setArrayspecialities(data)
    }

    let newErrors = { ...errors };


    const reginCategoresData = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value?.trimStart();
        setRegioncatgoricesValue(trimmedValue)
        reginValidateField(name, value);
    }
    const otherNameValue = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value?.trimStart();
        setotherName(trimmedValue)
        reginValidateField(name, value);
    }
    const specialitiesChange = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value?.trimStart();
        setSpecialitiesVlaue(trimmedValue)
        reginValidateField(name, value);
    }



    const reginValidateField = (fieldName, value) => {
        if (fieldName === 'reginCatagores') {
            newErrors.reginCatagores = value === '' ? '' : '';
        }
        else if (fieldName === 'otherName') {
            newErrors.otherName = value === '' ? '' : '';
        }
        else if (fieldName === 'specialities') {
            newErrors.specialities = value === '' ? '' : '';
        }
        setErrors(newErrors);
    }

    const validateField = (fieldName, value) => {

        if (fieldName === 'firm') {
            newErrors.firm = value === '' ? 'Firm name is required.' : '';
        }
        else if (fieldName === 'nrd') {
            let pattern = /^[0-9]+$/;
            // newErrors.nrd = value === '' ? 'NRD number is required.' : (value.length > 0 && value.length < 5 ? 'NRD number must be at  5 digits.' : '');
            if (value.length == '') {
                newErrors.nrd = 'NRD number is required.'
            }
            else if (value.length > 0 && value.length < 5) {
                newErrors.nrd = 'NRD number must be at  5 digits.';
            }
            else if (!pattern.test(value)) {
                newErrors.nrd = 'Only numbers are allowed.';
            } else {
                newErrors.nrd = ''
            }

        }

        else if (fieldName === 'location') {
            newErrors.location = value === '' ? 'Location  is required.' : '';
        }
        else if (fieldName === 'city') {
            newErrors.city = value === '' ? 'City name is required.' : '';
        }
        else if (fieldName === 'postalCode') {
            let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{5,10}(?<!-)$/ ;
            if (value.length == '') {
                newErrors.postalCode = 'Postal code is required.'
            }
            else if (!pattern.test(value)) {
                newErrors.postalCode = 'Invalid  postal code';
            } else {
                newErrors.postalCode = ''
            }

        }
        else if (fieldName === 'headOfficeStreetName') {
            newErrors.headOfficeStreetName = value === '' ? 'Head office street name  is required.' : '';
        }

        else if (fieldName === 'headOfficeCity') {
            newErrors.headOfficeCity = value === '' ? 'Head office city name  is required.' : '';
        }
        else if (fieldName === 'headOfficePostalCode') {

            let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{5,10}(?<!-)$/ ;
            if (value.length == '') {
                newErrors.headOfficePostalCode = 'Head office postal Code is required.'
            }
            else if (!pattern.test(value)) {
                newErrors.headOfficePostalCode = 'Invalid  postal code';
            } else {
                newErrors.headOfficePostalCode = ''
            }

        }
        else if (fieldName === 'headOfficePhone') {
            let pattern = /^[0-9+]+$/
            if (value === '') {

                if (!pattern.test(value)) {
                    if (value === '') {
                        newErrors.headOfficePhone = ''
                        // newErrors.headOfficePhone = 'Head office phone number is required.'
                    }
                    else {
                        newErrors.headOfficePhone = 'Only numbers are allowed.';
                    }
                } else {
                    newErrors.headOfficePhone = ''
                    // newErrors.headOfficePhone = 'Head office phone number is required.'
                }
            }
            else if (value.length < 10 || value.length > 10) {
                if (!pattern.test(value)) {
                    newErrors.headOfficePhone = 'Only numbers are allowed.';
                } else {
                    newErrors.headOfficePhone = 'Phone number must be at least 10 digits.'
                }
            }
            else if (!pattern.test(value)) {
                newErrors.headOfficePhone = 'Only numbers are allowed.';
            }
            else {
                newErrors.headOfficePhone = ''
            }

        }

        // if (fieldName === 'geographicArea') {
        //     newErrors.geographicArea = value === '' ? 'Geographical area  is required.' : '';
        // }
        if (fieldName === 'category') {
            newErrors.category = value === '' ? 'Category  is required.' : '';
        }
        if (fieldName === 'type') {
            newErrors.type = value === '' ? 'Type  is required.' : '';
        }
        if (fieldName === 'website') {
            let pattern = /^(http:\/\/www.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
            if (value.trim() === '') {
                newErrors.website = ' Website is required.';
            } else if (!pattern.test(value)) {
                newErrors.website = 'Please enter a valid URL! Example: http://www.example.com or www.example.com';
            } else {
                newErrors.website = '';
            }
        }
        if (fieldName === 'linkedinUrl') {
            let pattern = /^(https?:\/\/)((www|\w\w)\.)?linkedin\.com\/(([\w]{2,3})?|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
            if (value.trim() === '') {
                newErrors.linkedinUrl = ' Linkedin URL is required.';
            } else if (!pattern.test(value)) {
                newErrors.linkedinUrl = 'Please enter a valid URL! Example: http://nl.linkedin.com/in/name/';
            } else {
                newErrors.linkedinUrl = '';
            }
        }


        // else if (fieldName === 'lastFundingCurrency') {
        //   newErrors.lastFundingCurrency = value === '' ? 'Last funding currency  is required.' : '';
        // }



        else if (fieldName === 'tagline') {
            newErrors.tagline = value === '' ? 'Tag line  is required.' : '';
        }
        if (fieldName === 'longitude') {
            let pattern = /^(-?((1[0-7]|[0-9])?\d(\.\d{1,2})?|180(\.0+)?))$/;
            if (value.trim() === '') {
                newErrors.longitude = 'Longitude  is required.';
            } else if (!pattern.test(value)) {
                newErrors.longitude = 'Please enter a valid number! Example: 45, -123.45 , 180, 0.12';
            } else {
                newErrors.longitude = '';
            }
        }
        if (fieldName === 'latitude') {
            let pattern = /^[-]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
            if (value.trim() === '') {
                newErrors.latitude = 'Latitude  is required.';
            } else if (!pattern.test(value)) {
                newErrors.latitude = 'Please enter a valid number! Example: 90 , -23.45 , 45.678 ';
            } else {
                newErrors.latitude = '';
            }

        }
        if (fieldName === 'yearFounded') {
            newErrors.yearFounded = value === '' ? 'Year founded  is required.' : '';
        }
        if (fieldName === 'phoneNumber') {
            let pattern = /^[0-9+]+$/
            if (value === '') {

                if (!pattern.test(value)) {
                    if (value === '') {
                        newErrors.phoneNumber = 'Phone number is required.'
                    }
                    else {
                        newErrors.phoneNumber = 'Only numbers are allowed.';
                    }
                } else {
                    newErrors.phoneNumber = 'Phone number is required.'
                }
            }
            else if (value.length < 10) {
                if (!pattern.test(value)) {
                    newErrors.phoneNumber = 'Only numbers are allowed.';
                } else {
                    newErrors.phoneNumber = 'Phone number must be at least 10 digits.'
                }
            }
            else if (!pattern.test(value)) {
                newErrors.phoneNumber = 'Only numbers are allowed.';
            }
            else {
                newErrors.phoneNumber = ''
            }
        }
        setErrors(newErrors);

    }



    const handleCountryChange = (value) => {
        countryName = value.name;
        formData.country = countryName
        countryValidateField('country', formData.country);
        setCountryCode(value.isoCode);
        setFormData({
            ...formData,
            country: countryName,
        });
        setIsDisabled(false);
        stateValidateField('province', stateName);
        handleAddressChange({ country: value, state: null });
    };


    const RegionCountryValidateField = (country, value) => {
        if (country === 'region_country') {

            newErrors.regionCountry = value === '' ? 'Country field is required.' : '';
            setErrors(newErrors);
        }
        setErrors(newErrors);

    }

    const handleStateChange = (label) => {
        setFormData({
            ...formData,
            province: label.name,
        });
        stateName = label.name;

        stateValidateField('province', stateName);
        handleAddressChange({ ...address, state: label });
    };


    const stateValidateField = (fieldName, value) => {
        if (fieldName === 'province') {
            newErrors.province = value === '' ? 'Province field is required.' : '';
            setErrors(newErrors);
        }
        setErrors(newErrors);
    }
    const RegionStateValidateField = (fieldName, value) => {
        if (fieldName === 'region_name') {
            newErrors.reginState = value === '' ? 'Province field is required.' : '';
            setErrors(newErrors);
        }
        setErrors(newErrors);
    }

    const handleAddressChange = (newAddress) => {
        setAddress(newAddress);
    };


    const RegionHandleCountryChange = (value, index) => {
        if (value) {
            setAddressIndex(index);
            resionCountryName = value.name;
            newRegion.region_country = resionCountryName
            RegionCountryValidateField('region_country', newRegion.region_country);
            setResionCountryCode(value.isoCode);
            setNewRegion({
                ...newRegion,
                region_country: resionCountryName,
                region_name: null,
            });
            reginSetIsDisabled(false);
            RegionStateValidateField('region_name', stateName);
            RegionHandleAddressChange({ country: value, state: null });
        }
    };

    const RegionHandleStateChange = (label) => {
        if (label) {
            setNewRegion({
                ...newRegion,
                region_name: label.name,
            });
            resionStateName = label.name;

            RegionStateValidateField('region_name', resionStateName);
            RegionHandleAddressChange({ ...resionAddress, state: label });
        }
    }

    const RegionHandleAddressChange = (newAddress) => {
        setResionAddress(newAddress);
    };

    // what is the use of this function

    const handleKeyPress = (e) => {
        const char = String.fromCharCode(e.keyCode || e.which);
        if (!/^[0-9+]+$/.test(char)) {
            e.preventDefault();
        }
    };

    // what is the use of this function
    const handleKeyOnlyLatter = (e) => {
        const char = String.fromCharCode(e.keyCode || e.which);
        if (!/^[a-zA-Z\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(char)) {
            e.preventDefault();
        }
    };

    // what is the use of this function
    const handleKeyPressLatter = (e) => {
        const char = String.fromCharCode(e.keyCode || e.which);
        if (!/^[a-zA-Z\s]+$/.test(char)) {
            e.preventDefault();
        }
    };


    const countryValidateField = (country, value) => {


        if (country === 'country') {
            newErrors.country = value === '' ? 'Country is required.' : '';
            setErrors(newErrors);
        }
        setErrors(newErrors);

    };

    const listItemRemove = (index) => {
        const newArray = [...regionData];
        newArray.splice(index, 1);
        setRegionData(newArray);
    };




    const validateForm = () => {
        for (const fieldName in formData) {
            if (formData.hasOwnProperty(fieldName)) {
                validateField(fieldName, formData[fieldName]);
                reginValidateField(fieldName, regioncatgoricesValue);
                countryValidateField(fieldName, formData[fieldName]);
                stateValidateField(fieldName, formData[fieldName]);

            }
        }
        setErrors(newErrors);
    };
    const RegionvalidateForm = () => {
        for (const fieldName in newRegion) {
            if (newRegion.hasOwnProperty(fieldName)) {
                RegionCountryValidateField(fieldName, newRegion[fieldName]);
                RegionStateValidateField(fieldName, newRegion[fieldName]);
            }
        }
        setErrors(newErrors);

    };
    const listAdd = () => {
        RegionvalidateForm();
        newErrors.reginCatagores = newRegion.categories.length === 0 ? 'Categories  is required.' : '';
        if (newRegion.region_country && newRegion.region_name && (newRegion.categories.length > 0)) {
            let arr = [];
            arr.push(newRegion);

            setRegionData([...regionData, ...arr]);
            setNewRegion({
                region_name: '',
                region_country: '',
                categories: []
            })
            selectInputRef.current.clearValue();
            countryInputRef.current.clearValue();
        }

    }



    const editUser = (e) => {
        setEditOpen(!editOpen);
        setEditId(e);
    };

    useEffect(() => {
        document.title = "PCMA ||  Registered Individuals Management";
    }, []);

    useEffect(() => {
        redirectToLogin();
    });

    const handlePageChange = (page) => {
        setCurrentPage(page);
        // dispatch(setPageNumber(page));
    };

    const handleSorting = (event) => {
        if (event === "registrant_name") {
            setshortstatus(null)
            setOrderName("registrant_name");
            if (sort === null) {
                setSort(0);
                setshortName(0);
                setCurrentPage(1);
            }
            else if (sort === 1) {
                setSort(0);
                setshortName(0);
                setCurrentPage(1);
            }
            else if (sort === 0) {
                setSort(1);
                setshortName(1);
                setCurrentPage(1);
            }
        }
        else if (event === "featured") {
            setshortName(null)
            setOrderName("featured");
            if (sort === null) {
                setSort(0);
                setshortstatus(0);
                setCurrentPage(1);
            }
            else if (sort === 1) {
                setSort(0);
                setshortstatus(0);
                setCurrentPage(1);
            }
            else if (sort === 0) {
                setSort(1);
                setshortstatus(1);
                setCurrentPage(1);
            }
        }
    };

    const redirectToLogin = () => userData ? (token = userData.Token) : ((token = null), token === null && navigate("/admin/login"));

    useEffect(() => {
        userDatas();
    }, [currentPage, sort,shortName]);

    useEffect(() => {
        let timerOut = setTimeout(() => {
            if (render.current !== 0) {
                if (currentPage === 1) {
                    userDatas();
                } else {
                    setCurrentPage(1);
                }
            }
            render.current++;
        }, 1000);

        return () => clearTimeout(timerOut)
    }, [search])

    const userDatas = () => {
        setIsLoading(true);
        if (token) {
            axios
                .get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/registrant-search`, {
                    params: {
                        page: currentPage,
                        search: search,
                        order_name: orderName,
                        order_status: sort,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    if (response?.data?.message === "Unauthozied User") {
                        localStorage.removeItem('loginAdmin');
                        navigate("/admin");
                        toast.error(`Unauthozied user !`);
                    }
                    if (response?.data?.message === "No data found") {
                        setTotalUsers([]);
                        setTotalPages(null);
                    } else {
                        setTotalUsers(response.data?.registrants);
                        setTotalPages(response.data?.totalPages);
                    }


                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);

                    if (error?.response?.data?.message === "No Data found") {
                        setTotalUsers([]);
                        setTotalPages(null);
                    }
                    if (error?.message === "Request failed with status code 401") {
                        localStorage.removeItem("loginAdmin");
                        navigate("/admin/login");
                    }
                });
        }
    };


    useEffect(() => {
        if (modelshow === true) {
            setRegionData([]);
            const clearedFormData = {};
            for (const key in formData) {
                if (Array.isArray(formData[key])) {
                    clearedFormData[key] = [];
                } else {
                    clearedFormData[key] = '';
                }
            }

            const clearedErrors = {};
            for (const key in errors) {
                if (Array.isArray(errors[key])) {
                    clearedErrors[key] = [];
                } else {
                    clearedErrors[key] = '';
                }
            }
            const clearedRegion = {};
            for (const key in newRegion) {
                if (Array.isArray(newRegion[key])) {
                    clearedRegion[key] = [];
                } else {
                    clearedRegion[key] = '';
                }
            }
            setotherName('');
            setSpecialitiesVlaue('');
            setFile(null);
            setNewRegion(clearedRegion);
            address.country = null;
            setErrors(clearedErrors);
            setFormData(clearedFormData);
        }
    }, [modelshow])

    const handleClose = () => {
        setModelShow(false);
    };
    const handleShow = () => {
        setModelShow(true);
    };
    const handleClone = (e) => {
        e.preventDefault();
        const newCount = count.slice();
        setIncriment(incriment + 1);
        newCount.push(incriment);
        setCount(newCount);
    };
    const remove = (index) => {
        if (count.length > 1) {
            const newCount = count.slice();
            newCount.splice(index, 1);
            setCount(newCount);
        }
    };

    //   const deleteFirm = async (id) => {
    //     try {
    //       const confirmDeletion = await Swal.fire({
    //         title: "Delete Firm",
    //         text: "Do you want to delete this firm? This action cannot be undone.",
    //         icon: "warning",
    //         showCancelButton: true,
    //         cancelButtonColor: "#D0D5DD",
    //         confirmButtonColor: "#D92D20",
    //         confirmButtonText: "Delete",
    //         iconHtml: '<i className="fas fa-trash-alt"></i>',
    //         iconColor: "red",
    //       });
    //       if (!confirmDeletion.isConfirmed) return;
    //       setIsLoading(true);
    //       const response = await axios.delete(
    //         `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firm-delete/${id}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       );
    //       if (response?.data?.message === "Unauthozied User") {
    //         localStorage.removeItem('loginAdmin');
    //         navigate("/admin");
    //         toast.error(`Unauthozied user !`);
    //       }
    //       userDatas();
    //       if (response.data.message === "Firm deleted successfully") {
    //         toast.success(response.data.message);
    //         setIsLoading(false);
    //       } else {
    //         toast.error(response.data.message);
    //       }
    //     } catch (error) {
    //       console.error("Error deleting Firm:", error);
    //       toast.error("Something went wrong please try again later.");
    //     }
    //   };


    // const handleDeleteRegistrant = async (registrant_id) => {
    //     try {
    //       const { isConfirmed } = await Swal.fire({
    //         title: "Delete Registrant",
    //         text: "Do you want to delete this Registrant? This action cannot be undone.",
    //         icon: "warning", showCancelButton: true,
    //         cancelButtonColor: "#D0D5DD",
    //         confirmButtonColor: "#D92D20",
    //         confirmButtonText: "Delete",
    //         iconHtml: '<i class="fas fa-trash-alt"></i>', iconColor: "red"
    //       });
    //       if (!isConfirmed) return;
    //       const response = await axios.delete(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/delete-registrant/${params.bid}/${registrant_id}`,
    //         { headers: { Authorization: `Bearer ${token}` } });
    //       if (response?.data?.message === 'Registrant deleted successfully.')
    //         toast.success(response.data.message);
    //     //   Notes();
    //     userDatas();
    //     } catch (error) {
    //       if (error?.response?.data?.message === 'Server Error') {
    //         navigate("/not-found")
    //       } else if (error?.message === "Request failed with status code 401") {
    //         localStorage.removeItem('loginAdmin');
    //         navigate("/admin/login");
    //         toast.error(`Unauthozied user !`);
    //       } else {
    //         toast.error("Something went wrong please try again later.");
    //       }

    //     }
    //   };

    const handlereset = () => {
        setOrderName("");
        setSort(null);
        setSearch('');
        setshortName(null);
        setshortstatus(null);
        // setSortName(null);
        // setSortNameStatus(null);
        // setSearchQuery("");
      };

    const handleSearch = (e) => {
        setSearch(e.target.value);
        // setCurrentPage(1);
    };
    
    const exportRegistrantCSV = async () => {
        setSpinner(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/export-registrants`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params:{
                    search:search
                }
            })
            if (response?.data?.message === "Unauthozied user") {
                localStorage.removeItem('login');
                navigate("/");
                toast.error(`Unauthozied user !`);
            }
            let csvResponseData = response?.data;
            const blob = new Blob([csvResponseData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'PCMA Registered Individuals Details.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setSpinner(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            if (error?.message === 'Request failed with status code 401') {
                localStorage.removeItem("loginAdmin");
                navigate("/admin/login");
                toast.error(`Unauthozied user !`);
            }
            setSpinner(false);
        }

    };

    // useEffect(()=>{
    //   setCurrentPage(1);
    // },[search])

    const toggleStatus = async (id, currentStatus) => {
        setIsLoading(true);
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/firm-status/${id}`,
                {
                    status: !currentStatus,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const updatedStats = totalUsers?.map((user) =>
                user?._id === id ? { ...user, status: !currentStatus } : user
            );
            if (response?.data?.message === "Unauthozied User") {
                localStorage.removeItem('loginAdmin');
                navigate("/admin");
                toast.error(`Unauthozied user !`);
            }
            userDatas();
            toast.success(response?.data?.message);
        } catch (error) {
            console.log("error", error);
        }
    };


    const featuredToggleStatus = async (nrd) => {
        setIsLoading(true);
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/feature/registrant/${nrd}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response?.data?.message === "Unauthozied User") {
                localStorage.removeItem('loginAdmin');
                navigate("/admin");
                toast.error(`Unauthozied user !`);
            }
            userDatas();
            toast.success(response?.data?.message);
             setCurrentPage(1)
        } catch (error) {
            console.log("error", error);
        }
    };

    const handleupload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                toast.error("File size should be under 2MB");
                return;
            }

            const reader = new FileReader();
            reader.onload = function (event) {
                setFile(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleCancel = () => {
        if (file) {
            URL.revokeObjectURL(file);
        }
        setFile(null);
    };

    // const handleSubmit = () => {
    //   setModelShow(false);
    // };


    // const firmDetail = (data) => {
    // setEditOpen(true);
    // if (data) {
    //   setEditId(data?.id);
    //   setNrd(data?.NRD)
    // }
    // console.log(data);

    // navigate(`/admin/firms/${data?.id}/${data?.NRD}`)
    // };

    const adminFirmUpdate = (firmId) => {
        navigate(`/admin/firmupdate/${firmId}`);
    };

    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [registrantId, setRegistrantId] = useState(null);

    const handleEditModal = (id) => {
        setShowEdit(true)
        setRegistrantId(id)
    }


    const handleNav = (data) => {
        navigate(`/admin/firms/${data}`)
    }



    return (

            <>
                {editOpen ? (
                    <>
                        <Firmdetail
                            editUserId={editId}
                            sendEditOpenData={editOpen}
                            sendeditOpen={editUser}
                            nrdID={nrd}
                        />
                    </>
                ) : (
                    <>
                        <Container fluid>
                            <Row>
                                <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    style={{ background: "#137CD9", color: "#fff", borderRadius: "5px", margin: "37px 0 0 0", }}
                                >
                                    <h2 className="mb-0 py-2 firmManagement"> Registered Individuals Management</h2>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    className="d-flex  justify-content-center justify-content-sm-start py-2 align-items-center ps-0 registrantSearchBar areaFlex"
                                >
                                    <div className="admin_user_search searchOutline ps-0 wrappppp">
                                        <input
                                            type="search"
                                            name=""
                                            id=""
                                            className="adminSearchBar"
                                            placeholder="Search"
                                            value={search}
                                            onChange={handleSearch}
                                            style={{ maxWidth: '100%' }}
                                            

                                            onKeyPress={(e) => {
                                                if(e.key === 'Enter'){
                                                    e.preventDefault();
                                                    if (search?.length > 0) {
                                                        userDatas();
                                                    }
                                                  
                                                } 
                                               }}
                                        />
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </div>


                                    {/* <div className="px-3">
                        <button
                          className="reset_button btn"
                          onClick={handlereset}
                        >
                          <i
                            className="fa-solid fa-arrow-rotate-right"
                            style={{ color: "#0F66B4" }}
                          ></i>
                        </button>
                      </div> */}


                                    <div className="tooltip-container resetTooltip ms-3">
                                        <button
                                            className="reset_button btn"
                                            onClick={handlereset}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i
                                                className="fa-solid fa-arrow-rotate-right"
                                                style={{ color: "#0F66B4" }}
                                            ></i>
                                        </button>
                                        <div className="tooltip-text">Reset</div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="d-flex  py-2 justify-content-sm-end justify-content-center align-items-center ps-0 registrantSearchBar areaFlex">
                                    <div className="" style={{ paddingLeft: "10px" }}> 
                                        {spinner ? (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'not-allowed', opacity: "0.9" }} disabled={spinner} >Loading<i className="fa-solid fa-spinner fa-spin ms-1"></i></button>) :
                                            totalUsers?.length === 0 ? (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'not-allowed', opacity: "0.9" }} disabled ><i className="fa-solid fa-download my-1" style={{ color: '#fff', }}></i>Export</button>) :
                                                (<button className="p-2 adminadd_btn" style={{ color: '#fff', backgroundColor: '#0F66B4', cursor: 'pointer' }} onClick={() => { exportRegistrantCSV() }}><i className="fa-solid fa-download my-1" style={{ color: '#fff', }}></i>Export</button>)}
                                    </div>
                                </Col>        
                            </Row>


                            {isLoading ? (
                                <Container fluid className="loader">
                                    <Row
                                        className="g-2 pb-3 d-flex justify-content-center align-content-center "
                                        style={{ height: "79vh" }}
                                    >
                                        <Col
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            className="d-flex justify-content-center align-content-center"
                                        >
                                            <BallTriangle
                                                height={100}
                                                width={100}
                                                radius={5}
                                                color="#0f66b4"
                                                ariaLabel="ball-triangle-loading"
                                                wrapperClass={{}}
                                                wrapperStyle=""
                                                visible={true}
                                                centered
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            ) : (


                                <Row>
                                    <Col
                                        xs={12}
                                        style={{
                                            background: "#fff",
                                            padding: "0",
                                            borderRadius: "5px",
                                        }}
                                        className="py-0 mt-3"
                                    >
                                        <div className="table-responsive firms_card_box">
                                            <Table className="notice_table_body">
                                                <thead>
                                                    <tr className="firm_table_heading">
                                                        <th
                                                            style={{
                                                                backgroundColor: "rgba(221, 235, 242, 1)",
                                                                borderRadius: "5px 0 0px 0",
                                                                color: "#137CD9", fontWeight: "700", fontSize: "17px", borderBottom: "0"
                                                            }}
                                                            className="ps-5"
                                                        >
                                                            NAME
                                                            <span
                                                                style={{ cursor: 'pointer' }}
                                                                className="ms-2"
                                                                onClick={() => handleSorting("registrant_name")}
                                                            >
                                                                {shortName === 0 ? (
                                                                    <i className="fa-solid fa-arrow-down-z-a"></i>
                                  
                                ) : shortName === 1 ? (
                                    <i className="fa-solid fa-arrow-up-a-z"></i>
                                ) : (
                                  <i className="fa-solid fa-arrow-right-arrow-left fa-rotate-90"></i>
                                )}
                                                            </span>
                                                        </th>

                                                        <th
                                                            style={{
                                                                backgroundColor: "rgba(221, 235, 242, 1)",
                                                                borderRadius: "0",
                                                                color: "#137CD9", fontWeight: "700", fontSize: "17px", borderBottom: "0"
                                                            }}
                                                            className="ps-5"
                                                        >
                                                            FIRM NAME
                                                            <span
                                                                style={{ cursor: 'pointer' }}
                                                                className="ms-2"
                                                                // onClick={() => handleSorting("name")}
                                                            >
                                                               
                                                            </span>
                                                        </th>

                                                        <th
                                                            style={{
                                                                backgroundColor: "rgba(221, 235, 242, 1)",
                                                                color: "#137CD9",
                                                            }}
                                                            className="d-flex justify-content-center ps-0 pe-0"
                                                        >
                                                            <p
                                                                style={{ whiteSpace: "nowrap" }}
                                                                className="mb-0 areaHeight"
                                                            >
                                                                {" "}
                                                                FEATURED

                                                                <span
                                                                style={{ cursor: 'pointer' }}
                                                                className="ms-2"
                                                                onClick={() => handleSorting("featured")}
                                                            >
                                                                {shortstatus === 0 ? (
                                  <i className="fa-solid fa-arrow-up"></i>
                                ) : shortstatus === 1 ? (
                                  <i className="fa-solid fa-arrow-down"></i>
                                ) : (
                                  <i className="fa-solid fa-arrow-right-arrow-left fa-rotate-90"></i>
                                )}
                                                            </span>
                                                            </p>
                                                        </th>
                                                        <th
                                                            style={{
                                                                backgroundColor: "rgba(221, 235, 242, 1)", borderRadius: "0px 5px 0px 0", color: "#137CD9", fontWeight: "700", fontSize: "17px", borderBottom: "0"
                                                            }}
                                                            className="text-center"
                                                        >
                                                            ACTIONS
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {totalUsers?.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="5" className="text-center">
                                                                <div className="d-flex align-items-center justify-content-center w-100">
                                                                    No Data Found
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        totalUsers?.map((totaluser, index) => {
                                                            return (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td className="ps-sm-5 text-center text-sm-start">
                                                                            {/* <Link
                                      className="me-3"
                                      onClick={() => firmDetail(totaluser._id)}
                                    > */}
                                                                            {totaluser?.name}
                                                                            {/* </Link> */}
                                                                        </td>

                                                                        <td className="ps-sm-5 text-center text-sm-start text-truncate" style={{ maxWidth: '150px', cursor: 'pointer' }} onClick={() => { handleNav(totaluser?.NRD[0]) }}  >
                                                                            {totaluser?.firm_details[0]}
                                                                        </td>



                                                                        <td className="text-center">
                                                                            <div className="form-check form-switch d-flex justify-content-center ps-0   font-family: 'Raleway';">
                                                                                <input
                                                                                    className="form-check-input mx-1"
                                                                                    type="checkbox"
                                                                                    role="switch"
                                                                                    id="flexSwitchCheckChecked"
                                                                                    checked={totaluser?.featured}
                                                                                    onChange={() =>
                                                                                        featuredToggleStatus(
                                                                                            totaluser._id,
                                                                                        )
                                                                                    }
                                                                                />
                                                                                {totaluser?.featured ? "Enabled" : "Disabled"}

                                                                            </div>
                                                                        </td>

                                                                        <td className="text-center ">
                                                                            <div className="d-flex justify-content-center">
                                                                                <div>
                                                                                    <Link
                                                                                        className="me-2"
                                                                                        // onClick={() => firmDetail(totaluser)}
                                                                                        //   to={`/admin/firms/${totaluser._id}/${totaluser.NRD}`}
                                                                                        to={`/admin/registrant-details/${totaluser._id}`}
                                                                                    >
                                                                                        <i
                                                                                            className="fa-solid fa-eye"
                                                                                            style={{ color: "#0F66B4" }}
                                                                                        ></i>
                                                                                    </Link>
                                                                                </div>
                                                                                {/* <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleEditModal(totaluser._id) }
                                      >
                                        <i className="fa-solid fa-edit px-2" style={{ cursor: 'pointer', color: '#0F66B4' }} ></i>
                                      </div> */}
                                                                                {/* 
                                      <div className="px-2">
                                        <Link
                                          href=""
                                          style={{ cursor: "not-allowed" }}
                                        // onClick={() => deleteFirm(totaluser.NRD)}
                                        >
                                          <i
                                            className="fa-solid fa-trash-can"
                                            style={{ color: "#FE6464" }}
                                          ></i>
                                        </Link>
                                      </div> */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                </>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>

                            )}



                        </Container>

                        <Container fluid className="py-2">
                            {totalUsers?.length === 0 ? (
                                <h3> </h3>
                            ) : (
                                <Row>

                                    <Col
                                        sm={12}
                                        className="d-flex justify-content-between pagenation_bar mt-3"
                                        style={{ padding: 0 }}
                                    >
                                        <div>
                                            <CustomPagination
                                                maxWidth={7}
                                                total={totalPages}
                                                current={currentPage}
                                                onPageChange={(page) => handlePageChange(page)}
                                            />

                                        </div>

                                        <div>
                                            {totalPages > 3 ?
                                                <ul className=" list-unstyled new_side_pagination  justify-content-center mt-3">
                                                    <li style={{ whiteSpace: 'nowrap' }}>Go to page</li>
                                                    <li>
                                                        <label htmlFor="pageNumber">
                                                            <input
                                                                type="number"
                                                                name=""
                                                                id="pageNumber"
                                                                placeholder={currentPage}
                                                                onChange={(e) => {
                                                                    setGoTOPage(e.target.value);
                                                                }} />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <div onClick={() => { handlePageSearch(goToPage) }}>
                                                            <i className="fa-solid fa-arrow-right"></i>
                                                        </div>
                                                    </li>
                                                </ul>
                                                : ""}
                                        </div>


                                    </Col>
                                </Row>
                            )}
                        </Container>

                        {/* <RegistrantEdit show={showEdit} setShow={setShowEdit} token={token} nrdID={registrantId} registrantId={registrantId}  onEditDone={Notes}/> */}


                    </>
                )}

            </>
    );
};

export default AdminRegistrant;
