// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Modal, Form } from "react-bootstrap";
// import axios from 'axios';
// import toast from "react-hot-toast";
// import { useNavigate } from 'react-router-dom';
// import { BallTriangle } from 'react-loader-spinner';
// import { useSelector } from 'react-redux';

// const UserMemberShip = () => {
//   const navigate = useNavigate();
//   const logindata = JSON.parse(window.localStorage.getItem("login"));
//   let localUserId;
//   let token;
//   const [isLoading, setIsLoading] = useState(false);
//   if (logindata?.user_data?._id) { localUserId = logindata?.user_data?._id; }
//   if (logindata) { token = logindata.token; }
//   else { token = null; }

//   const [userId, setUserId] = useState()
//   const [data, setData] = useState([])

//   useEffect(() => {
//     document.title = "PCMA || Membership Information";
//   }, []);

//   const [name, setName] = useState('');
//   const [membershipType, setMembershipType] = useState('');
//   const [viewData, setViewData] = useState([]);
// const [membershipTill, setMembershipTill] = useState('');
// const userDatas = useSelector((state) => state.user);

//   const apiData = async () => {
//     setIsLoading(true);
//     try {
//       if (userDatas?._id) {
//         const response = await axios(`${process.env.REACT_APP_PCMA_BACKEND}/api/customer-subscriptions/${userDatas?._id}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           }
//         });
//         setData(response?.data);
//         setMembershipTill(response?.data[response?.data?.length-1]?.period_end)
//         setIsLoading(false);
//       }
//     } catch (error) {
//       if (error?.response?.data?.message === "Unauthozied user") {
//         localStorage.removeItem('login');
//         navigate("/");
//         toast.error(`Unauthozied user !`);
//       }
//     }

//   }
//   useEffect(() => {
//     if(userDatas){
//       setUserId(userDatas?._id)
//           setName(userDatas?.name);
    
//           if (Array.isArray(userDatas?.membership_type)) {
//             setMembershipType(userDatas?.membership_type[0]?.subscription_name)
//           } else {
//             setMembershipType(userDatas?.membership_type)
//           }
//     }

//     apiData();
//   }, [userDatas?._id]);


//   const [show, setShow] = useState(false);

//   // const handleDelete = (id) => {}

//   const handleView = (data) => {
//     setShow(true)
//     setViewData([data])
//   }

//   // const handleEdit = (id) => {}

//   // const convertUnixTimeToCanadaDate = (unixTimestamp, canadaTimeZone) => {
//   //   const utcDate = new Date(unixTimestamp * 1000);
//   //   const canadaDate = new Date(utcDate.toLocaleString('en-US', { timeZone: canadaTimeZone }));
//   //   const canadaDateString = canadaDate.toISOString().split('T')[0];
//   //   return canadaDateString;
//   // };


//   const handleClose = () => {
//     setShow(false);
//   }

//   const handleDownload = (url) => {
//     window.open(url);
//   };

//   return (
//     <>
   
//     <Container fluid className='pt-4'>

//       <div className=" j_account_information" style={{ background: "#137CD9" }}>
//         <span className="px-3 d-flex align-items-center" style={{ color: "white", fontSize: '20px' }}>
//           Membership Information
//         </span>
//       </div>

//       <Row className="g-2" style={{ paddingBottom: "10px" }}>
//         <Col sm={12} md={12} lg={12} className='py-3 d-flex justify-content-start align-items-start flex-column'>
//           <h4 className='network_h4 fs-5'> {name}</h4>
//           <h5>{membershipType}</h5>
//           <p>Your membership is current through {membershipTill}</p>
//           <p> <span style={{ color: 'red' }}>Please note:</span> It appears you have a transaction that is still open (see below.) Your current membership and expiration may change once this transaction has been processed and closed by administration.</p>

//         </Col>

//       </Row>
//       <Row>
//         <Col sm={12} md={12} lg={12}>
//           <div className='table-responsive' style={{ borderRadius: '6px' }}>
//             <table className="userinvoice_table" style={{ width: '100%' }}>
//               <thead style={{ background: 'blue' }} >
//                 <tr className="table-heading-row" >

//                   <th>Status</th>
//                   <th>Transaction Date</th>
//                   <th>Invoice Date</th>
//                   <th>Membership</th>
//                   <th className='py-2'>Annual Membership Fee</th>
//                   <th>Discounted Membership Fee</th>
//                   <th className=' text-center'>Actions</th>
//                 </tr>
//               </thead>
//               {
//                 isLoading ?
//                   <tbody>
//                     <tr>
//                       <td colSpan={7}>
//                         <div className=' d-flex align-items-center justify-content-center'>
//                           <BallTriangle
//                             height={100}
//                             width={100}
//                             radius={5}
//                             color="#0f66b4"
//                             ariaLabel="ball-triangle-loading"
//                             wrapperClass={{}}
//                             wrapperStyle=""
//                             visible={true}
//                           />
//                         </div>
//                       </td>
//                     </tr>
//                   </tbody> :

//                   <tbody>
//                     {data?.map(item => (
//                       <tr key={item?.id}>
//                         <td>{item?.status === 'active' ? "Closed" : "Open"}</td>
//                         <td> {
//                         // convertUnixTimeToCanadaDate
//                         (item?.transaction_date)
//                         }</td>
//                         <td>{
//                         // convertUnixTimeToCanadaDate
//                         (item?.invoice_date)
//                         }</td>
//                         <td>{item?.membership}</td>
//                         <td>$ {item?.subtotal}</td>
//                         <td>$ {item?.total}</td>
                        // <td className='py-1'>
                        //   <div className=' d-flex align-items-center justify-content-center' style={{ gap: '10px' }}>
                        //     <span style={{ cursor: 'pointer' }} onClick={() => { handleView(item) }}>
                        //       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        //         <mask id="mask0_3492_1111" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                        //           <rect width="24" height="24" fill="#137CD9" />
                        //         </mask>
                        //         <g mask="url(#mask0_3492_1111)">
                        //           <path d="M12 16C13.25 16 14.3125 15.5625 15.1875 14.6875C16.0625 13.8125 16.5 12.75 16.5 11.5C16.5 10.25 16.0625 9.1875 15.1875 8.3125C14.3125 7.4375 13.25 7 12 7C10.75 7 9.6875 7.4375 8.8125 8.3125C7.9375 9.1875 7.5 10.25 7.5 11.5C7.5 12.75 7.9375 13.8125 8.8125 14.6875C9.6875 15.5625 10.75 16 12 16ZM12 14.2C11.25 14.2 10.6125 13.9375 10.0875 13.4125C9.5625 12.8875 9.3 12.25 9.3 11.5C9.3 10.75 9.5625 10.1125 10.0875 9.5875C10.6125 9.0625 11.25 8.8 12 8.8C12.75 8.8 13.3875 9.0625 13.9125 9.5875C14.4375 10.1125 14.7 10.75 14.7 11.5C14.7 12.25 14.4375 12.8875 13.9125 13.4125C13.3875 13.9375 12.75 14.2 12 14.2ZM12 19C9.56667 19 7.35 18.3208 5.35 16.9625C3.35 15.6042 1.9 13.7833 1 11.5C1.9 9.21667 3.35 7.39583 5.35 6.0375C7.35 4.67917 9.56667 4 12 4C14.4333 4 16.65 4.67917 18.65 6.0375C20.65 7.39583 22.1 9.21667 23 11.5C22.1 13.7833 20.65 15.6042 18.65 16.9625C16.65 18.3208 14.4333 19 12 19ZM12 17C13.8833 17 15.6125 16.5042 17.1875 15.5125C18.7625 14.5208 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7625 8.47917 17.1875 7.4875C15.6125 6.49583 13.8833 6 12 6C10.1167 6 8.3875 6.49583 6.8125 7.4875C5.2375 8.47917 4.03333 9.81667 3.2 11.5C4.03333 13.1833 5.2375 14.5208 6.8125 15.5125C8.3875 16.5042 10.1167 17 12 17Z" fill="#137CD9" />
                        //         </g>
                        //       </svg>
                        //     </span>
                            
                        //     <i className="fa fa-download" style={{color:'#137CD9', cursor:'pointer'}}  aria-hidden="true" onClick={() => { handleDownload(item?.invoice_pdf) }}></i>
                        //   </div>

                        // </td>
//                         {/* <td className='py-1'><Button onClick={()=>{handleViewBillButton(item.status)}} style={{border:'0px',borderRadius: "4px",background: "#BDD3E5",width:'100%'}}>{item.status}</Button> </td> */}
//                       </tr>
//                     ))}
//                   </tbody>

//               }
//             </table>
//           </div>
//         </Col>
//       </Row>
//     </Container> 
//     <Modal centered backdrop="true" keyboard={false} size="lg" show={show}>
//             <Modal.Body>
//               <div className="form p-3" >
//                 {viewData && viewData?.map((data, i) => (
//                   <div className="row" key={i}>
//                     <div className="col-12 col-sm-6">
//                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                         <Form.Label className="fw-bolder">Status</Form.Label>
//                         <Form.Control type="text"
//                           value={data.status || ''}
//                           //   onChange={handleChanges} 
//                           name="networkName" placeholder="Network Name"
//                           disabled />
//                       </Form.Group>
//                     </div>
//                     <div className="col-12 col-sm-6">
//                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                         <Form.Label className="fw-bolder">Transaction Date</Form.Label>
//                         <Form.Control type="text"
//                           value={data?.invoice_date || ''}
//                           //   onChange={handleChanges} 
//                           name="networkName" placeholder="Network Name"
//                           disabled
//                            />
//                       </Form.Group>
//                     </div>
//                     <div className="col-12 col-sm-6">
//                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                         <Form.Label className="fw-bolder">Invoice Number</Form.Label>
//                         <Form.Control type="text"
//                           value={data?.invoice_number || ''}
//                           //   onChange={handleChanges} 
//                           name="networkName" placeholder="Network Name" 
//                           disabled
//                           />
//                       </Form.Group>
//                     </div>
//                     <div className="col-12 col-sm-6">
//                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                         <Form.Label className="fw-bolder">Membership </Form.Label>
//                         <Form.Control type="text"
//                           value={data?.membership
//                             || ''}
//                           //   onChange={handleChanges} 
//                           name="networkName" placeholder="Network Name"
//                           disabled
//                            />
//                       </Form.Group>
//                     </div>
//                     <div className="col-12 col-sm-6">
//                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                         <Form.Label className="fw-bolder"> Name On Invoice</Form.Label>
//                         <Form.Control type="text"
//                           value={data?.name_on_invoice || ''}
//                           //   onChange={handleChanges} 
//                           name="networkName" placeholder="Network Name"   disabled />
//                       </Form.Group>
//                     </div>
//                     <div className="col-12 col-sm-6">
//                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                         <Form.Label className="fw-bolder">Subscription Id</Form.Label>
//                         <Form.Control type="text"
//                           value={data?.subscription_id
//                             || ''}
//                           //   onChange={handleChanges} 
//                           name="networkName" placeholder="Network Name"   disabled />
//                       </Form.Group>
//                     </div>

//                     <div className="col-12 col-sm-6">
//                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                         <Form.Label className="fw-bolder">Amount</Form.Label>
//                         <Form.Control type="text"
//                           value={data?.subtotal || ''}
//                           //   onChange={handleChanges} 
//                           name="networkName" placeholder="Network Name"   disabled />
//                       </Form.Group>
//                     </div>

//                     <div className="col-12 col-sm-6">
//                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                         <Form.Label className="fw-bolder">Invoice Date</Form.Label>
//                         <Form.Control type="text"
//                           value={data?.invoice_date || ''}
//                           //   onChange={handleChanges} 
//                           name="networkName" placeholder="Network Name"   disabled />
//                       </Form.Group>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//               <div className="d-flex justify-content-center align-items-center">

//                 <button className="subcription_cancel ms-2" onClick={handleClose}>Close</button>

//               </div>
//             </Modal.Body>
//           </Modal>
//     </>
//   )
// }

// export default UserMemberShip



import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import axios from 'axios';
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

const UserMemberShip = () => {
  const navigate = useNavigate();
  const logindata = JSON.parse(window.localStorage.getItem("login"));
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [name, setName] = useState('');
  const [membershipType, setMembershipType] = useState('');
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [membershipTill, setMembershipTill] = useState('');
  const [show, setShow] = useState(false);
  const userDatas = useSelector((state) => state.user);

  const [noData, setNoData] = useState('');
  

  const token = logindata?.token || null;

  useEffect(() => {
    document.title = "PCMA || Membership Information";

    if (userDatas) {
      setUserId(userDatas._id);
      setName(userDatas.name);

      if (Array.isArray(userDatas?.membership_type)) {
        setMembershipType(userDatas.membership_type[0]?.subscription_name || '');
      } else {
        setMembershipType(userDatas?.membership_type || '');
      }
    }

    apiData();
  }, [userDatas?._id]);

  const apiData = async () => {
    setIsLoading(true);
    try {
      if (userDatas?._id) {
        const response = await axios(`${process.env.REACT_APP_PCMA_BACKEND}/api/customer-subscriptions/${userDatas?._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response?.data || []);
        setMembershipTill(response?.data[response?.data?.length - 1]?.period_end || '');
        setNoData('')
      }
    } catch (error) {
      if (error?.response?.data?.message === "Unauthorized user") {
        localStorage.removeItem('login');
        navigate("/");
        toast.error('Unauthorized user!');
      }
      else if(error?.response?.data?.message == 'Transaction History Not Found!'){
        toast.error(error?.response?.data?.message)
      }
      setData([])
      setNoData(error?.response?.data?.message)
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = (data) => {
    setShow(true);
    setViewData([data]);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDownload = (url) => {
    window.open(url);
  };

  return (
    <>
      <Container fluid className="pt-4">
        <div className="j_account_information" style={{ background: "#137CD9" }}>
          <span className="px-3 d-flex align-items-center" style={{ color: "white", fontSize: '20px' }}>
            Membership Information
          </span>
        </div>

        <Row className="g-2" style={{ paddingBottom: "10px" }}>
          <Col sm={12} md={12} lg={12} className="py-3 d-flex justify-content-start align-items-start flex-column">
            <h4 className="network_h4 fs-5">{name}</h4>
            <h5>{membershipType}</h5>
            <p>Your membership is current through {membershipTill}</p>
            <p>
              <span style={{ color: 'red' }}>Please note:</span> It appears you have a transaction that is still open (see below.) Your current membership and expiration may change once this transaction has been processed and closed by administration.
            </p>
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className="table-responsive" style={{ borderRadius: '6px' }}>
              <table className="userinvoice_table" style={{ width: '100%' }}>
                <thead style={{ background: 'blue' }}>
                  <tr className="table-heading-row">
                    <th>Status</th>
                    <th>Transaction Date</th>
                    <th>Invoice Date</th>
                    <th>Membership</th>
                    <th className="py-2">Annual Membership Fee</th>
                    <th>Total FeeRegistered Firm Information</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                {isLoading ? (
  <tbody>
    <tr>
      <td colSpan={7}>
        <div className="d-flex align-items-center justify-content-center">
          <BallTriangle height={100} width={100} color="#0f66b4" visible={true} />
        </div>
      </td>
    </tr>
  </tbody>
) : noData?.length>0 ? (
  <tbody>
    <tr>
      <td colSpan={7}>
        <div className="d-flex align-items-center justify-content-center">
          {noData}
        </div>
      </td>
    </tr>
  </tbody>
) : (
                  <tbody>
                    {data?.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.status === 'active' ? "Closed" : "Open"}</td>
                        <td>{item?.transaction_date}</td>
                        <td>{item?.invoice_date}</td>
                        <td>{item?.membership}</td>
                        <td>$ {item?.subtotal?.toFixed(2)}</td>
                        <td>$ {item?.total?.toFixed(2)}</td>
                        <td className='py-1'>
                          <div className=' d-flex align-items-center justify-content-center' style={{ gap: '10px' }}>
                            <span style={{ cursor: 'pointer' }} onClick={() => { handleView(item) }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_3492_1111" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                  <rect width="24" height="24" fill="#137CD9" />
                                </mask>
                                <g mask="url(#mask0_3492_1111)">
                                  <path d="M12 16C13.25 16 14.3125 15.5625 15.1875 14.6875C16.0625 13.8125 16.5 12.75 16.5 11.5C16.5 10.25 16.0625 9.1875 15.1875 8.3125C14.3125 7.4375 13.25 7 12 7C10.75 7 9.6875 7.4375 8.8125 8.3125C7.9375 9.1875 7.5 10.25 7.5 11.5C7.5 12.75 7.9375 13.8125 8.8125 14.6875C9.6875 15.5625 10.75 16 12 16ZM12 14.2C11.25 14.2 10.6125 13.9375 10.0875 13.4125C9.5625 12.8875 9.3 12.25 9.3 11.5C9.3 10.75 9.5625 10.1125 10.0875 9.5875C10.6125 9.0625 11.25 8.8 12 8.8C12.75 8.8 13.3875 9.0625 13.9125 9.5875C14.4375 10.1125 14.7 10.75 14.7 11.5C14.7 12.25 14.4375 12.8875 13.9125 13.4125C13.3875 13.9375 12.75 14.2 12 14.2ZM12 19C9.56667 19 7.35 18.3208 5.35 16.9625C3.35 15.6042 1.9 13.7833 1 11.5C1.9 9.21667 3.35 7.39583 5.35 6.0375C7.35 4.67917 9.56667 4 12 4C14.4333 4 16.65 4.67917 18.65 6.0375C20.65 7.39583 22.1 9.21667 23 11.5C22.1 13.7833 20.65 15.6042 18.65 16.9625C16.65 18.3208 14.4333 19 12 19ZM12 17C13.8833 17 15.6125 16.5042 17.1875 15.5125C18.7625 14.5208 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7625 8.47917 17.1875 7.4875C15.6125 6.49583 13.8833 6 12 6C10.1167 6 8.3875 6.49583 6.8125 7.4875C5.2375 8.47917 4.03333 9.81667 3.2 11.5C4.03333 13.1833 5.2375 14.5208 6.8125 15.5125C8.3875 16.5042 10.1167 17 12 17Z" fill="#137CD9" />
                                </g>
                              </svg>
                            </span>
                            
                            <i className="fa fa-download" style={{color:'#137CD9', cursor:'pointer'}}  aria-hidden="true" onClick={() => { handleDownload(item?.invoice_pdf) }}></i>
                          </div>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {viewData.map((item) => (
            <Form key={item?.id}>
              <Form.Group controlId="transactionDate">
                <Form.Label>Transaction Date</Form.Label>
                <Form.Control type="text" value={item?.transaction_date} readOnly />
              </Form.Group>
              <Form.Group controlId="invoiceDate">
                <Form.Label>Invoice Date</Form.Label>
                <Form.Control type="text" value={item?.invoice_date} readOnly />
              </Form.Group>
              <Form.Group controlId="membership">
                <Form.Label>Membership</Form.Label>
                <Form.Control type="text" value={item?.membership} readOnly />
              </Form.Group>
              <Form.Group controlId="annualMembershipFee">
                <Form.Label>Annual Membership Fee</Form.Label>
                <Form.Control type="text" value={`$ ${item?.subtotal}`} readOnly />
              </Form.Group>
              <Form.Group controlId="discountedMembershipFee">
                <Form.Label>Discounted Membership Fee</Form.Label>
                <Form.Control type="text" value={`$ ${item?.total}`} readOnly />
              </Form.Group>
            </Form>
          ))}
        </Modal.Body>
      </Modal> */}


<Modal centered backdrop="true" keyboard={false} size="lg" show={show}>
             <Modal.Body>
               <div className="form p-3" >
                 {viewData && viewData?.map((data, i) => (
                   <div className="row" key={i}>
                     <div className="col-12 col-sm-6">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-bolder">Status</Form.Label>
                        <Form.Control type="text"
                          value={data.status || ''}
                           //   onChange={handleChanges} 
                           name="networkName" placeholder="Network Name"
                           disabled />
                       </Form.Group>
                     </div>
                     <div className="col-12 col-sm-6">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-bolder">Transaction Date</Form.Label>
                         <Form.Control type="text"
                           value={data?.invoice_date || ''}
                           //   onChange={handleChanges} 
                           name="networkName" placeholder="Network Name"
                           disabled
                            />
                      </Form.Group>
                     </div>
                     <div className="col-12 col-sm-6">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-bolder">Invoice Number</Form.Label>
                         <Form.Control type="text"
                           value={data?.invoice_number || ''}
                           //   onChange={handleChanges} 
                           name="networkName" placeholder="Network Name" 
                           disabled
                           />
                      </Form.Group>
                     </div>
                    <div className="col-12 col-sm-6">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-bolder">Membership </Form.Label>
                        <Form.Control type="text"
                           value={data?.membership
                             || ''}
                           //   onChange={handleChanges} 
                           name="networkName" placeholder="Network Name"
                           disabled
                            />
                      </Form.Group>
                     </div>
                     <div className="col-12 col-sm-6">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-bolder"> Name On Invoice</Form.Label>
                         <Form.Control type="text"
                           value={data?.name_on_invoice || ''}
                           //   onChange={handleChanges} 
                           name="networkName" placeholder="Network Name"   disabled />
                       </Form.Group>
                     </div>
                     <div className="col-12 col-sm-6">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-bolder">Subscription Id</Form.Label>
                         <Form.Control type="text"
                           value={data?.subscription_id
                             || ''}
                           //   onChange={handleChanges} 
                           name="networkName" placeholder="Network Name"   disabled />
                       </Form.Group>
                     </div>

                     <div className="col-12 col-sm-6">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-bolder">Amount</Form.Label>
                         <Form.Control type="text"
                           value={data?.subtotal?.toFixed(2) || ''}
                          //   onChange={handleChanges} 
                           name="networkName" placeholder="Network Name"   disabled />
                       </Form.Group>
                     </div>

                     <div className="col-12 col-sm-6">
                       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                         <Form.Label className="fw-bolder">Invoice Date</Form.Label>
                         <Form.Control type="text"
                           value={data?.invoice_date || ''}
                           //   onChange={handleChanges} 
                           name="networkName" placeholder="Network Name"   disabled />
                       </Form.Group>
                     </div>
                   </div>
                 ))}
               </div>
               <div className="d-flex justify-content-center align-items-center">

                 <button className="subcription_cancel ms-2" onClick={handleClose}>Close</button>

               </div>
             </Modal.Body>
           </Modal>
    </>
  );
};

export default UserMemberShip;
