import React, { useEffect } from 'react'
import './job.style.css';
import Navbar from "../../components/navbar/NavbarGlobal";
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CheckIcon from './CheckIcon';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../../components/footer/Footer';
const JobPosting = () => {
    //Removing the active class from header
    useEffect(() => {
        const dropdownToggle = document.querySelector('.navbar_nav .dropdown-toggle.show');
        if (dropdownToggle) {
            dropdownToggle.classList.remove('show');
        }
    }, []);
  return (
    <>
    <div className='jobPosting'>
        <Navbar />
        <Container fluid>
        <div className="slider_img_div_box" style={{maxWidth:'1690px', padding:'0px 20px'}}>
            <Container fluid className="pb-4" style={{ marginTop: "120px" }}>
                <div className="row ">
                    <div className="col-12">
                        <div className="approve_about_banner  py-5 d-flex align-items-center">
                                <div className="over_layout">
                                    <div className="content">

                                        <div className="container-fluid p-0">
                                            <div className="row g-3 g-lg-2 m-0">
                                                {/* <div className="col-12  p-0 mb-0 mb-md-4 mb-lg-0">
                                                    <div className=" d-flex align-items-center justify-content-end">
                                                    <ul className="breadcrumb mb-0 py-3 px-4">
                                                        <li className="breadcrumb-item active px-2"><Link to="https://pcma1dev.wpenginepowered.com/" className="px-3">Home</Link></li>
                                                        <li className="breadcrumb-item active px-2" aria-current="page"><Link to="https://pcma1dev.wpenginepowered.com/membership-benefits/" className="px-3">Membership</Link></li>
                                                        <li className="breadcrumb-item active px-2" aria-current="page">Our Members</li>
                                                    </ul></div>
                                                </div> */}
                                                <div className="col-lg-9 col-xl-7  col-xxl-6">
                                                    <div className="px-2 px-sm-5 text-center text-sm-start ">
                                                
                                                    <h2>Recruit the Best</h2>
                                                
                                                    <p>
                                                        Find the perfect position in the private capital markets.
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <Row className='justify-content-center'>
                    <Col lg="11" xl="10">
                        <div className="postBoard py-5">
                            <Row className='g-3 g-lg-4 g-xxl-5'>
                                <Col lg="6">
                                    <h5>Job Postings Board</h5>
                                    <p>The Job Postings Board of the Private Capital Markets Association of Canada (PCMA) is the place to find dealing representatives, chief compliance officers, chief financial officers, ultimate designated persons, portfolio analysts, administrative assistants, jobs, controllers, IT support staff, and other talent.</p>
                                    <h5 className='text-dark'>Job Seekers</h5>
                                    <p>Review the company profile directory to review potential employers. Look for Member Spotlights about members from a particular employer.</p>
                                    <p>PCMA members: Access new jobs posted four days before they are open to the public. Please ensure you are signed in to see all current job postings.</p>
                                    <p className="lead">Check back often—the PCMA receives new listings regularly!*</p>
                                </Col>
                                <Col lg="6">
                                    <Image src="./asset/postBoard.jpg" fluid/>
                                </Col>
                            </Row>
                        </div>
                        <div className="jobTypeSelect py-5">
                            <Row className='g-3 g-lg-4'>
                                <Col lg="4">
                                    <div className="jobTypeLeft p-4 p-lg-5">
                                        <h4 className='mb-4'>Spotlighted Jobs</h4>
                                        <ul>
                                            <li>
                                                <span className='jtlNum'>1</span>
                                                <span>Lorem Ipsum is simply dummy text.</span> 
                                            </li>
                                            <li>
                                                <span className='jtlNum'>2</span>
                                                <span>Lorem Ipsum is simply dummy text.</span> 
                                            </li>
                                            <li>
                                                <span className='jtlNum'>3</span>
                                                <span>Lorem Ipsum is simply dummy text.</span> 
                                            </li>
                                            <li>
                                                <span className='jtlNum'>4</span>
                                                <span>Lorem Ipsum is simply dummy text.</span> 
                                            </li>
                                            <li>
                                                <span className='jtlNum'>5</span>
                                                <span>Lorem Ipsum is simply dummy text.</span> 
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg="8">
                                    <div className="jobTypeRight p-4 p-lg-5">
                                        <h4 className='text-blue mb-4'>Featured jobs</h4>
                                        <Row>
                                            <Col md="6">
                                                <ul>
                                                    <li>
                                                        <span className='jtlNum'>1</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                    <li>
                                                        <span className='jtlNum'>2</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                    <li>
                                                        <span className='jtlNum'>3</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                    <li>
                                                        <span className='jtlNum'>4</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                    <li>
                                                        <span className='jtlNum'>5</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col md="6">
                                                <ul>
                                                    <li>
                                                        <span className='jtlNum'>6</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                    <li>
                                                        <span className='jtlNum'>7</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                    <li>
                                                        <span className='jtlNum'>8</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                    <li>
                                                        <span className='jtlNum'>9</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                    <li>
                                                        <span className='jtlNum'>10</span>
                                                        <span>Lorem Ipsum is simply dummy text.</span> 
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="jobOpening py-5">
                            <h5>All Job Openings</h5>
                            <Row className='g-3 g-lg-4 justify-content-center'>
                                <Col lg="6" xl="5">
                                    <div className="card p-3 p-lg-4">
                                        <div className="card-body">
                                            <div className="jobData">
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Position</p></Col>
                                                    <Col sm="7"> <Link to="/">[Click Display Job Posting]</Link> </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Position</p></Col>
                                                    <Col sm="7"> <Link to="/">[Click Display Job Posting]</Link> </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Position</p></Col>
                                                    <Col sm="7"> <p><strong>Canada</strong></p> </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Salary</p></Col>
                                                    <Col sm="7"> <p><strong>500k-900k</strong></p> </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Job Type</p></Col>
                                                    <Col sm="7"> <p><strong>Full-Time</strong></p> </Col>
                                                </Row>
                                            </div>
                                            <div className='jobRes'>
                                                <p>Responsibilities:</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6" xl="5">
                                    <div className="card p-3 p-lg-4">
                                        <div className="card-body">
                                            <div className="jobData">
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Position</p></Col>
                                                    <Col sm="7"> <Link to="/">[Click Display Job Posting]</Link> </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Position</p></Col>
                                                    <Col sm="7"> <Link to="/">[Click Display Job Posting]</Link> </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Position</p></Col>
                                                    <Col sm="7"> <p><strong>Canada</strong></p> </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Salary</p></Col>
                                                    <Col sm="7"> <p><strong>500k-900k</strong></p> </Col>
                                                </Row>
                                                <Row className='g-3'>
                                                    <Col sm="5"><p>Job Type</p></Col>
                                                    <Col sm="7"> <p><strong>Full-Time</strong></p> </Col>
                                                </Row>
                                            </div>
                                            <div className='jobRes'>
                                                <p>Responsibilities:</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="text-center mt-4">
                                <a href="#" role="button" className='btn btn-outline-primary mt-2'>Load More</a>
                            </div>
                        </div>
                        <div className="how_post pb-5">
                            <h5 className='jobHeaderTitle mb-4'>How to post on the Job Board</h5>
                            <Tabs
                            defaultActiveKey="non-members"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            >
                                <Tab eventKey="members" title="PCMA Members">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt harum aut repellendus, saepe, quod nulla, eius in perferendis quo et debitis accusamus. Atque illum quidem voluptatem cumque eaque, soluta sed!</p>
                                </Tab>
                                <Tab eventKey="non-members" title="Non-Members">
                                    <div className="non_member py-2">
                                        <h6>Non-Members</h6>
                                        <p>The  Job Posting Board of the PCMA is your premier destination for connecting with top-tier talent in the private capital markets. Our mission is to facilitate seamless connections between employers and skilled professionals actively seeking opportunities. Explore the range of digital recruitment packages of the PCMA to find your ideal candidate today!</p>
                                        <div className="nmRow mt-5">
                                            <div className="d-none d-lg-block">
                                                <Row className='g-0 mb-4 mb-lg-0'>
                                                    <Col lg="4">
                                                        <div className="bg-white bgBlock">

                                                        </div>
                                                    </Col>
                                                    <Col lg="8">
                                                        <Row className='g-0'>
                                                            <Col lg="4">
                                                                <div className="bg-yellow bgBlock">
                                                                    <p>Premium</p>
                                                                    <h5>$800</h5>
                                                                    <button>Add To Cart</button>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4">
                                                                <div className="bg-white bgBlock">
                                                                    <p>Advanced</p>
                                                                    <h5>$400</h5>
                                                                    <button>Add To Cart</button>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4">
                                                                <div className="bg-yellow bgBlock">
                                                                    <p>Starter</p>
                                                                    <h5>$200</h5>
                                                                    <button>Add To Cart</button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row className='g-0 mb-4 mb-lg-0'>
                                                <Col lg="4">
                                                    <div className="bg-yellow bgBlock bgBlockFirst">
                                                        <h6 className=''>Online Job Posting – 30 days</h6>
                                                    </div>
                                                </Col>
                                                <Col lg="8">
                                                    <Row className='g-0'>
                                                        <Col xs="9" className='d-lg-none'>
                                                            <Row className='g-0'>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Premium</p>
                                                                        <h5>$800</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-white bgBlock">
                                                                        <p>Advanced</p>
                                                                        <h5>$400</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Starter</p>
                                                                        <h5>$200</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs="3" lg="12">
                                                            <Row className='g-0'>
                                                                <Col lg="4">
                                                                    <div className="bg-white bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-white bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className='g-0 mb-4 mb-lg-0'>
                                                <Col lg="4">
                                                    <div className="bg-white bgBlock bgBlockFirst">
                                                        <h6>Featured Organization</h6>
                                                        <p>Stand out with your organization's logo on the Job Postings Board homepage.</p>
                                                    </div>
                                                </Col>
                                                <Col lg="8">
                                                    <Row className='g-0'>
                                                        <Col xs="9" className='d-lg-none'>
                                                            <Row className='g-0'>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Premium</p>
                                                                        <h5>$800</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-white bgBlock">
                                                                        <p>Advanced</p>
                                                                        <h5>$400</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Starter</p>
                                                                        <h5>$200</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs="3" lg="12">
                                                            <Row className='g-0'>
                                                                <Col lg="4">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-white bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-yellow bgBlock">
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className='g-0 mb-4 mb-lg-0'>
                                                <Col lg="4">
                                                    <div className="bg-yellow bgBlock bgBlockFirst">
                                                        <h6>Spotlight Job</h6>
                                                        <p>Your highlighted job is shown at the top of search results and appears on the Job Postings Board homepage </p>
                                                    </div>
                                                </Col>
                                                <Col lg="8">
                                                    <Row className='g-0'>
                                                        <Col xs="9" className='d-lg-none'>
                                                            <Row className='g-0'>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Premium</p>
                                                                        <h5>$800</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-white bgBlock">
                                                                        <p>Advanced</p>
                                                                        <h5>$400</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Starter</p>
                                                                        <h5>$200</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs="3" lg="12">
                                                            <Row className='g-0'>
                                                                <Col lg="4">
                                                                    <div className="bg-white bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-white bgBlock">
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className='g-0 mb-4 mb-lg-0'>
                                                <Col lg="4">
                                                    <div className="bg-white bgBlock bgBlockFirst">
                                                        <h6>Branded Employer Profile</h6>
                                                        <p>Upload a 960 x 200px profile header banner to customize your profile and make it stand out</p>
                                                    </div>
                                                </Col>
                                                <Col lg="8">
                                                    <Row className='g-0'>
                                                        <Col xs="9" className='d-lg-none'>
                                                            <Row className='g-0'>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Premium</p>
                                                                        <h5>$800</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-white bgBlock">
                                                                        <p>Advanced</p>
                                                                        <h5>$400</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Starter</p>
                                                                        <h5>$200</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs="3" lg="12">
                                                            <Row className='g-0'>
                                                                <Col lg="4">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-white bgBlock">
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-yellow bgBlock">
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className='g-0 mb-4 mb-lg-0'>
                                                <Col lg="4">
                                                    <div className="bg-yellow bgBlock bgBlockFirst">
                                                        <h6>Newsletter Advertisement</h6>
                                                        <p>A summary of your job posting and link to the job posting online in at least two PCMA newsletters.</p>
                                                    </div>
                                                </Col>
                                                <Col lg="8">
                                                    <Row className='g-0'>
                                                        <Col xs="9" className='d-lg-none'>
                                                            <Row className='g-0'>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Premium</p>
                                                                        <h5>$800</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-white bgBlock">
                                                                        <p>Advanced</p>
                                                                        <h5>$400</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="12">
                                                                    <div className="bg-yellow bgBlock">
                                                                        <p>Starter</p>
                                                                        <h5>$200</h5>
                                                                        <button>Add To Cart</button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs="3" lg="12">
                                                            <Row className='g-0'>
                                                                <Col lg="4">
                                                                    <div className="bg-white bgBlock">
                                                                        <h5><CheckIcon/></h5>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-yellow bgBlock">
                                                                    </div>
                                                                </Col>
                                                                <Col lg="4">
                                                                    <div className="bg-white bgBlock">
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <p className='py-4 price_taxes'>Prices do not include applicable taxes.</p>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                            <div className='howContact'>
                                <h6 className='mb-4'>If your organization is interested in <span>purchasing 3 or more</span> job postings or <span>wish to purchase a banner ad</span> for your job posting,</h6>
                                <Row className='g-3'>
                                    <Col md="6" lg="4">
                                        <div className="emailBox p-3 p-lg-4 p-xl-5">
                                            <p>PLEASE EMAIL AT</p>
                                            <a href="mailto:info@pcmacanada.com" title="info@pcmacanada.com" className='text-truncate d-block'>info@pcmacanada.com</a>
                                        </div>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <div className="callBox p-3 p-lg-4 p-xl-5">
                                            <p>CALL AT</p>
                                            <a href="tel:1.877.363.3632." title='1.877.363.3632' className='text-truncate d-block'>1.877.363.3632</a>
                                        </div>
                                    </Col>
                                    <Col md="12" lg="5">
                                        <p>All positions will remain on the job posting board for 30 days, at which point they automatically expire and are unpublished from the board. If your position has not yet been filled, you can go to your user page, make an additional payment, and republish the position. If you are a member or purchased a subscription longer than 30 days, your job posting will still expire after 30 days and you must go to your user page and republish the position.</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="payTrans py-5">
                            <h5 className='jobHeaderTitle mb-4'>Pay Transparency</h5>
                            <p>To comply with new provincial legislation adopted in certain provinces, PCMA now requires all employers that wish to post available positions on the PCMA Job Posts webpage to disclose the salary ranges for those positions. Prince Edward Island and British Columbia have enacted “pay transparency” laws that mandate their employers primarily include salary ranges in job postings or otherwise provide that data to applicants. These laws also apply to candidates who are outside of that province’s borders but are seeking positions at employers within them. Since most job postings seek employees nationwide, PCMA has chosen to apply a consistent approach and require disclosure of pay information generally. Please note that the laws provide primarily for a broad salary range for each position. Pay transparency is one of the ways that employers can build a team that is based on trust and accountability.</p>
                        </div>
                        <div className="notices pt-4 pb-5">
                            <h5 className='jobHeaderTitle mb-2 text-start'>Notices</h5>
                            <div className="pt-3">
                                <Accordion defaultActiveKey="1" flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>General</Accordion.Header>
                                        <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Abuse Policy</Accordion.Header>
                                        <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div className="beTheFirst text-center pb-5">
                            <Row className='justify-content-center'>
                                <Col lg="10" xl="8" xxl="6">
                                    <h5 className='jobHeaderTitle mb-3'>Be the First to Know</h5>
                                    <p className='mb-5'>Follow the PCMA on social media to hear about new job opportunities, keep up to date with public capital markets news and trends, and increase your chances of landing the job you really want in the private capital markets.</p>
                                    <h6>Follow Us</h6>
                                    <div className="follow_us">
                                        <a href="#" target='_blank'>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={30}
                                                height={30}
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                >
                                                <path
                                                    d="M11.495 26.875H16.495V16.8625H21L21.495 11.8875H16.495V9.375C16.495 9.04348 16.6267 8.72554 16.8611 8.49112C17.0955 8.2567 17.4135 8.125 17.745 8.125H21.495V3.125H17.745C16.0874 3.125 14.4977 3.78348 13.3256 4.95558C12.1535 6.12769 11.495 7.7174 11.495 9.375V11.8875H8.995L8.5 16.8625H11.495V26.875Z"
                                                    fill="#5E5E5E"
                                                />
                                                </svg>
                                        </a>
                                        <a href="#" target='_blank'>
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={27}
                                            height={27}
                                            viewBox="0 0 27 27"
                                            fill="none"
                                            >
                                            <g clipPath="url(#clip0_7474_791)">
                                                <mask
                                                id="mask0_7474_791"
                                                style={{ maskType: "luminance" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={27}
                                                height={27}
                                                >
                                                <path d="M0.25 0.25H26.5V26.5H0.25V0.25Z" fill="white" />
                                                </mask>
                                                <g mask="url(#mask0_7474_791)">
                                                <path
                                                    d="M20.9219 1.47998H24.9475L16.1538 11.5562L26.5 25.27H18.4L12.0513 16.9544L4.795 25.27H0.765625L10.1706 14.4887L0.25 1.48186H8.55625L14.2863 9.08123L20.9219 1.47998ZM19.5063 22.855H21.7375L7.3375 3.76936H4.945L19.5063 22.855Z"
                                                    fill="#5E5E5E"
                                                />
                                                </g>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_7474_791">
                                                <rect
                                                    width="26.25"
                                                    height="26.25"
                                                    fill="white"
                                                    transform="translate(0.25 0.25)"
                                                />
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        </a>
                                        <a href="#" target='_blank'>
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={31}
                                            height={30}
                                            viewBox="0 0 31 30"
                                            fill="none"
                                            >
                                            <path
                                                d="M6.6875 2.34375C5.98302 2.34375 5.30739 2.6236 4.80925 3.12175C4.3111 3.61989 4.03125 4.29552 4.03125 5C4.03125 5.70448 4.3111 6.38011 4.80925 6.87825C5.30739 7.3764 5.98302 7.65625 6.6875 7.65625C7.39198 7.65625 8.06761 7.3764 8.56575 6.87825C9.0639 6.38011 9.34375 5.70448 9.34375 5C9.34375 4.29552 9.0639 3.61989 8.56575 3.12175C8.06761 2.6236 7.39198 2.34375 6.6875 2.34375ZM4.1875 9.84375C4.14606 9.84375 4.10632 9.86021 4.07701 9.88951C4.04771 9.91882 4.03125 9.95856 4.03125 10V26.25C4.03125 26.3363 4.10125 26.4062 4.1875 26.4062H9.1875C9.22894 26.4062 9.26868 26.3898 9.29799 26.3605C9.32729 26.3312 9.34375 26.2914 9.34375 26.25V10C9.34375 9.95856 9.32729 9.91882 9.29799 9.88951C9.26868 9.86021 9.22894 9.84375 9.1875 9.84375H4.1875ZM12.3125 9.84375C12.2711 9.84375 12.2313 9.86021 12.202 9.88951C12.1727 9.91882 12.1562 9.95856 12.1562 10V26.25C12.1562 26.3363 12.2262 26.4062 12.3125 26.4062H17.3125C17.3539 26.4062 17.3937 26.3898 17.423 26.3605C17.4523 26.3312 17.4688 26.2914 17.4688 26.25V17.5C17.4688 16.8784 17.7157 16.2823 18.1552 15.8427C18.5948 15.4032 19.1909 15.1562 19.8125 15.1562C20.4341 15.1562 21.0302 15.4032 21.4698 15.8427C21.9093 16.2823 22.1562 16.8784 22.1562 17.5V26.25C22.1562 26.3363 22.2262 26.4062 22.3125 26.4062H27.3125C27.3539 26.4062 27.3937 26.3898 27.423 26.3605C27.4523 26.3312 27.4688 26.2914 27.4688 26.25V15.475C27.4688 12.4412 24.8313 10.0688 21.8125 10.3425C20.8785 10.4275 19.9636 10.6587 19.1012 11.0275L17.4688 11.7275V10C17.4688 9.95856 17.4523 9.91882 17.423 9.88951C17.3937 9.86021 17.3539 9.84375 17.3125 9.84375H12.3125Z"
                                                fill="#5E5E5E"
                                            />
                                            </svg>
                                        </a>
                                        <a href="#" target='_blank'>
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={34}
                                            height={34}
                                            viewBox="0 0 34 34"
                                            fill="none"
                                            >
                                            <path
                                                d="M14.1654 21.2502L21.5179 17.0002L14.1654 12.7502V21.2502ZM30.542 10.1577C30.7262 10.8235 30.8537 11.716 30.9387 12.8493C31.0379 13.9827 31.0804 14.9602 31.0804 15.8102L31.1654 17.0002C31.1654 20.1027 30.9387 22.3835 30.542 23.8427C30.1879 25.1177 29.3662 25.9393 28.0912 26.2935C27.4254 26.4777 26.207 26.6052 24.337 26.6902C22.4954 26.7893 20.8095 26.8318 19.2512 26.8318L16.9987 26.9168C11.0629 26.9168 7.36537 26.6902 5.9062 26.2935C4.6312 25.9393 3.80953 25.1177 3.45536 23.8427C3.2712 23.1768 3.1437 22.2843 3.0587 21.151C2.95953 20.0177 2.91703 19.0402 2.91703 18.1902L2.83203 17.0002C2.83203 13.8977 3.0587 11.6168 3.45536 10.1577C3.80953 8.88266 4.6312 8.061 5.9062 7.70683C6.57203 7.52266 7.79037 7.39516 9.66037 7.31016C11.502 7.211 13.1879 7.1685 14.7462 7.1685L16.9987 7.0835C22.9345 7.0835 26.632 7.31016 28.0912 7.70683C29.3662 8.061 30.1879 8.88266 30.542 10.1577Z"
                                                fill="#5E5E5E"
                                            />
                                            </svg>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Container>
        <Footer/>
    </div>
    </>
  )
}

export default JobPosting