import React, { useEffect } from "react";
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Error = () => {
  
   useEffect(() => {
     document.title = "PCMA || Page Not Found";
   }, [])

   const pathname = window.location.pathname;
  const pathSegments = pathname?.split('/');

    //   const handleBack = () => {
    //     if (window.history.length === 1) {
    //         navigate('/login');
    //     } else {
    //         navigate(-1);
    //     }
    // };


  return (
    <>
      <Container fluid className='error_page'>
        <div className='error_box'>
          <div className='back_icon d-flex align-items-center  justify-content-center justify-content-sm-start'>
            {pathSegments[1].includes("admin") ? 
               <Link to="/admin"><i className="fa-solid fa-arrow-left"></i>Back to home</Link>
              :
              <Link to="/"><i className="fa-solid fa-arrow-left "></i>Back to home</Link>
            }
            </div>
          <div className='d-flex align-items-center justify-content-center'>
            <div>
              <img src='/asset/404 png.png' className='img-fluid'/>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Error