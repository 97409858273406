import React, { useEffect, useRef,useState} from 'react'
import FirmShow from '../../components/globalDashboard/FirmShow'
import RegistrantShow from '../../components/globalDashboard/RegistrantShow'
import MemberShow from '../../components/globalDashboard/MemberShow'
// import NonFirm from '../../components/globalDashboard/NonFirm';
// import NonRegistarnt from '../../components/globalDashboard/NonRegistarnt';
import Dropdown from "react-bootstrap/Dropdown";
import Footer from '../../components/footer/Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Col, Row, Container, Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import toast from 'react-hot-toast';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { clearAddress } from '../../redux/slice/registrantNearmeLocation';
import { useDispatch } from 'react-redux';
import useDisableTabKey from '../../components/disableTabKey/disableTabKey';

const GlobalDashboard = ({searchValue,setSearchValue,setCurrentPage, setRegistrantPage}) => {

  useDisableTabKey();

  let localUserId;
  let userName;
  let token;
  const userData = JSON.parse(window.localStorage.getItem("login"));
  if (userData) {token = userData.token;} 
  if (userData?.user_data?._id) { localUserId = userData?.user_data?._id; }
  if (userData?.user_data?.name) { userName = userData?.user_data?.name; }

  setCurrentPage(1);
  setRegistrantPage(1);

  const dispatch = useDispatch();

  dispatch(clearAddress());
  
  
  const [noticeDatas, setNoticeData] = useState([])
  // const [searchValue, setSearchValue] = useState('');
  const [firms,setFirms] = useState();
  const [firmsData,setfirmsData] = useState();
  const [registrantsData,setRegistrantsData] = useState();
  const [members,setMembers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState();
  const navigate = useNavigate();
  const [mainBookmarkId, setMainBookmarkId] = useState([]);
  const [bookmarkLength, setbookmarkLength] = useState();
  const [userAddress, setUserAddress] = useState('');
  const [bookmarkData, setbookmarkData] = useState([]);
  const [registrantBookmarkData, setRegistrantBookmarkData] = useState([]);
  const [profileImage, setProfileImage] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');
  const [checkBookMark, setCheckBookMark] = useState(() => {
    const savedBookmarks = localStorage.getItem('bookmarks');
    return (savedBookmarks && savedBookmarks !== "undefined") ? JSON.parse(savedBookmarks) : [];

  });
  const capitalizeFirstLetter = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };
  const [showModal, setShowModal] = useState(false);
  const [notificationName, setNotificationName] = useState('');
  const [notificationDate, setNotificationDate] = useState('');
  const [notificationmessage, setNotificationmessage] = useState('');
  
  
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(true);

   useEffect(() => {
    document.title = "PCMA || Dashboard";
  }, []);

  const settings = {
    dots: false,
    infinite: (firmsData?.length > 4) ? true : false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: (firmsData?.length > 4) ? true : false,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1267,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          draggable: (firmsData?.length > 1) ? true : false,
          infinite: (firmsData?.length > 1  ) ? true : false,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          draggable: (firmsData?.length > 2) ? true : false,
          infinite: (firmsData?.length > 2  ) ? true : false,
         
        }
      },
      {
        breakpoint: 848,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          draggable: (firmsData?.length > 1) ? true : false,
          infinite: (firmsData?.length > 1  ) ? true : false,
        }
      },
      {
        breakpoint: 576,
        settings: {
          draggable: (firmsData?.length > 1) ? true : false,
          infinite: (firmsData?.length > 1  ) ? true : false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  
  const registranSettings = {
    dots: false,
    infinite: (registrantsData?.length >= 4) ? true : false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: (registrantsData?.length >= 4) ? true : false,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1267,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          draggable: (registrantsData?.length > 1) ? true : false,
          infinite: (registrantsData?.length > 1  ) ? true : false,
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          draggable: (registrantsData?.length > 2) ? true : false,
          infinite: (registrantsData?.length > 2  ) ? true : false,
         
        }
      },
      {
        breakpoint: 848,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          draggable: (registrantsData?.length > 1) ? true : false,
          infinite: (registrantsData?.length > 1  ) ? true : false,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: (registrantsData?.length > 1) ? true : false,
          infinite: (registrantsData?.length > 1  ) ? true : false,
        }
      }
    ]
  };
  useEffect(() => {
    if (searchValue) {
      setIsOpen(true);
    }
    const handleClickOutside = (event) => {
      if (dropdownRef?.current && !dropdownRef?.current?.contains(event?.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [searchValue]);


  const timerIdRef = useRef(null);

  const debounce = (value) => {
   
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
      // SearchingApi(value)
    }
    timerIdRef.current = setTimeout(() => {
     
    }, 500);
  };

  // const SearchingApi = (value)=>{
  //   setIsLoading(true);
  //   axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/search-suggestions?name=${value}`, {
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //     }
  //   })
  //   .then(response => {
  //     setSuggestions(response.data.suggestions);
  //     setIsLoading(false);
  //   })
  //   .catch(error => {
  //     console.error('Error:', error);
  //   });
  // }

  //  useEffect(()=>{
  //   if(searchValue.length>0){
  //     SearchingApi(searchValue)
  //   }
  //  },[])

  const SearchValue = (val) => {
    // e.preventDefault();
    setSearchValue(val);
    debounce(val);
  };

  const handleResetSearch = () => {
    setSearchValue('');
    
   };
 
 const getApi = ()=>{
  setIsLoading(true);
  axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/global-search?name=${searchValue}
  `,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

.then(response => {
setMembers(response.data.users);
setFirms(response.data.firms);
setfirmsData(response.data.firms.data);
setRegistrantsData(response.data.registrant);
setLastUpdate(response.data?.last_update_date) 
setIsLoading(false);
})
.catch(error => {
if(error?.response?.data?.message == "Unauthozied User") {
  navigate('/login')
}
});
 }
 

  useEffect(() => {
    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      getApi();
    }, 1500); 
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);


  const fetchData = async () => {
    window.scrollTo(0, 0);
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/logged-user-data`, {
          params: {
            id: localUserId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.data?.message === "Unauthozied User") {
          localStorage.removeItem("login");
          navigate("/");
          toast.error("Unauthozied user !");
        }
        const bookmarkId = response?.data?.bookmark_details?.map(firm => firm.NRD);
        const bookmarkRegistrant = response?.data?.registrant_bookmark?.map(firm => firm.registrant_id);
        const mainBookmarkId = response?.data?.bookmark_details;
        setbookmarkLength(response?.data?.bookmark_details?.length);
        setbookmarkData(bookmarkId);
        setRegistrantBookmarkData(bookmarkRegistrant);
        setUserAddress(response?.data?.address)
        setMainBookmarkId(mainBookmarkId);
        setProfileImage(response?.data?.profile_picture);
      } catch (error) { 
        console.error('Error fetching data:', error);
        if (error?.message === "Request failed with status code 401") {
          localStorage.removeItem('login');
          navigate("/");
          toast.error(`Unauthozied user !`);
        }
      }
    } else {
      toast.error('Please login again !')
    }

  };

  useEffect(() => {
    fetchData();
  }, [checkBookMark]);

  const fetchNoticData = async () => {
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/all-notice-data-user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.data?.message === "Unauthozied User") {
          localStorage.removeItem("login");
          navigate("/");
          toast.error("Unauthozied user !");
        }
        setNoticeData(response?.data?.notice_data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error?.message === "Request failed with status code 401") {
          localStorage.removeItem('login');
          navigate("/");
          toast.error(`Unauthozied user !`);
        }
      }
    }
  };

  const formatNoticeData = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return date.getDate() + (date.getDate() % 10 === 1 && date.getDate() !== 11 ? 'st' : date.getDate() % 10 === 2 && date.getDate() !== 12 ? 'nd' : date.getDate() % 10 === 3 && date.getDate() !== 13 ? 'rd' : 'th') + ' ' + date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
    }
  };

  const handleNoticeModal = (data) => {
    setShowModal(true);
    const noticeDate = formatNoticeData(data?.updated_at);
    setNotificationDate(noticeDate)
    setNotificationName(data?.name);
    setNotificationmessage(data?.message)
  }

  useEffect(() => {
    fetchNoticData()
  }, [])

  const logout = () => {

    toast.success('You have logged out successfully.');
    navigate("/login");
    localStorage.removeItem('login');
  }

  const offDropDown = ()=>{
    if (searchValue) {
      setTimeout(()=>{

        setIsOpen(false);
      }, 100)
    }
  }

  return (
    <>
   
    <div style={{height:'100vh'}}  className={` d-flex align-items-center justify-content-between flex-column ${firms?.message === "No data found" && members?.message === "No data found" && registrantsData?.message === "No data found" && 'global_nodat_height'}`}>
      <div className="slider_img_div_box" >
        <Container fluid className="">
          <Row className="g-2" style={{ paddingBottom: "10px" }}>
            <Col className="col-2  order-1  d-flex align-items-center justify-content-start py-3">
              <Link to="/">
                <img
                  src="./asset/logo.png"
                  className="header_img_logo"
                  alt=""
                />
              </Link>
            </Col>
            <Col className="col-12 col-md-6 col-xl-5  order-3 order-md-2 d-flex align-items-center justify-content-start">
              <div
                className="search_box d-flex align-items-center  justify-content-start position-relative"
                ref={dropdownRef}
                style={{
                  border: "2px solid rgba(159, 194, 225, 0.80)",
                  borderRadius: "6px",
                  width: "100%",
                  padding: '8px',
                  backgroundColor: "#FFFFFF",
                }}>
        
                <form className="d-flex w-100">
                  <input
                    type="search"
                    className="top-search"
                    style={{ width: "100%",fontSize:"16px" }}
                      placeholder="Search By Region,Registered Firms or Registered Individuals..."
                    value={searchValue}
                    // onChange={(e) => {
                    //   const value = e.target.value;
                     
                    //   if (value === '' || value.charAt(0) !== ' ') {
                    //     SearchValue(value); 
                    //   }
                    // }}
                    onChange={(e) => {
                      let inputValue = e.target.value;
                      const sanitizedValue = inputValue.replace(/^\s+/g, '').replace(/\s{2,}/g, ' ');
                      setSearchValue(sanitizedValue);
                    }}
                    onKeyPress={(e) => {
                      if(e.key === 'Enter'){
                        e.preventDefault(); 
                        if (searchValue?.length > 0) {
                          getApi();
                        }
                       
                      } 
                     }}
                  />
                    <button type="button" className="reset-search-button me-2" onClick={handleResetSearch}><i className="fa-solid fa-arrow-rotate-right me-2"></i></button> 
                  <button
                    type="button"
                    className="top-search-button"
                    style={{ cursor: 'auto' }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                </form>
                {/* {(searchValue && isOpen) && <ul className='g_list_search m-0  list-unstyled w-100'>
                  { 
                  suggestions?.length===0  ?  <p className='text-center mb-0'>No Data</p> : suggestions?.map((elem)=>(

                    <li><Link to='' onClick={()=>{setSearchValue(elem); debounce(elem); offDropDown()}}>{elem}</Link></li>
                  ))
                  }
                </ul>
                } */}


              </div>
            </Col>

            <Col className=" col-10  col-md-4 col-xl-5   order-2 order-md-3 d-flex align-items-center justify-content-end">
                <div className="icons-top-bar icons_top_bar d-flex align-items-center justify-content-around"></div>

                <div className="dropdown align-items-center mt-3">
                  <div
                    data={noticeDatas?.length}
                    className="notice_svg me-3"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      style={{ cursor: "pointer" }}
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M23.4375 12.1375V11.2563C23.4375 6.42 19.6575 2.5 15 2.5C10.3425 2.5 6.5625 6.42 6.5625 11.2563V12.1375C6.56401 13.1894 6.26381 14.2198 5.6975 15.1062L4.3125 17.2625C3.04875 19.2313 4.01375 21.9075 6.2125 22.53C11.9576 24.1587 18.0424 24.1587 23.7875 22.53C25.9863 21.9075 26.9513 19.2313 25.6875 17.2638L24.3025 15.1075C23.7358 14.2211 23.4351 13.1908 23.4363 12.1388L23.4375 12.1375Z"
                        fill="#A7ADB4"
                      />
                      <path
                        d="M9.375 22.75C10.1937 24.935 12.4025 27.5 15 27.5C17.5975 27.5 19.8062 24.935 20.625 22.75"
                        fill="#A7ADB4"
                      />
                    </svg>
                  </div>
                  <ul
                    className="dropdown-menu notice_data py-0"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="p-3 border-bottom">
                      <p className="mb-0 d-flex align-items-center justify-content-between">
                        Notifications
                        <span>
                          <i className="fa-solid fa-bars-staggered"></i>
                        </span>
                      </p>
                    </li>

                    <li>
                      <ul className=" list-unstyled p-0 notice_data_item_box">
                        {noticeDatas?.map((noticeData, index) => {
                          return (
                            <li key={index}>
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  handleNoticeModal(noticeData);
                                }}
                              >
                                <p className="notice_data_item mb-0">
                                  {noticeData?.name}
                                </p>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                </div>


                <div className="d-flex flex-row top-bar-dashboard">
  <div className="dropdown">
    <button
      className="btn dropdown-toggle p-0 ms-2 drop_down_dasboard d-flex justify-content-between align-items-center mt-2"
      type="button"
      id="dropdownMenuButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style={{
        backgroundColor: "transparent",
        border: "transparent",
      }}
    >
      <div className="data-user text-dark d-flex justify-content-center flex-column me-3">
        <h5 className="text-end mb-0">
          {capitalizeFirstLetter(userName)}
        </h5>
        <h6 className="mb-0 text-end">{userAddress}</h6>
      </div>

      {profileImage?.length > 0 ? (
        <span
          className='j_header_profile_img'
          style={{
            border: "1px solid black",
            borderRadius: "50%",
            transition: '0.3s'
          }}
        >
          <img
            className="fluid"
            src={`${profileImage}`}
            alt="Profile"
            style={{
              borderRadius: "8px",
              height: "3rem",
              width: "3rem",
              borderRadius: "100px",
            }}
          />
        </span>
      ) : (
        <span
          className='j_header_profile_img'
          style={{
            border: "1px solid black",
            borderRadius: "50%",
            transition: '0.3s'
          }}
        >
          <img
            src="./asset/users.png"
            className="fluid"
            alt="Default Profile"
            style={{
              borderRadius: "8px",
              height: "3rem",
              width: "3rem",
              borderRadius: "100px",
            }}
          />
        </span>
      )}
    </button>

    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <li>
        <a
          className="dropdown-item d-flex justify-content-between align-items-center"
          href="/bookmarks"
        >
          Bookmark
          <span>
            <i
              style={{ fontSize: '14px', color: "#898b8d" }}
              className="fa-solid fa-bookmark"
            ></i>
          </span>
        </a>
      </li>
      <li>
        <a
          className="dropdown-item d-flex justify-content-between align-items-center"
          href="/settings"
        >
          Manage Profile
          <span>
            <i
              style={{ fontSize: '14px', color: "#898b8d" }}
              className="fa-solid fa-gear"
            ></i>
          </span>
        </a>
      </li>
      <li>
        <a className="dropdown-item" onClick={() => logout()}>
          <button
            className="logout w-100"
          >
            Logout
          </button>
        </a>
      </li>
    </ul>
  </div>
</div>

              </Col>
          </Row>

         { isLoading ?  <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container> :
          <>
         {firms?.data?.length>0 ? <><Row>
            <Col sm={12}>
              {/* <h2 className='g_dasboard_head_line mb-4'>GLOBAL DASHBOARD</h2> */}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className="account-information  d-flex align-items-center px-4 justify-content-between mb-4">


                      <span className="p-1">{searchValue?.length > 0 ? "Registered Firms" : " Registered Firms"}</span>


                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} >

                  <span className="near_me">
                    <Link to="/firm" className="near_me">
                      View All
                    </Link>
                  </span>

                </div>
              </div>

            </Col>
          </Row>


          <Slider className='row' style={{ gap: '10px' }} {...settings} tabIndex="-1">
            {firmsData?.map((elem) => (
              <div key={elem._id} className='px-3 py-2' tabIndex="-1">
                <FirmShow elem={elem} localUserId={localUserId} mainBookmarkId={mainBookmarkId} bookmarkData={bookmarkData} fetchData={fetchData} />
              </div>
            ))}
          </Slider>
          </>
                  : ("") }
                    {/* {searchValue?.length === 0 ? (
                      <>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={12}>
                            <div className="account-information  d-flex align-items-center px-4 justify-content-between mb-4">
                              <span className="p-1">{searchValue?.length > 0 ? "" : "Featured Firms"}</span>


                              <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} >

                                <span className="near_me">
                                  <Link to="/firm" className="near_me">
                                    View All
                                  </Link>
                                </span>

                              </div>
                            </div>

                          </Col>
                        </Row>
                        <Slider className='row' style={{ gap: '10px' }} {...settings}>
                          <div className='px-3 py-2'>
                            <NonFirm />
                          </div>
                        </Slider>
                      </>
                      ): ("")}     */}

    
                {registrantsData?.length > 0 ?
                  <>
                    <Row className="my-4" tabIndex="-1">
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <div className="account-information  d-flex align-items-center px-4 justify-content-between ">
                          <span className="p-1">{searchValue?.length > 0 ? "Registered Individuals" : "Registered Individuals "}</span>
                          <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} >
                            <span className="near_me">
                              <Link to="/registrant-search" className="near_me">
                                View All
                              </Link>
                            </span>

                          </div>
                        </div>

                      </Col>
                    </Row>

                    <Slider className='row' style={{ gap: '10px' }} {...registranSettings} tabIndex="-1">
                      {registrantsData?.map((elem) => (
                        <div key={elem?._id} className='px-3 py-2' tabIndex="-1">
                          <RegistrantShow elem={elem} fetchData={fetchData} registrantBookmarkData={registrantBookmarkData} />
                        </div>
                      ))}


                    </Slider>
                  </>
                  : ("")}
                  {/* <>
            {searchValue?.length === 0 ? (<>
                <Row className="my-4">
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className="account-information  d-flex align-items-center px-4 justify-content-between ">
                      <span className="p-1">{searchValue?.length > 0 ? "" : "Featured Registrants"}</span>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} >
                        <span className="near_me">
                          <Link to="/registrant-search" className="near_me">
                            View All
                          </Link>
                        </span>

                      </div>
                    </div>

                  </Col>
                </Row>
              <Slider className='row' style={{ gap: '10px' }} {...registranSettings}>
                <div className='px-3 py-2'>
                  <NonRegistarnt />
                </div>
              
              </Slider>
            </>) : ('')}
        </> */}     
                
                {/* {The PCMA Members section is currently inaccessible. } */}
                
          {/* {members?.data?.length > 0 ? <Row className="my-4">

            <Col xl={12} lg={12} md={12} sm={12}>
              <div className="account-information  d-flex align-items-center px-4 justify-content-between ">
            
                
                <span className="p-1">PCMA Executives and Board of Directors</span>
                  
                 
                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} >
                
                  <span className="near_me">    
                    <Link to="/members-search" className="near_me" >
                      View All
                    </Link>
                  </span>

                </div>
              </div>

            </Col>
          </Row> : ''}
          { members?.data?.length > 0 ?
          <Row className="g-3 mb-4">
            {members?.data?.map((elem) => (
              <Col key={elem?._id} lg={6}>
                <MemberShow elem={elem}/>
              </Col>
            ))}
          </Row>
                  : ''}      */}
              </>}
            
          {firms?.message === "No data found" && members?.message === "No data found" && registrantsData?.message === "No data found" && !isLoading &&
            <div className='d-flex align-items-center justify-content-center w-100' style={{ minHeight: '360px' }}>
              <h3>
                No Data Found !!!
              </h3>
            </div>
          }

          {lastUpdate ? (
            <div>
              <div className=' last_update d-flex align-items-center justify-content-center justify-content-md-end mb-3'>
                <span>Last Updated On : {lastUpdate}</span>
              </div>
            </div>
          ) : ("")}
        </Container>



      </div>

      <Footer />
      
    </div>
     <>
      <div className="d-flex justify-content-center align-items-center">
          <Modal show={showModal} onHide={handleClose} centered size="lg" id="notificaion-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                <span style={{ color: "#0F66B4" }}>Notifications</span>{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="model-content">
              <div className="notification-img">
                <div
                  className="img_container "

                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 39 39"
                    fill="none"
                    className=""
                  >
                    <path
                      d="M14.6091 26.6064L19.5 23.6384L24.3909 26.6064L23.095 21.0467L27.4425 17.2844L21.7155 16.8246L19.5 11.5575L17.2844 16.8246L11.5575 17.2844L15.905 21.0467L14.6091 26.6064ZM19.5 38.3947L13.8984 32.8768H6.12316V25.1015L0.605225 19.5L6.12316 13.8984V6.12316H13.8984L19.5 0.605225L25.1015 6.12316H32.8768V13.8984L38.3947 19.5L32.8768 25.1015V32.8768H25.1015L19.5 38.3947ZM19.5 33.7128L23.6802 29.5326H29.5326V23.6802L33.7128 19.5L29.5326 15.3197V9.46736H23.6802L19.5 5.28711L15.3197 9.46736H9.46736V15.3197L5.28711 19.5L9.46736 23.6802V29.5326H15.3197L19.5 33.7128Z"
                      fill="#0F66B4"
                    />
                  </svg>
                </div>
                <span>{notificationDate}</span>
              </div>

              <div className="modal_body">
                <h4>{notificationName}</h4>
                <div className="notification_message overflow-auto">
                  <p>
                    {notificationmessage}
                  </p>
                </div>
              </div>
            </Modal.Body>

          </Modal>  
        
      </div>
     </>
    </>
  )
}

export default GlobalDashboard