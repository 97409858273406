import React, { useState, useEffect } from 'react';
import { Form, FormControl, Button, InputGroup } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';

import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';



const AdminLogin = () => {

  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
  });

  const [errors, setErrors] = useState({
    username: '',
    password: ''
  })
  let newErrors = { ...errors }

  let token;
  useEffect(() => {
    redirectToLogin();
  },[]);

  const redirectToLogin = () => {
    const logindata = JSON.parse(window.localStorage.getItem("loginAdmin"));
     token = logindata?.Token || null;
    if (token !== null) {
      navigate('/admin');
    }
  };
  

  useEffect(() => {

    const storedUsername = localStorage.getItem('usernameAdmin');
    const storedPassword = localStorage.getItem('passwordAdmin');
    const storedRememberMe = localStorage.getItem('rememberMeAdmin') === 'true';


    setFormData({
      username: storedUsername || '',
      password: storedPassword || '',
      rememberMe: storedRememberMe,
    });

  }, []);

  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
  const [passwordVisible, setPasswordVisible] = useState(false);

const togglePassword = () => {
  setPasswordVisible((prev) => !prev);
};




  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    const filteredValue = value.replace(emojiRegex, '');
    validateField(name, filteredValue);

    if (name === 'rememberMe' && checked) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('username', formData.username);
      localStorage.setItem('password', formData.password);
    } else if (name === 'rememberMe' && !checked) {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }


  };


  const handleSubmit = (e) => {
    e.preventDefault();

    validateForm();
    const { username, password, rememberMe } = formData;
    if (!Object.values(errors).some((error) => error !== '')) {
      if (username && password) {
        setSpinner(true);
        let login_credential = {
          username: username,
          password: password,
        }
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey);
        const windowTime = Date.now();
        let stringify = {
          ...login_credential,
          windowTime: btoa(windowTime)
        }
        var encrypted = encryptor.encrypt(JSON.stringify(stringify));


        axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/login`
          , {
            encrypted
          }
        )
          .then(function (response) {
            setSpinner(false)
            let data = response.data;
            data = JSON.stringify(data);

            if (response.data.message === 'You have logged in sucessfully!') {
              localStorage.setItem('loginAdmin', data);
              if (rememberMe) {
                localStorage.setItem('usernameAdmin', username);
                localStorage.setItem('passwordAdmin', password);
                localStorage.setItem('rememberMeAdmin', true);
              }
              // navigate('/admin');
              toast.success(`${response?.data?.message}`);
              setTimeout(() => {
                window.location.href = '/admin';
              }, 400);
            } else {
              toast.error(`${response?.data?.error}`)
            }
          })
          .catch(function (error) {
            console.log('error', error);
            
            setTimeout(() => {
              setSpinner(false)
            }, 2000);
            toast.error(error?.response?.data?.error)
          });   
      }
    } else {
      setTimeout(() => {
        setSpinner(false)
      }, 2000);
      setErrors(errors);
    }
  };

  let validateField = (fieldName, value) => {
    if (fieldName === 'username') {
      let userRegex = /^(?![0-9]+$)[a-zA-Z0-9\S]{4,20}$/;
      newErrors.username = value?.trim() === '' ? 'Username is required!' : !userRegex?.test(value?.trim()) ? 'Username is not valid! (Username must contains only letters and/or numbers and 4 to 20 characters in length)' : '';
    }
    if (fieldName === 'password') {
      newErrors.password = value?.trim() === '' ? 'Password is required!' : '';
    }
    setErrors(newErrors);
  };

  const validateForm = () => {

    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        validateField(fieldName, formData[fieldName]);
      }

    }
    setSpinner(false);
  };


 

  return (
    <>
        <div className="main_login_body  ">
          <div className="left_side d-flex flex-column justify-content-between">
            <div className="upper-part text-center text-lg-start  pe-3">
              <h1 className="first-text ">Unleash the Potential</h1>
              <h4 className="second-text ">Where Innovation Meets Capital</h4>
              <p>
                Discover a Vibrant and Dynamic Network of Exempt Market
                Professionals United by Excellence and Innovation.
              </p>
            </div>
            <div className="dashboard-screen-image d-flex justify-content-end"></div>
          </div>

          <div className=" right_side d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className=" d-flex align-items-center justify-content-center">
                    <div className="form_register">
                      <Link to="https://pcma1dev.wpenginepowered.com/">
                        <img
                          src="../asset/logo.png"
                          className="img-fluid"
                          alt=""
                          // style={{ height: "80px", width: "150px" }}
                        />
                      </Link>

                      <h1 className="member-heading pt-5">Admin Login</h1>
                      <Form
                        onSubmit={handleSubmit}
                        className="pt-4 registaer_form"
                      >
                        <Form.Group className="username d-flex flex-column justify-content-around">
                          <Form.Label htmlFor="username " className="fw-bolder">
                            Username
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>


                          <Form.Control
                            type="text"
                            name="username"
                            id="username"
                            maxLength={20}
                            className={`${((errors.username) && (formData.username === '') || (errors.username)) && 'error_active'}
                                      ${((errors.username === '') && (formData.username)) && 'sucess_active'}`}
                            placeholder="Enter Your Username"
                            value={formData?.username?.trimStart()}
                            onChange={handleChange}
                          />
                        <span style={{ color: "red" }}>
                          {errors.username}
                        </span>
                        </Form.Group>
                        <Form.Group className="password d-flex flex-column justify-content-around py-2">
                          <Form.Label htmlFor="password " className="fw-bolder">
                            Password
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                          <div className="position-relative">

                            <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            className={`
                                     ${((errors.password) && (formData.password === '') || (errors.password)) && 'error_active'}
                                     ${((errors.password === '') && (formData.password)) && 'sucess_active'}`}
                              name="password"
                              id="password"
                              placeholder="Enter Your Password"
                            value={formData?.password?.trimStart()}
                              onChange={handleChange}
                              maxLength={20}
                            />

                            <button
                              type="button"
                              className="bg-transparent border-0 position-absolute eyBtn"
                              onClick={togglePassword}
                            >
                              {!passwordVisible ? (
                                <i className="fa-regular fa-eye-slash"></i>
                              ) : (
                                <i className="fa-regular fa-eye"></i>
                              )}
                          </button>
                          <span style={{ color: "red" }}>
                            {errors.password}
                          </span>
                          </div>
                        </Form.Group>
                        <div className="col d-flex justify-content-between p-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="form2Example34"
                              name='rememberMe'
                              checked={formData.rememberMe}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="form2Example34"
                            >
                              Remember me
                            </label>
                          </div>
                          {/* <Link
                            to="/reset-password"
                            style={{ color: "#1E1E1E" }}
                            className="text-decoration-none"
                          >
                            Forgot password?
                          </Link> */}

                        </div>


                        <div className="register_login">


                          <Button type="submit" disabled={spinner}  >Login {spinner ? (<i className="fa-solid fa-spinner fa-spin"></i>) : null}</Button>
                        </div>
                      </Form>
                      <div className=" d-flex align-items-center my-4">
                     
                      </div>
                      {/* <p className="divider text-center my-3">
                        or-----------
                      </p> */}
                      <div className="register_facebook">

                      </div>
                      <div className="not-member mt-5 w-100">

                      </div>
                      <div className="note mt-5 w-100">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* testing */}
          {/* <FacebookLogin
        appId="your-facebook-app-id"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
      /> */}

          {/* testing */}
        </div>
    </>
  );
}

export default AdminLogin