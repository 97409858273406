import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Country, State } from "country-state-city";
import { BallTriangle } from 'react-loader-spinner';
import Select from 'react-select';
import toast from 'react-hot-toast';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Swal from "sweetalert2";
import { set } from 'lodash';

const AdminUserView = ({ editUserId, sendeditOpen, sendEditOpenData }) => {

  let loginData = JSON.parse(window.localStorage.getItem("loginAdmin"));
  const id = editUserId;
  let token = loginData.Token;
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoding] = useState(false);
  const [userName, setUserName] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const [userPic, setUserPic] = useState('');
  const [userType, setUserType] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userStatus, setUserStatus] = useState(false);
  const [userPhoneCode, setUserPhoneCode] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userGender, setUserGender] = useState('');
  const [userOrgaization, setUserOrgaization] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userAddressContact, setUserAddressContact] = useState('');
  const [userWebsite, setUserWebsite] = useState('');
  const [userCountry, setUserCountry] = useState('');
  const [userCity, setUserCity] = useState('');
  const [userPostalCode, setUserPostalCode] = useState('');
  const [userFax, setUserFax] = useState('');
  const [userProvince, setUserProvince] = useState('');
  const [userComDescription, setUserComDescription] = useState('');
  const [userindustryPreferences, setUserIndustryPreferences] = useState([]);


  useEffect(() => {
    document.title = "PCMA || PCMA Members Details";
  }, []);

  const capitalize = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };

  useEffect(() => {
    fetchData()
  }, []);



  const fetchData = async (e) => {
    setSpinner(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/user-get-profile`,
        {
          params: { id: id, },
          headers: { Authorization: `Bearer ${token}`, }
        }
      );
      if (response.data) {
        const getUserData = response.data;
        setUserFullName(getUserData?.name);
        setUserName(getUserData?.username);
        setUserEmail(getUserData?.email);
        setUserGender(getUserData?.gender);
        setUserStatus(getUserData?.status);
        setUserOrgaization(getUserData?.organization);
        setUserAddress(getUserData?.address);
        setUserAddressContact(getUserData?.address_cont);
        setUserPic(getUserData?.profile_picture);
        setUserWebsite(getUserData?.website);
        setUserCountry(getUserData?.country);
        setUserProvince(getUserData?.province);
        setUserPostalCode(getUserData?.postal_code);
        setUserCity(getUserData?.city);
        setUserPhone(getUserData?.phone);
        setUserFax(getUserData?.fax);
        setUserComDescription(getUserData?.company_description);
        setUserIndustryPreferences(getUserData.industry_preferences);
      }
      setSpinner(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };





  const cancel = () => {
    sendeditOpen(!sendEditOpenData);
  };


  return (
    <>
      {spinner ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : ( 
      <Container fluid className="pt-5 mb-4">
        <Row className="py-3">
          <Col sm={12} className='px-0' >
             <span onClick={cancel} style={{ cursor: "pointer", color: "#0F66B4", fontWeight: "500", marhintop: "10px" }} className="p-1  position-static">
              <i className="fa-solid fa-arrow-left-long me-2"></i>Go Back
            </span>
          </Col>
          <Col xs={12} md={12} lg={12} style={{ background: "#137CD9", color: "#fff", borderRadius: "5px", margin: "37px 0 0 0", }}>
            <h2 className="mb-0 py-2 firmManagement">PCMA Members Management</h2>
          </Col>
        </Row>
        <Row className=' whole_container mt-2 p-3'>
          <Col sm={12} md={12} lg={12}>
            <div className="d-flex justify-content-between p-3" style={{ borderBottom: "1px solid rgba(15, 102, 180, 0.18)" }}>
              <div className="d-flex">
                    {userPic ?
                      <img style={{ height: "50px", width: "50px", borderRadius: "50%", }} src={userPic} className='img-fluid' />
                      :
                      <img src="/asset/users.png" alt="ceoimage" className="img-fluid" style={{ height: "50px", width: "50px", borderRadius: "50%", }} />
                    }
                
                <div className="px-1 mt-2">
                      <div className='userView_name'>
                        {userFullName ? userFullName :"--"}
                    </div>
                </div>
              </div>
              <div className="d-flex">
                    <div class="d-flex align-items-center"><form class="form-check form-switch"><label class="form-check-label" for="flexSwitchCheckChecked"><strong>Status:</strong>{userStatus ? "Enabled" : "Disabled"}</label></form></div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={12} style={{ background: "#137CD9", color: "#fff", borderRadius: "5px" }} className='mt-4'>
            <h2 className="mb-0 py-2 firmManagement">Account Information</h2>
          </Col>
              <div className='user-infromation-info' style={{ marginTop: "32px" }}>
                <Row >
                  <Col sm={6} md={6} lg={3} xl={3} className='pborder-right'>
                    <div >
                      <div className='user_information p-2 pll-0 prr-0'>
                        <h6>Full Name</h6>
                        <p className='mb-0'>{userFullName ? capitalize(userFullName) : "--"}</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={3} xl={3} className='pborder-right'>
                    <div className=''>
                      <div className='user_information p-2 pll-0 prr-0'>
                        <h6>Username</h6>
                        <p className='mb-0'>{userName ? userName : "--"}</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={3} xl={3} className='pborder-right'>
                    <div className=''>
                      <div className='user_information p-2 pll-0 prr-0'>
                        <h6>Email Address</h6>
                        <p className='mb-0'>{userEmail ? (<Link className='text-decoreation-none' to={`mailto:${userEmail}`} >{userEmail}</Link>) : "--"}</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={3} xl={3} className='pborder-right'>
                    <div className=''>
                      <div className='user_information p-2 pll-0 prr-0'>
                        <h6>Gender</h6>
                        <p className='mb-0'>{userGender ? capitalize(userGender) : "--"}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>              
          <Col sm={12} md={12} lg={12} style={{ background: "#137CD9", color: "#fff", borderRadius: "5px" }} className='mt-4'>
            <h2 className="mb-0 py-2 firmManagement">Professionals Information</h2>
          </Col>
          <div className='p-info mt-5 mb-4' >

            <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Organization</h6>
                    <p className='mb-0'>{userOrgaization ? userOrgaization :"--" }</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Address</h6>
                    <p className='mb-0'>{userAddress ? userAddress :"--" }</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Address Contact</h6>
                    <p className='mb-0'>{userAddressContact ? userAddressContact : "--"}</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Website</h6>
                    {userWebsite ? (<p className='mb-0'><Link className='text-decoreation-none' to={userWebsite?.includes("http") ? userWebsite : "https://" + userWebsite} target='_blank'>{userWebsite?.replace(/^https?:\/\//, '')}</Link></p>) : (<p className='mb-0'>--</p>)}
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Country</h6>
                    <p className='mb-0'>{userCountry ? userCountry : "--"}</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>City / Town</h6>
                    <p className='mb-0'>{userCity ? userCity : "--"}</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Province</h6>
                    <p className='mb-0'>{userProvince ? userProvince : "--"}</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Postal Code</h6>
                    <p className='mb-0'>{userPostalCode ? userPostalCode : "--"}</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Phone</h6>
                    <p className='mb-0'>{userPhone ? userPhone : "--"}</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Fax</h6>
                    <p className='mb-0'>{userFax? userFax :"--" }</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Company Description</h6>
                    <p className='mb-0'>{userComDescription ? userComDescription :"--" }</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={4} xl={3} className='pborder-right pborder-bottom'>
                <div >
                  <div className='user_info p-4 pLl-0 prr-0'>
                    <h6>Industry Preferences</h6>
                    {userindustryPreferences ?
                    <div className="d-flex align-items-center flex-wrap" style={{ gap: '10px' }}>
                      {userindustryPreferences?.map((userindustryPreferences, index) => (
                        <span className=" d-inline-flex " key={index} style={{ backgroundColor: " rgba(129, 136, 147, 0.08)", padding: '10px', borderRadius: '2px', color: '#818993', fontSize: '16px', }}>{userindustryPreferences}</span>
                      ))}
                      </div>
                      : <p className='mb-0'>"--</p>}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Row>


        </Container>
      )}
    </>
  );
}
export default AdminUserView;
