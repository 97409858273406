// import React from 'react';
// import PropTypes from 'prop-types';

// const CustomPagination = ({ total, current, onPageChange }) => {
//   const range = 2;

//   const getPages = () => {
//     let pages = [];


//     if (current > 1) {
//       pages.push('<<');
//     }

//     if (current > 1 + range) {
//       pages.push(1);
//       if (current > 2 + range) {
//         pages.push('...');
//       }
//     }

//     const start = Math.max(1, current - range);
//     const end = Math.min(total, current + range);
//     for (let i = start; i <= end; i++) {
//       pages.push(i);
//     }

//     if (current < total - range) {
//       if (current < total - range - 1) {
//         pages.push('...');
//       }
//       pages.push(total);
//     }

//     if (current < total) {
//       pages.push('>>');
//     }

//     return pages;
//   };

//   const pages = getPages();

//   const handlePageChange = (page) => {
//     if (page === '<<') {
//       onPageChange(Math.max(current - 1, 1));
//     } else if (page === '>>') {
//       onPageChange(Math.min(current + 1, total));
//     } else if (page !== '...') {
//       onPageChange(page);
//     }
//   };

//   return (
//     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//       {pages.map((page, index) => (
//         page === '...' ? (
//           <span key={index} style={{ margin: '0 5px', color: '#0f66b4' }}>...</span>
//         ) : (
//           <button
//             key={index}
//             onClick={() => handlePageChange(page)}
//             style={{
//               margin: '0 2px',
//               height: '40px',
//               minWidth: '40px',
//               borderRadius: '5px',
//               textAlign: 'center',
//               textDecoration: 'none',
//               color: page === current ? '#ffffff' : '#0f66b4',
//               backgroundColor: page === current ? '#0f66b4' : '#ffffff',
//               border: `1px solid ${page === '<<' || page === '>>' ? '#cccccc' : '#0f66b4'}`,
//               cursor: 'pointer',
//               fontWeight: page === current ? '700' : 'normal',
//               fontSize: '18px',
//               padding: '8px 16px',
//               display: 'flex',
//               alignItems: 'center'
//             }}
//           >
//             {page === '<<' ? '\u00AB' : page === '>>' ? '\u00BB' : page}
//           </button>
//         )
//       ))}
//     </div>
//   );
// };

// CustomPagination.propTypes = {
//   total: PropTypes.number.isRequired,
//   current: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
// };

// export default CustomPagination;


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomPagination = ({ total, current, onPageChange }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1400);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const range = isMobile ? 1 : 2; 

  const getPages = () => {
    let pages = [];

    if (current > 1) {
      pages.push('<<');
    }

    if (current > 1 + range) {
      pages.push(1);
      if (current > 2 + range) {
        pages.push('...');
      }
    }

    const start = Math.max(1, current - range);
    const end = Math.min(total, current + range);
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (current < total - range) {
      if (current < total - range - 1) {
        pages.push('...');
      }
      pages.push(total);
    }

    if (current < total) {
      pages.push('>>');
    }

    return pages;
  };

  const pages = getPages();

  const handlePageChange = (page) => {
    if (page === '<<') {
      onPageChange(Math.max(current - 1, 1));
    } else if (page === '>>') {
      onPageChange(Math.min(current + 1, total));
    } else if (page !== '...') {
      onPageChange(page);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {pages.map((page, index) => (
        page === '...' ? (
          <span key={index} style={{ margin: '0 5px', color: '#0f66b4' }}>...</span>
        ) : (
          <button
            key={index}
            onClick={() => handlePageChange(page)}
            style={{
              margin: '0 2px',
              height: '40px',
              minWidth: '40px',
              borderRadius: '5px',
              textAlign: 'center',
              textDecoration: 'none',
              color: page === current ? '#ffffff' : '#0f66b4',
              backgroundColor: page === current ? '#0f66b4' : '#ffffff',
              border: `1px solid ${page === '<<' || page === '>>' ? '#cccccc' : '#0f66b4'}`,
              cursor: 'pointer',
              fontWeight: page === current ? '700' : 'normal',
              fontSize: '18px',
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {page === '<<' ? '\u00AB' : page === '>>' ? '\u00BB' : page}
          </button>
        )
      ))}
    </div>
  );
};

CustomPagination.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;

