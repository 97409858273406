import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import {store} from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
    <Toaster 
     position="top-right"
     reverseOrder={false}
     gutter={8}
     containerClassName=""
     containerStyle={{}}
     toastOptions={{
       className: '',
       duration: 5000,
       style: {
         background: '#363636',
         color: '#fff',
       }}}
     />
     <Provider store={store} >
      <App />
     </Provider>
    
    </BrowserRouter>
  </>
);

