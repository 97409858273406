import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const MemberShow = ({ elem }) => {
  const [shortName, setShortName] = useState(null);
  const navigate = useNavigate();
  let [firstLetterOfFirstName, firstLetterOfLastName] = elem?.name?.split(" ")?.map(name => name?.charAt(0));

  const handleButtonClickPhone = (phone) => {
    navigator.clipboard.writeText(phone);
    toast.success(`Phone number copied to clipboard ${phone}`)
  }
  const handleClick = () => {
    navigate(`/firm/member-details/${elem._id}`);
  }
  const handleButtonClick = (email) => {
    navigator.clipboard.writeText(email);
    toast.success(`Email copied to clipboard ${email}`)
  };

  useEffect(() => {
    let newShortName
    if (firstLetterOfLastName === undefined) {
      firstLetterOfLastName = ''
      newShortName = firstLetterOfFirstName + firstLetterOfLastName;
      setShortName(newShortName)
    }
    else {
      newShortName = firstLetterOfFirstName + firstLetterOfLastName;
      setShortName(newShortName)
    }
  }, [shortName])

  return (
    <>
      <div className="member_card d-flex align-items-center justify-content-between">
        <div className="member_card_left_site" style={{ width: '40%' }}>
          <div className=' d-flex align-items-center pe-3 member-left-box'>
            <div
              className="d-flex align-items-center me-3 dashboard_card_imag d-flex align-items-center justify-content-center">
              <div className="Registrant_short_name_box" style={{ cursor: "pointer", height: '52px', width: '52px' }} onClick={handleClick}>
                {elem.profile_picture ?
                  <img style={{
                    borderRadius: "100px",
                    height: '52px', width: '52px',
                    cursor: "pointer",
                  }} src={elem.profile_picture} alt='firm_image_url' /> : <p style={{ fontSize: '18px' }}>{shortName}</p>}

              </div>
            </div>
            <div onClick={handleClick} className='text-truncate member_text'>
              <h5
                className="card-name text-truncate fw-bold mb-1 "
                style={{ cursor: "pointer", color: '#0F67B5', fontSize: '16px' }}
              >

                {elem?.name}
              </h5>
              <h6
                className="card-location text-truncate  mb-0"
                style={{ color: 'rgb(155 155 156)', fontSize: '14px' }}
              >
                {elem?.membership_type ? elem?.membership_type : '...'}
              </h6>
            </div>
          </div>
        </div>
        <div className="member_card_right_site" style={{ width: '60%', gap: '10px' }}>
          <div className="d-flex align-items-center justify-content-between member_card_right_site-box" style={{ columnGap: '10px' }}>
            <div className='text-truncate member_center_txt'>
              <h6
                className="card-location text-truncate mb-1"
                style={{ color: 'rgb(155, 155, 156)', fontSize: '14px' }}
              >
                EMAIL
              </h6>
              <div className='d-flex flex-nowrap'>
                <h5
                  className="card-name fw-bold mb-0 text-truncate " data-bs-toggle="tooltip" data-bs-placement="top" title={`${elem?.email}`}
                  style={{ color: '#0F67B5', fontSize: '16px' }}
                >

                  {elem?.email}

                </h5>
                <span onClick={() => { handleButtonClick(elem?.email) }} className='px-2' style={{ cursor: "pointer" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.666 2H2.66602V10.6667" stroke="#106BBD" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.33301 4.66675H13.333V12.6667C13.333 13.0204 13.1925 13.3595 12.9425 13.6096C12.6924 13.8596 12.3533 14.0001 11.9997 14.0001H6.66634C6.31272 14.0001 5.97358 13.8596 5.72353 13.6096C5.47348 13.3595 5.33301 13.0204 5.33301 12.6667V4.66675Z" stroke="#106BBD" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </div>

            </div>
            <div className="d-flex j_re_btn align-items-center member_card_btn">
              {elem?.website?.length > 1 ? <button
                className="email-button"
                onClick={() => {
                  window.open(elem?.website.includes("http") ? elem?.website : "https://" + elem.website, "_blank")
                }}
              >
                <i className="fa-solid fa-globe"></i>
                <span>Website</span>
              </button> : <button
                className="email-button"
                style={{ marginLeft: '0', marginRight: '0px' }}
                disabled
              >
                <i className="fa-solid fa-globe"></i>
                <span>Website</span>
              </button>}


              {elem?.phone?.length > 1 ?
                <button
                  className="email-button "
                  onClick={() => handleButtonClickPhone(elem?.phone)}
                >
                  <i className="fa-solid fa-phone"></i>
                  <span>Phone</span>
                </button>
                :
                <button
                  className="email-button"
                  disabled
                  style={{ marginLeft: '0', marginRight: '0px' }}
                >
                  <i className="fa-solid fa-phone"></i>
                  <span>Phone</span>
                </button>}
              <button className="email-button member-right-btn">
                <i className="fa-solid fa-envelope"></i>
                <span>Email</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MemberShow