import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const fetchNews = createAsyncThunk('fetchNews', async () => {
    try {
        const response = await fetch(`https://pcma1dev.wpenginepowered.com/wp-json/news/v1/news`);
      return response.json();
    } catch (error) {
      console.error("Error fetching category:", error);
      throw error; 
    }
});
  
const newsSlice = createSlice({
    name:'news',
    initialState:{
        isLoading:false,
        data:[],
        isError:false,
    },
    extraReducers : (builder) => {
        builder.addCase(fetchNews.pending, (state,action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchNews.fulfilled, (state,action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchNews.rejected, (state,action) => {
          state.isError=true;
   
        });

    }
});
export default newsSlice.reducer;