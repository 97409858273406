import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-hot-toast';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });

   useEffect(() => {
    document.title = "PCMA || Forgot Password";
  }, []);

  const [showModal, setShowModal] = useState(true);
const [spinner, setSpinner] = useState(false);
  const handleSuccess = () => {
    setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
    // navigate("/register");
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    redirectToLogin();
  });

  const redirectToLogin = () => {
    const registerdata = window.localStorage.getItem("registerOption");
    if (!registerdata) {
      window.localStorage.removeItem("register_data")
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
      setSpinner(true);
    let email = formData.email;

    try{
     const {data} =await axios.post(`${process.env.REACT_APP_PCMA_BACKEND}/api/verify-account`, {
        "email": email
     })
      setSpinner(false);
      if (data.status === true){
        toast.success(`${data.message}`)
      } else if (data.status === false) {
        toast.error(`${data.message}`)
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setSpinner(false);
    }
  };

  let membership_type = window.localStorage.getItem("registerOption");
  return (
    <>
      <div className="main_login_body  ">
        <div className="left_side d-flex flex-column justify-content-between">
          <div className="upper-part">
            <h1 className="first-text ">Unleash the Potential</h1>
            <h4 className="second-text ">Where Innovation Meets Capital</h4>
            <p>
              Discover a Vibrant and Dynamic Network of Exempt Market
              Professionals United by Excellence and Innovation.
            </p>
          </div>
          <div className="dashboard-screen-image d-flex justify-content-end">
          </div>
        </div>

        <div className=" right_side d-flex align-items-center ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className=" d-flex align-items-center justify-content-center">
                  <div className="form_register">
                    <Link to="https://pcma1dev.wpenginepowered.com/">
                        <img
                          src="./asset/logo.png"
                          className="img-fluid"
                          alt=""
                        />
                    </Link>
                    <h1 className="member-heading pt-5">Forgot Password</h1>
                    <Form
                      onSubmit={handleSubmit}
                      className="pt-4 registaer_form"
                    >
                      <Form.Group className="email d-flex flex-column justify-content-around py-2">
                        <Form.Label htmlFor="email" className="fw-bolder position-relative">
                          Email
                          <svg
                            className="ms-1 position-absolute"
                            style={{top:'2px'}}
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Enter Your Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <div className="register_login">
                        <Button type="submit" disabled={spinner}>
                          Send Mail{" "}
                          {spinner ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : null}
                        </Button>
                      </div>
                    </Form>

                    <div className="not-member mt-5 w-100">
                      <p>
                        Already A Member ?{" "}
                        <Link to="/" className="text-decoration-none">
                          <span className="join-pcma ms-1 ">Login Here!</span>
                        </Link>
                      </p>
                    </div>
                    <div className="note mt-5 w-100">
                      <p>
                        Reset your password using the forgot password mail send.
                        If you don't remember your Password, please{" "}
                        <span>
                  
                          <Link style={{color:'rgb(15 103 182)', textDecoration:"none"}} to="https://pcma1dev.wpenginepowered.com/">
                            CONTACT US.
                          </Link>
                        </span>
                       
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
