import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { BallTriangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogs } from '../../redux/slice/blogs';
import ResponsivePagination from 'react-responsive-pagination';
import Swal from "sweetalert2";
import CreateNewBlog from './CreateNewBlog';
import CustomPagination from '../../components/pagination/CustomPagination';

const UserBlogSection = () => {

    const loginData = JSON.parse(window.localStorage.getItem("login"));
    let token;
    let id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCreate, setIsCreate] = useState(false);
    const [editBlog, setEditBlog] = useState(false);
    const [blogData, setBlogData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const blogsState = useSelector((state) => state?.blogs);
    const noData =useSelector((state) => state?.blogs.error);
    const blogsData = blogsState?.data?.data;
    const lastPage = blogsState?.data?.last_page;
    const isLoading = blogsState?.isLoading;
    const itemsPerPage = blogsState?.data?.per_page
    const [sortBlog, setSortBlog] = useState('latest');

   if (loginData) {
    token = loginData.token;
    id = loginData?.user_data?._id
    }

    const handleToggle = () => {
        setIsCreate(true);
        setEditBlog(false);
    };

    useEffect(() => {
     document.title = "PCMA || Blog Management";
    }, [])

    useEffect(() => {
        dispatch(fetchBlogs({ id: id, currentPage: currentPage , sortBlog:sortBlog}));
    }, [id, currentPage, sortBlog, dispatch]);
    
    const handlePageChange = (data) => {
        setCurrentPage(data);   
   };

    const removeHtmlTags = htmlString => {
        const cleanContent = document.createElement('div');
        cleanContent.innerHTML = htmlString;
         return cleanContent.textContent;
    };
    
    const handleEditShow = (datas) => {
        setBlogData(datas)
        setEditBlog(true);
        setIsCreate(false);
    };

    const handleDeleteBlogs = async (postid) => {
    try {
       const confirmDeletion = await Swal.fire({
         title: "Delete Blog",
         text: "Do you want to delete this Blog? This action cannot be undone.",
         icon: "warning",
         showCancelButton: true,
         cancelButtonColor: "#D0D5DD",
         confirmButtonColor: "#D92D20",
         confirmButtonText: "Delete",
         iconHtml: '<i class="fas fa-trash-alt"></i>',
         iconColor: "red",
       });
       if (!confirmDeletion.isConfirmed) return;
        const response = await axios.delete(`${process.env.REACT_APP_PCMA_BACKEND}/api/delete-blog/${postid}`,
            {
            headers: {
                Authorization: `Bearer ${token}`,
            }
            }
            );
            dispatch(fetchBlogs({ id: id, currentPage: currentPage , sortBlog:sortBlog})); 
                if (response?.data?.message === "Post Deleted") {
                    toast.success(response?.data?.message);
                }
        } catch (error) {
                if (error?.data?.message === "Unauthozied User") {
                    localStorage.removeItem('login');
                    navigate("/");
                    toast.error(`Unauthozied user !`);
                } else {
                toast.error(error?.response?.data?.message?.message); 
                }
                }
    };

    const handlesortBlogs = () => {
        setSortBlog(sortBlog => (sortBlog === 'latest' ? 'oldest' : 'latest'));
    };
    
    return (
        <>
            <div className="container-fluid px-sm-3 mt-5 mt-lg-4">
                <div className="row g-2">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className='table-responsive' style={{ borderRadius: '6px' }}>
                            <table className="blog_table" >
                                <thead style={{ background: 'blue' }} >
                                    <tr className="table-heading-row" >
                                        <th className='text-center'>SL No.</th>
                                        <th className='text-center'>Date</th>
                                        <th className='text-center'>Blog Name 
                                            <span style={{ cursor: 'pointer' }} className="ms-2" onClick={handlesortBlogs}> 
                                                {sortBlog === 'latest' ? (<i className="fa-solid fa-arrow-up"></i>) : (<i className="fa-solid fa-arrow-down"></i>)}         
                                        </span>
                                        </th>
                                        <th className='text-center'>Description</th>
                                        {/* <th className='text-center'>No. of Comments</th> */}
                                        <th className='text-center'>Actions</th>
                                    </tr>
                                </thead>
                            {isLoading ? ( 
                                <tbody>
                                    <tr>
                                    <td colSpan={7}>
                                        <div className=' d-flex align-items-center justify-content-center'>
                                        <BallTriangle
                                            height={100}
                                            width={100}
                                            radius={5}
                                            color="#0f66b4"
                                            ariaLabel="ball-triangle-loading"
                                            wrapperClass={{}}
                                            wrapperStyle=""
                                            visible={true}
                                        />
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>                                     
                                ) : (
                                <tbody>
                                {noData ? (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="d-flex align-items-center justify-content-center w-100 ">
                                               <p className='mb-0 fw-bold'>There are no blogs available right now!</p>
                                            </div>
                                        </td>
                                    </tr>
                                        ) : (<>
                                { blogsData?.map((items, index) => (
                                    <tr key={`${index}-${currentPage}`}>
                                        <td className='text-center'>{currentPage > 1 ? (currentPage - 1) * itemsPerPage + index + 1 : index + 1}</td>
                                        <td className='text-center'>{items?.date?.split(' ')[0]}</td>
                                        <td className='text-center'><Link to={items?.url} target="_blank" className='text-truncate text-primary'>{items?.title}</Link></td>
                                        <td className='text-center '><p className='mb-0 text-truncate blog_description '>{removeHtmlTags(items?.content)}</p></td>
                                        {/* <td className='text-center'>0</td> */}
                                        <td className='text-center'>
                                            <div className="d-flex justify-content-center">
                                                <div className="mx-2" style={{ cursor: "pointer" }}  onClick={() => handleEditShow(items)} ><i className="fa-solid fa-pen" style={{ color: "#0F66B4" }} ></i></div>
                                                <div className="mx-2" style={{ cursor: "pointer" }} onClick={() => handleDeleteBlogs(items?.id)}><i  className="fa-solid fa-trash-can" style={{ color: "#FE6464" }}></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </>)}                  
                                </tbody>
                                )}
                            </table>                 
                        </div>
                    {isLoading ? (''):(
                            <div className='mt-2'>
                                <div className='d-flex justify-content-start align-items-center'>
                                <CustomPagination
                                    maxWidth={7}
                                    total={lastPage}
                                    current={currentPage}
                                    onPageChange={(page) => handlePageChange(page)}
                                />
                                </div>        
                            </div>
                     )}
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <button type="button" className="btn_cancel mt-3" onClick={handleToggle} >Create A New Blog  <i className="fa-solid fa-plus ms-2"></i></button>
                    </div>
                    {isCreate && (<CreateNewBlog isCreate={isCreate} setIsCreate={setIsCreate} currentPage={currentPage} sortBlog={sortBlog} />)} 
                    {editBlog && (<CreateNewBlog editBlog={editBlog} setEditBlog={setEditBlog} blogData={blogData} currentPage={currentPage} sortBlog={sortBlog} />)}  
                </div>
            </div>
    </>)
 };
export default UserBlogSection;