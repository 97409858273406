import React, { useState, useEffect } from "react";
import { Form, FormControl, Button, InputGroup } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';

const ChangePassword = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmpasswordType, setCnfirmpasswordType] = useState("password");
  const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  
   useEffect(() => {
    document.title = "PCMA || Change Password";
   }, []);
  
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleconfirmPassword = () => {
    if (confirmpasswordType === "password") {
      setCnfirmpasswordType("text");
      return;
    }
    setCnfirmpasswordType("password");
  };

  const { token } = useParams();

  
  const handleSubmit = async (e) => {
    e.preventDefault();






     if (password && !password_confirmation) {
       toast.error("All fields are required");
      } else if (!password) {
       toast.error("Please enter your new password");
     } else if (!password_confirmation) {
       toast.error("Please enter your re-type new password");
     } else {
       if (password !== password_confirmation) {
         toast.error(" New password and re-type new password doesn't match");
       } else {
         if (password.search(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$_#^~!%*?&])[A-Za-z\d@$!%_#^~*?&]{8,}$/) < 0) {
           toast.error(
             'Password must be 8 to 20 characters and one number, one special character and one capital letter.'
           );
         } else {
           try {


            let login_credential = {
              password: password,
              password_confirmation: password_confirmation,
            }
            const publicKey = process.env.REACT_APP_PUBLIC_KEY;
            const encryptor = new JSEncrypt();
            encryptor.setPublicKey(publicKey);
            const windowTime = Date.now();
            let stringify = {
              ...login_credential,
              windowTime: btoa(windowTime)
            }
            var encrypted = encryptor.encrypt(JSON.stringify(stringify));

             const { data } = await axios.post(
               `${process.env.REACT_APP_PCMA_BACKEND}/api/reset-password/${token}`,
               {encrypted}
             );
             if (data?.message === "Your password has been reset."){
               toast.success(data?.message);
              //  navigate("/");
              window.location.href = '/';
             } else {
               toast.error(data?.message);
                navigate("/reset-password");
             }    
           } catch (err) {
             toast.error(err?.response?.data?.message);
             navigate("/reset-password");
           }
         }
       }
     } 
  
  };
  return (
    <>
      <div className="main_login_body  ">
        <div className="left_side d-flex flex-column justify-content-between">
          <div className="upper-part text-center text-lg-start  pe-3">
            <h1 className="first-text ">Unleash the Potential</h1>
            <h4 className="second-text ">Where Innovation Meets Capital</h4>
            <p>
              Discover a Vibrant and Dynamic Network of Exempt Market
              Professionals United by Excellence and Innovation.
            </p>
          </div>
          <div className="dashboard-screen-image d-flex justify-content-end"></div>
        </div>

        <div className=" right_side d-flex align-items-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className=" d-flex align-items-center justify-content-center">
                  <div className="form_register">
                    <Link to="https://pcma1dev.wpenginepowered.com/">
                      <img
                        src="../asset/logo.png"
                        className="img-fluid"
                        alt=""
                        // style={{ height: "80px", width: "150px" }}
                      />
                    </Link>
                    <h1 className="member-heading pt-5">Change Password</h1>
                    <Form className="pt-4 registaer_form">
                      <Form.Group className="newpassword d-flex flex-column justify-content-around py-2">
                        <Form.Label htmlFor="password" className="fw-bolder">
                          New Password
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type={passwordType}
                            name="password"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter Your New Password"
                          />
                          <button
                            type="button"
                            className="bg-transparent border-0 position-absolute eyBtn"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? (
                              <i className="fa-regular fa-eye-slash"></i>
                            ) : (
                              <i className="fa-regular fa-eye"></i>
                            )}
                          </button>
                        </div>
                      </Form.Group>
                      <Form.Group className="newpassword d-flex flex-column justify-content-around py-2">
                        <Form.Label htmlFor="password" className="fw-bolder">
                          Re-type New Password
                          <svg
                            className="ms-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type={confirmpasswordType}
                            name="password"
                            id="password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Enter Your Re-type New Password"
                          />
                          <button
                            type="button"
                            className="bg-transparent border-0 position-absolute eyBtn"
                            onClick={toggleconfirmPassword}
                          >
                            {confirmpasswordType === "password" ? (
                              <i className="fa-regular fa-eye-slash"></i>
                            ) : (
                              <i className="fa-regular fa-eye"></i>
                            )}
                          </button>
                        </div>
                      </Form.Group>

                      <div className="register_login mt-3">
                        <Button type="submit" onClick={handleSubmit}>
                          Confirm Password
                        </Button>
                      </div>
                    </Form>

                    <div className="member  w-100">
                      <p>
                        Already a member? &nbsp;
                        <Link to="/" className="text-decoration-none">
                          <span className="loginagain-pcma">Login PCMA</span>
                        </Link>
                      </p>
                    </div>
                    <div className="note mt-5 w-100">
                      <p>
                        Please note that your member ID is your username. If you
                        don't remember your member ID, please
                        <span>
                          {" "}
                          <Link to="https://pcma1dev.wpenginepowered.com/" className="text-decoration-none">
                            CONTACT US.
                          </Link>{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
