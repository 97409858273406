import axios from "axios";
import React,{useEffect,useState} from "react"
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

const AdminTransaction = () => {
  const id = useParams();
    let loginData = JSON.parse(window.localStorage.getItem("loginAdmin"));
    let token = loginData.Token;

    const [show, setShow] = useState(false);
    const [viewData, setViewData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const convertUnixTimeToCanadaDate = (unixTimestamp, canadaTimeZone) => {
        const utcDate = new Date(unixTimestamp * 1000);
        const canadaDate = new Date(utcDate?.toLocaleString('en-US', { timeZone: canadaTimeZone }));
        
        // const canadaDateString = canadaDate?.toISOString()?.split('T')[0];
        return canadaDate;
      };

useEffect(() => {
        document.title = "PCMA || Transaction History";
    }, []);

    const handleBack = () => {
      navigate(-1);
    };

   const [data, setData] = useState('')

    useEffect(() => {
        handleApi()
    }, [id?.id])

    const handleApi =async()=>{
        setIsLoading(true);
        try{
            const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/customer-subscriptions/${id?.id}
            `,{
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              if(response?.data?.message == "User Not Found"){
                setData('')
              }
              else if(response?.data){
                setData(response?.data) 
              }
            
              setIsLoading(false);
        }catch(error){
          //  console.log("error",error);
           setIsLoading(false);
        }
    }

    const handleDownload =(url)=>{
        window.open(url)
    }
    const handleViewModal=(data)=>{
      setShow(true)
      setViewData([data]);
    }

    const [emailLoader, setEmailLoader] = useState(false);

    const [loadingStates, setLoadingStates] = useState({});


    const handleSendMail = async (itemId, data) => {
      setLoadingStates((prevStates) => ({ ...prevStates, [itemId]: true }));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/single-payment-details`,
          {
            "subscription_id": itemId?.subscription_id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          setLoadingStates((prevStates) => ({ ...prevStates, [itemId]: false }));
          toast.success("Mail sent");
        }
      } catch (error) {
        toast.error(error.response.data.error);
        setLoadingStates((prevStates) => ({ ...prevStates, [itemId]: false }));
      }
    };
    
    const handleCloseModal =()=>{
      setShow(false);
    }



    const downloadCsv = async () => {

      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/export-member-logs/${id?.id}`, {
          params: {
            // page: Number(currentPage),
            // subscription_status: selectedOption?.value,
            // invoice_number: invoiceNo,
            // member_name: name
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        });
    
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'subscriptions-transaction.csv');
        document.body.appendChild(link);
        link.click();
    
        setIsLoading(false);
      } catch (error) {
        // console.log("error:", error);
        setIsLoading(false);
      }
    }

    

    return (
        <>
{isLoading ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <>

            <Row className="g-2" style={{ paddingBottom: "10px" }} >
                <Col lg={3} md={3} sm={12} className="mt-3 pt-4" >
                    <span onClick={handleBack} style={{cursor: "pointer",color: "#0F66B4",fontWeight: "500",}} className="p-1 position-static">
                      <i className="fa-solid fa-arrow-left-long me-2"></i>Go Back
                    </span> 
                
                </Col>
                    
                </Row>
            <div className="row pt-4">
                <div className="col-12"
                >
                    <div style={{ background: "#137CD9", color: "#fff", borderRadius: '5px' }}>
                        <h4 className="mb-0 py-2 px-3">Transaction History</h4>
                    </div>
                </div>
            </div>

            <div className="row g-4 mt-0">
                <div className="col-12 ">
                    <div className=" d-flex align-content-center justify-content-end">
                        <button className="payment_csv_btn"  onClick={downloadCsv} >Export CSV <i className="fa-solid fa-download"></i></button>
                    </div>
                </div>

                <div className="col-12">
                    <div className="subcription_table">
                        <div className="table-responsive firms_card_box">
                        <Table className="w-100">
                                <thead>
                                    {/* <th>
                                        <input type="checkbox" name="" id="" />
                                    </th> */}
                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Invoice Number</th>
                                    {/* <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Type</th> */}
                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Name On Invoice</th>
                                    {/* <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Organization Name</th>
                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Invoice Type</th> */}
                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Invoice Date</th>
                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Date Created</th>
                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Annual Membership Fee</th>
                                    <th className="text-start" style={{ background: "#DDEBF2", color: '#137CD9', fontWeight: "700", fontSize: "17px", borderBottom: " 0" }}>Total Fee</th>
                                    <th className="text-start" style={{ background: "#DDEBF2",color: '#137CD9',fontWeight: "700", fontSize: "17px", borderBottom:" 0"}}>Actions</th>
                                </thead>
                                
                                <tbody>

                                {data?.length ==0 ? 
                                <tr>
                                  <td colSpan={9} className="text-center">User Not Found</td>
                                </tr>
                                :
                                (data?.map((item, i) => (

          <tr key={i}>
              {/* <td> <input type="checkbox" name="" id="" /></td> */}
              <td>{item.invoice_number}</td>
              {/* <td>{item.type === null ? "Invoice":  item.type}</td> */}
              <td>{item?.name_on_invoice}</td>
              {/* <td>Big Kahuna Ltd.</td>
              <td>Dues/Membership</td> */}
              <td> {item.invoice_date}</td>
              <td> {(item.transaction_date)}</td>
              <td>${item?.subtotal?.toFixed(2)}</td>
              <td>${item?.total?.toFixed(2)}</td>
              <td>
                  <div className=" transaction_table_btn d-flex align-items-center justify-content-center">
                      <button>
                      <i className="fa fa-download" aria-hidden="true"  onClick={()=>{handleDownload(item?.invoice_pdf)}}></i>
                      </button>
                      <button>
                      <i className="fa-regular fa-eye" onClick={()=>{handleViewModal(item)}} ></i>
                      </button>
  
  
                      <button data-bs-toggle="tooltip" data-bs-placement="left" title="Send email ">
                                      {emailLoader? (
                                      // <div className="spinner-border" style={{maxWidth: "20px", maxHeight: "20px",color:"#0f66b4"}}></div>
                                      <i className="fa-regular fa-envelope"></i>
                                       ) : (
                                        <i className="fa-regular fa-envelope" onClick={()=>{handleSendMail(item)}}></i>
                                      )}
                                      </button>
  
                  </div>
              </td>
          </tr>
                                  )))
                               }

  
                                  </tbody>

                            </Table>
                        </div>
                    </div>
                </div>
                
            </div>
</>
)}


<Modal centered backdrop="true" keyboard={false} size="lg" show={show} onHide={handleCloseModal} >
            <Modal.Body>
              <div className="form p-3" >
                {viewData && viewData?.map((data, i) => (
                  <div className="row" key={i}>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Customer Name</Form.Label>
                        <Form.Control type="text"
                          value={data.name_on_invoice || ''}
                          //   onChange={handleChanges} 
                          name="networkName" placeholder="Network Name" 
                          disabled />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Invoice Date</Form.Label>
                        <Form.Control type="text"
                          value={data?.invoice_date || ''}
                          //   onChange={handleChanges} 
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Invoice Number</Form.Label>
                        <Form.Control type="text"
                          value={data?.invoice_number || ''}
                          //   onChange={handleChanges} 
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Order Total </Form.Label>
                        <Form.Control type="text"
                          value={data?.amount
                             || ''}
                          //   onChange={handleChanges} 
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Subscription Name</Form.Label>
                        <Form.Control type="text"
                          value={data?.subscription_name || ''}
                          //   onChange={handleChanges} 
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Subscription Status</Form.Label>
                        <Form.Control type="text"
                          value={data?.status|| ''}
                          //   onChange={handleChanges} 
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>

                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Current Period End</Form.Label>
                        <Form.Control type="text"
                          value={data?.current_period_end || ''}
                          //   onChange={handleChanges} 
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>

                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="fw-bolder">Current Period Start</Form.Label>
                        <Form.Control type="text"
                          value={convertUnixTimeToCanadaDate(data?.invoice_date) || ''}
                          //   onChange={handleChanges} 
                          disabled
                          name="networkName" placeholder="Network Name" />
                      </Form.Group>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center align-items-center">

                <button className="subcription_cancel ms-2" onClick={handleCloseModal}>Close</button>

              </div>
            </Modal.Body>
          </Modal>
        </>
    )

}
export default AdminTransaction