import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const CustomerLeftsidebar = (role) => {
  let navigate = useNavigate();

  const location = useLocation();

  const sidebarItems = [
    { icon: 'fa-solid fa fa-user', name: 'Edit  Bio', path: '/settings' },
    { icon: 'fa-solid fa fa-download', name: 'Download Logo', path: '/settings/downloadlogo' },
    { icon: 'fa-solid fa fa-tasks', name: 'Invoice', path: '/settings/invoice' },
    { icon: 'fa-solid fa-file-lines', name: 'Membership', path: '/settings/membership' },
    { icon: 'fa-solid fa fa-tasks', name: 'Networks', path: '/settings/networks' },
    { icon: 'fa-solid fa-file-lines', name: 'Notice', path: '/settings/messaging' },
    { icon: 'fa-solid fa fa-user', name: 'USer Managment', path: '/settings/user-management' },
    { icon: 'fa-solid fa-comment', name: 'Blogs', path: '/settings/blog-management'},
  ];

  return (
    <div className='d-flex flex-column justify-content-start h-100'>

      <nav>
        <ul className='sidebar_items j_setting_sidebar' style={{paddingLeft:'0px'}}>

          <li className='pt-0'>

            <Link
              to='/'
              className={`d-flex align-items-center active`}
            >
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-columns-gap" viewBox="0 0 16 16">
                  <path d="M6 1v3H1V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm14 12v3h-5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM6 8v7H1V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm14-6v7h-5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" />
                </svg>
              </span>
              <span className='navs'> Go To Dashboard</span>
            </Link>
          </li>
          <span className='customer-leftsidebar-heading'>Information & Settings</span>
          <li className='pb-1'>
            <Link
              to={sidebarItems[0].path}
              className={`d-flex align-items-center ${location.pathname === sidebarItems[0].path ? 'active' : ''
                }`}
            >
              <span>
                <i className={sidebarItems[0].icon}></i>{' '}
              </span>
              <span className='navs'>{sidebarItems[0].name}</span>
            </Link>
          </li>
          <li className='pt-1'>
            <Link
              to={sidebarItems[1].path}
              className={`d-flex align-items-center ${location.pathname === sidebarItems[1].path ? 'active' : ''
                }`}
            >
              <span>
                <i className={sidebarItems[1].icon}></i>{' '}
              </span>
              <span className='navs'>{sidebarItems[1].name}</span>
            </Link>
          </li>
          {role?.role === 'primary_user' && (
            <>
              <span className='customer-leftsidebar-heading'>Invoicing & Payment</span>
              <li className='pb-1'>
                <Link
                  to={sidebarItems[2].path}
                  className={`d-flex align-items-center ${location.pathname === sidebarItems[2].path ? 'active' : ''}`}
                >
                  <span>
                    <i className={sidebarItems[2].icon}></i>{' '}
                  </span>
                  <span className='navs'>{sidebarItems[2].name}</span>
                </Link>
              </li>
              <li className='pt-1'>
                <Link
                  to={sidebarItems[3].path}
                  className={`d-flex align-items-center ${location.pathname === sidebarItems[3].path ? 'active' : ''}`}
                >
                  <span>
                    <i className={sidebarItems[3].icon}></i>{' '}
                  </span>
                  <span className='navs'>{sidebarItems[3].name}</span>
                </Link>
              </li>
              </>
          )}
            <>
              <span className='customer-leftsidebar-heading'>Community</span>
              <li className='pb-1'>
                <Link
                  to={sidebarItems[4].path}
                  className={`d-flex align-items-center ${location.pathname === sidebarItems[4].path ? 'active' : ''}`}
                >
                  <span>
                    <i className="fa-solid fa-signal" style={{ fontSize: '17px' }}></i>
                  </span>
                  {' '}
                  <span className='navs'>Networks</span>
                </Link>
              </li>
            </>
          
            
            {role?.role === 'primary_user' && (
            <>
              {/* <li className='pt-1'>
                <Link
                  to='https://pcma1dev.wpenginepowered.com/coming-soon-page/' target='_blank'
                  className={`d-flex align-items-center ${location.pathname === sidebarItems[5].path ? 'active' : ''}`}
                >
                  <span>
                    <i className="fa-solid fa-message"></i>{' '}
                  </span>
                  <span className='navs'>Messaging</span>
                </Link>
              </li> */}
              <span className='customer-leftsidebar-heading'>User Management</span>
              <li className='pt-1'>
                <Link
                  to={sidebarItems[6].path}
                  className={`d-flex align-items-center ${location.pathname === sidebarItems[6].path || location.pathname === '/settings/usermanagement/adduser' ? 'active' : ''}`}
                >
                  <span>
                    <i className="fa-solid fa-message"></i>{' '}
                  </span>
                  <span className='navs'> Sub Users</span>
                </Link>
              </li>
            </>
          )}

          <span className='customer-leftsidebar-heading'>Blog Management</span>
          <li className='pb-1'>
            <Link
              to={sidebarItems[7].path}
              className={`d-flex align-items-center ${location.pathname === sidebarItems[7].path ? 'active' : ''
                }`}
            >
              <span>
                <i className={sidebarItems[7].icon}></i>
              </span>
              <span className='navs'>{sidebarItems[7].name}</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default CustomerLeftsidebar;
