import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {

      console.error("Error caught by ErrorBoundary: ", error, errorInfo);
      console.error = () => {};
  }

  handleRefresh = () => {
    window.location.reload();
  };

  handleGoBack = () => {
    const { location, history } = this.props;
    const pathname = location?.pathname;
    const pathSegments = pathname?.split('/');

    if (pathSegments[1]?.includes("admin")) {
      history?.push("/admin");
    } else {
      history?.push("/");
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-container'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex justify-content-center align-items-center h-100'>
                  <div className='row align-items-center'>
                    <div className='col-lg-6 col-sm-12'>
                      <img src='/asset/something-went-wrong.png' className='img-fluid' alt='' />
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                      <div className='error_contant'>
                        <h1>Something Went Wrong!</h1>
                        <p>Please refresh page to load data.</p>
                        <div className='d-flex align-items-center justify-content-start'>
                          <button className='refresh_btn'>
                            <i className="fa-solid fa-arrow-rotate-right me-2" onClick={this.handleRefresh}></i>Refresh</button>
                          <button className='ms-2 goback_btn' onClick={this.handleGoBack} >Go Back</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
