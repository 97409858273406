import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Modal, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from 'react-hot-toast';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

// import { Country, State } from "country-state-city";
import Message from './../Message';
import { set } from 'lodash';

const RegistrantEdit = ({ show, setShow, token, nrdID, registrantId,onEditDone }) => {
    const [registrantData, setRegistrantData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate()
    const [linkedin, setLinkedin] = useState('');
    const [isReadonly, setIsReadonly] = useState(false);
    const [count, setCount] = useState([0]);
    const [increment, setIncrement] = useState(1);
    const [file, setFile] = useState();
    const [countries, setCountries] = useState();
    const [experiences, setExperiences] = useState([]);
    const [volunteerExperince, setVolunteerExperince] = useState([]);
    const [education, setEducation] = useState([]);
    //  const [registrantRegion, setRegistrantRegion] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [newLanguage, setNewLanguage] = useState('');
    const [registarntCategoryOptions, setRegistarntCategoryOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    // const [newTitle, setNewTitle] = useState('');
    const [numberConnections, setNumberConnections] = useState(0);
    const [numberFollowers, setNumberFollowers] = useState(0);
    const [newSkill, setNewSkill] = useState('');
    const [formData, setFormData] = useState({
        linkedin_url: '',
        registrant_name: '',
        current_job_title: '',
        number_connections: 0,
        number_followers: 0,
        work_email: '',
        personal_email: '',
        phone_number: '',
        languages: [],
        skills: [],
        description: '',
        current_company_name: '',
        headline: '',
        company_url: '',
        registrantResourceUrl:'',
        company_industry: '',
        country:'',
        province:'',
        city:'',
        

    });

    const [isDisabled, setIsDisabled] = useState(false);
    
    const [isDisableds, setIsDisableds] = useState(true);
    const [isDisableds1, setIsDisableds1] = useState(true);

    // const [newRegistrantRegion, setNewRegistrantRegion]=useState({
    //     region:'',
    //     titles:[],
    //     term_and_condition:''
    //  });
    const [editedRegion, setEditedRegion] = useState({
        region: '',
        titles: [],
        term_and_condition: '',
        office_address:'',
    });
    // const [newRegistrantRegion, setNewRegistrantRegion] = useState({
    //     region: '',
    //     titles: [],
    //     term_and_condition: '',
    //     office_address:'',
    // });

    const handleClose = () => {
        setShow(false);
        setFormData({});
        setLinkedin('');
        setFile('');
        setIsReadonly(false);
        setNewExperince({});
        setNewVolunteerExperince({});
        // setNewRegistrantRegion({});
        setEditedRegion({
            region: '',
            titles: [],
            term_and_condition: '',
            office_address:'',
        });

    };
  
    
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    const handleChange = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value?.trimStart();
        setFormData({
            ...formData,
            [name]: trimmedValue,
        });
    };
     
    const handleCity = (e) => {
        // const { label, value } = e;
        setFormData({
            ...formData,
            city: e.label,
        });
    };
     
    // const handleCategoryChange = (newValue) => {
    //     const options = Array?.isArray(newValue) ? newValue : [];
    //     const filteredOptions = options.filter(option => {
    //         const trimmedLabel = option?.label?.trimEnd();
    //         return trimmedLabel === option?.label;
    //     });
    //     const titles = filteredOptions?.map(option => option?.label);
    //     console.log('categories', titles);

       
    // };

    const handleCategoryChange = (newValue) => {     
        const options = Array.isArray(newValue) ? newValue : [];
        const filteredOptions = options.filter(option => {
            const trimmedLabel = option?.label?.trimEnd();
            return trimmedLabel === option?.label;  
        });
        const titles = filteredOptions?.map(option => option?.label);
        setEditedRegion({
            ...editedRegion,
            titles: titles,
        });
        setSelectedOptions(filteredOptions);
    };



    const [countryCode, setCountryCode] = useState("");
    const [resionCountryCode, setResionCountryCode] = useState("IN");
    const [updatedStates,setUpdatedStates] = useState();

    // const updatedStates = Object.values(
    //     State.getStatesOfCountry(countryCode)
    //   ).map((state) => ({
    //     label: state.name,
    //     value: state.id,
    //     ...state,
    //   }));

      const handleKeyOnlyLatter = (e) => {
        const char = String.fromCharCode(e.keyCode || e.which);
        if (!/^[a-zA-Z\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/.test(char)) {
          e.preventDefault();
        }
      };





    

    const [address, setAddress] = useState({
        country: null,
        state: null,
      });





      const [errors, setErrors] = useState({
        registrantName: '',
        linkedInURL: '',
        country:'',
        procience:''
      });
    
    const [newEducation, setNewEducation] = useState({
        school_name: '',
        linkedin_school_url: '',
    });

    const [newExperince, setNewExperince] = useState({
        title: '',
        company_name: '',
        company_description: '',
        location: '',
        date: '',
    });

    const [educationErrors, setEducationErrors] = useState({
        school_name: '',
        linkedin_school_url: '',
    });

    const [experienceErrors, setExperienceErrors] = useState({
        title: '',
        company_name: '',
        company_description: ''
    });

    let newEducationErrors = { ...educationErrors };
    let newExperienceErrors = { ...experienceErrors };


    
  const handleCountryChange = (value) => {
    stateApi(value);
    countryName = value.label;
    formData.country = countryName
    countryValidateField('country', formData.country);
    setCountryCode(value.value);
    setFormData({
      ...formData,
      country: countryName,
    });
    setIsDisableds(false);
    stateValidateField('province', stateName);
    handleAddressChange({ country: value, state: null });
  };


  let newErrors = { ...errors };



  const countryValidateField = (country, value) => {

    //  newErrors = { ...errors };
    if (value == '' || value == undefined || !value) {
      newErrors.country = 'Country is required.';
    } else {
      delete newErrors.country;
    }
  
    setErrors(newErrors);
    RegionCountryValidateField('country',address?.country?.name)
  };
  



  const RegionCountryValidateField = (country, value) => {
    if (country === 'region_country') {

      newErrors.regionCountry = value === '' ? 'Country field is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);

  }

  const handleStateChange = (label) => {
    CityApi(label)
    setFormData({
      ...formData,
      province: label.label,
    });
    stateName = label.label;
    setIsDisableds1(false);

    stateValidateField('province', stateName);
    handleAddressChange({ ...address, state: label });
  };


  const stateValidateField = (fieldName, value) => {
    if (fieldName === 'province') {
      newErrors.province = value === '' ? 'Province field is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);
  }
  const [cityData,setCityData] = useState();
  const CityApi =(value)=>{
    // if (logindata?.token) {
        axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/city`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-Id': value.value, 
            },
        }).then((res) => {
            const options1 = res?.data?.map(name => ({
                value: name._id,
                label: name.name
            }));
            setCityData(options1);
        }).catch((error) => {
            console.error('Error fetching province data:', error);
            toast.error('Error fetching province data');
        });
    // }
}
  const RegionStateValidateField = (fieldName, value) => {
    if (fieldName === 'region_name') {
      newErrors.reginState = value === '' ? 'Province field is required.' : '';
      setErrors(newErrors);
    }
    setErrors(newErrors);
  }

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };
  
  const [regionDatas, setRegionDatas] = useState();
    const [regionData, setRegionData] = useState([]);

    let countryName = "";
    let stateName = "";
    let resionCountryName = "";
    let resionStateName = "";
  
    // const resionUpdatedCountries = countries.filter(country => !excludedCountryNames?.includes(country.name))
    //   .map(country => ({
    //     label: country.name,
    //     value: country.id,
    //     ...country,
    //   }));
   
      const [updatedCountries,setUpdatedCountries] = useState();
      useEffect(() => {
        // if (logindata?.token) {
            axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/country`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                const options = res?.data?.map(province => ({
                    value: province._id,
                    label: province.name
                }));
                setUpdatedCountries(options);
            }).catch((error) => {
                console.error('Error fetching province data:', error);
                toast.error('Error fetching province data');
            });
        // }
    }, [])
  

    const stateApi =(value)=>{
        // if (logindata?.token) {
            axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/state`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Id': value.value, 
                },
            }).then((res) => {
                const options1 = res?.data?.map(name => ({
                    value: name._id,
                    label: name.name
                }));
                setUpdatedStates(options1);
            }).catch((error) => {
                console.error('Error fetching province data:', error);
                toast.error('Error fetching province data');
            });
        // }
    }
    // const updatedCountries = countries.filter(country => !excludedCountryNames?.includes(country.name))
    //   .map(country => ({
    //     label: country.name,
    //     value: country.id,
    //     ...country,
    //   }));









  

    const [newVolunteerExperince, setNewVolunteerExperince] = useState({
        title: '',
        company_name: '',
        company_description: '',
        location: '',
        date: '',
    });


    const [regionButton, setRegionButton] = useState('Add');
    // const [isDisabled, setIsDisabled] = useState(false);
    

    const [registrantRegion, setRegistrantRegion] = useState([]);
    const [editIndex, setEditIndex] = useState(null);

    const [newTitle, setNewTitle] = useState('');
    const [regions, setRegions] = useState(registrantRegion);
    // const addNewRegion = () => {
    //     setRegistrantRegion([...registrantRegion, newRegistrantRegion]);
    //     setNewRegistrantRegion({
    //         region: '',
    //         titles: [],
    //         term_and_condition: '',
    //         office_address:'',
    //     });
    // };
    
    
    const handleDeleteRegistrantRegion = (indexToDelete) => {
        const updatedRegions = registrantRegion.filter((item, index) => index !== indexToDelete);
        setRegistrantRegion(updatedRegions);
            setRegions(updatedRegions);
    };

    const handleEditRegistrantRegion = (index, item) => {
        const formattedOptions = item?.titles.map((title) => ({
            value: title,
            label: title,
        }));
        setSelectedOptions(formattedOptions);
        setRegions(registrantRegion);
        setIsDisabled(true);
        setRegionButton('Update')
        setEditIndex(index);
        setEditedRegion(
            {
                region: item.region,
                titles: item.titles,
                term_and_condition:item.term_and_condition,
                office_address:item.office_address,
            }
        );
    
        // setNewRegistrantRegion({
        //     region: '',
        //     titles: item.titles,
        //     term_and_condition: '',
        //     office_address:''
        // });
        // setRegionButton('Add')
    };




    const handleRegionChange = (selectedOption) => {
        setEditedRegion(prevState => ({ ...prevState, region: selectedOption.label }));
    };

    const handleTitlesChange = (e) => {
        setEditedRegion(prevState => ({ ...prevState, titles: e.target.value.split(', ') }));
    };

    const handleTermAndConditionChange = (e) => {
        setEditedRegion(prevState => ({ ...prevState, term_and_condition: e.target.value }));
    };

    const handleOfficeAddress =(e)=>{
        setEditedRegion(prevState => ({ ...prevState,  office_address: e.target.value }));
    }
    const handleUpdateRegion = () => {
        if (editIndex !== null) {
            const isDuplicate = regions.some(
                (region, index) => region.region === editedRegion.region && index !== editIndex
            );
            if (!isDuplicate) {
                const updatedRegions = [...regions];
                updatedRegions[editIndex] = {
                    ...editedRegion,
                    titles: editedRegion?.titles  
                };
                setRegions(updatedRegions);
                setRegistrantRegion(updatedRegions);
                setEditIndex(null);
                setEditedRegion({
                    region: '',
                    titles: [],
                    term_and_condition: '',
                    office_address:'',
                });
                // setNewRegistrantRegion({
                //     region: '',
                //     titles: [],
                //     term_and_condition: '',
                //     office_address:'',
                // });
            } else {
                toast.error("Region already exists");
                return false;
            }
        } else {
            const isDuplicate = registrantRegion.some(
                (region) => region.region === editedRegion.region
            );
            if (!isDuplicate) {
                if (editedRegion?.region?.length === 0 && editedRegion?.titles?.length === 0) {
                    toast.error("Enter data to update the region");
                    return false;
                } else if (editedRegion?.region?.length === 0) {
                    toast.error("Enter region to update the region");
                    return false;
                } else if (editedRegion?.titles?.length === 0) {
                    toast.error("Enter titles to update the region");
                    return false;
                }
                const updatedEditedRegion = {
                    ...editedRegion,
                    titles: editedRegion?.titles
                };
    
                const updatedRegions = [...registrantRegion, updatedEditedRegion];
                setRegions(updatedRegions);
                setRegistrantRegion(updatedRegions);
                setEditedRegion({
                    region: '',
                    titles: [],
                    term_and_condition: '',
                    office_address:'',
                });
                // setNewRegistrantRegion({
                //     region: '',
                //     titles: [],
                //     term_and_condition: '',
                //     office_address:'',
                // });
            } else {
                toast.error("Region already exists");
                return false;
            }
        }
        setRegionButton('Add');
        setIsDisabled(false);
    };
    



    const handleEducation = (e) => {
        const { name, value } = e.target;
        const filteredValue = value?.replace(emojiRegex, '');
        const trimmedValue = filteredValue?.trimStart();
        setNewEducation({
            ...newEducation,
            [name]: trimmedValue,
        });
        validateEducationField(name, value);
    };

    const handleDeleteEducation = (indexToDelete) => {
        const updatedEducation = education?.filter((item, index) => index !== indexToDelete);
        setEducation(updatedEducation);
        setEducation(updatedEducation);
    };


    const handleExperince = (e) => {
        const { name, value } = e.target;
        const filteredValue = value?.replace(emojiRegex, '');
        const trimmedValue = filteredValue?.trimStart();
        setNewExperince({
            ...newExperince,
            [name]: trimmedValue,
        });
        validateExperienceField(name, value);
    };

    const handleVolExperince = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value?.trimStart();
        setNewVolunteerExperince({
            ...newExperince,
            [name]: trimmedValue,
        });
    };


    // const handleRegistrantRegion = (e) => {
    //     const { name, value } = e.target;
    //     const trimmedValue = value?.trimStart();
    //     setNewRegistrantRegion({
    //         ...newRegistrantRegion,
    //         [name]: trimmedValue,
    //     });
    // };

    // const handleTitle = () => {
    //     if (String(editedRegion?.titles)?.trim() !== '') {
    //         setNewRegistrantRegion((prevState) => ({
    //             ...prevState,
    //             titles: Array.isArray(prevState.titles) ? [...prevState.titles, String(editedRegion?.titles)?.trim()] : [String(editedRegion?.titles)?.trim()]
    //         }));
    //         setEditedRegion({
    //                     region:editedRegion?.region,
    //                     titles: [],
    //                     term_and_condition:editedRegion?.term_and_condition,
    //                     office_address:editedRegion?.office_address,
    //                 });

    //     } else {
    //         setEditedRegion({
    //             titles: [],
    //         });
    //     }
    // };

    const validateEducationField = (name, value) => {
        if (name === 'school_name') {
            newEducationErrors.school_name = value === '' ? 'School name is required.' : '';
        } else if (name === 'linkedin_school_url') {
            let pattern = /^(https?:\/\/)((www|\w\w)\.)?linkedin\.com\/(([\w]{2,3})?|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
            if (value && !pattern.test(value)) {
                newEducationErrors.linkedin_school_url = 'Please enter a valid URL! Example: http://linkedin.com/in/name/';
            } else {
                newEducationErrors.linkedin_school_url = '';
            }
        }
        setEducationErrors(newEducationErrors);
    };

    const validateExperienceField = (fieldName, value) => {
        if (fieldName === 'title') {
            if (value === '') {
                newExperienceErrors.title = 'Experience title is required.';
            } else if (value.length > 100) {
                newExperienceErrors.title = 'Title should not exceed 100 characters.';
            } else {
                newExperienceErrors.title = '';
            }
        } else if (fieldName === 'company_name') {
            if (value === '') {
                newExperienceErrors.company_name = 'Company Name is required.';
            } else if (value.length > 150) {
                newExperienceErrors.company_name = 'Company Name should not exceed 150 characters.';
            } else {
                newExperienceErrors.company_name = '';
            }
        } else if (fieldName === "company_description") {
            if (value && value?.length > 150) {
                newExperienceErrors.company_description = 'Company Description should not exceed 150 characters.';
            } else {
                newExperienceErrors.company_description = '';
            }
        }
        setExperienceErrors(newExperienceErrors);
    };

    const educationvalidateForm = () => {
        for (const name in newEducation) {
            if (newEducation.hasOwnProperty(name)) {
                validateEducationField(name, newEducation[name])
            }
        }
        setEducationErrors(newEducationErrors);
    };

    const experiencevalidateForm = () => {
        for (const name in newExperince) {
            if (newExperince.hasOwnProperty(name)) {
                validateExperienceField(name, newExperince[name])
            }
        }
        setExperienceErrors(newExperienceErrors);
    };

    const addNewEducation = () => {
        educationvalidateForm();
        if (!Object.values(newEducationErrors).some((error) => error !== '')) {
            setEducation([...education, newEducation]);

            setNewEducation({
                school_name: '',
                linkedin_school_url: '',
            });
            setEducationErrors({});
        }
    };

    const addNewExperince = () => {
        experiencevalidateForm();
        if (!Object.values(newExperienceErrors).some((error) => error !== '')) {
            setExperiences([...experiences, newExperince]);
            setNewExperince({
                title: '',
                company_name: '',
                company_description: '',
                location: '',
                date: ''
            });
            setExperienceErrors({});

        }
    };

    // const handleDeleteLanguages = (index) => {
    //     let deletedCategory = formData.languages?.filter((language, i) => i !== index);
    //     setFormData((prevData) => ({
    //     ...prevData,
    //     languages: deletedCategory,
    //     }));
    // };

    useEffect(() => {
        // if (formDisabled === false) {
            axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/registrant/category-filter`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    const categoryOptions = response?.data?.data?.map(category => ({
                        value: category?._id,
                        label: category?.category
                    }));
                    setRegistarntCategoryOptions(categoryOptions);
                }).catch((error) => {
                    if (error?.response?.status == 404) {
                        navigate('/admin/not-found')
                    } else if (error?.response?.data?.messsage === "Unauthozied User") {
                        toast.error(error?.response?.data?.messsage);
                    }
                    console.error('Error fetching firm Categories:', error);
                });
        // }
    }, []);




    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file type
            if (file.type === "image/jpeg" || file.type === "image/png") {
                // Check file size (2MB limit)
                if (file.size <= 2 * 1024 * 1024) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                        const base64String = reader.result;
                        setFile(base64String);
    
                        try {
                            const response = await axios.put(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/registrant/update-profile-picture/${registrantId}`, {
                                profile_image: base64String
                            }, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'application/json',
                                },
                            });
    
                            if(response?.data?.message == "Profile image uploaded successfully."){
                                toast.success(`${response?.data?.message}`)
                                onEditDone();
                            }
                        } catch (error) {
                            console.error("Error updating profile picture", error);
                            toast.error("Error updating profile picture. Please try again.");
                        }
                    };
                    reader.readAsDataURL(file);
                } else {
                    toast.error("File size should not exceed 2MB.");
                }
            } else {
                toast.error("Please select a valid JPG or PNG file.");
            }
        }
    };
    

    // const [regionData, setRegionData] = useState();

    // const countries = Country.getAllCountries();
 


    

    const handleRegistrantData = async () => {
        try {
            if (registrantId) {
                const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/admin/registrant/update/${nrdID}/${registrantId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                const data = response?.data?.Registrant_Information;
                const datas =response?.data?.firm_regions;
                setFormData({
                    linkedin_url: data.linkedin_link || '',
                    name: data.registrant_name || '',
                    current_job_title: data.current_job_title || '',
                    number_connections: data.number_connections || 0,
                    number_followers: data.number_followers || 0,
                    work_email: data.work_email || '',
                    personal_email: data.personal_email || '',
                    phone_number: data.phone_number || '',
                    languages: data.languages || [],
                    skills: data.skills || [],
                    description: data.description || '',
                    current_company_name: data.current_company_name || '',
                    registrantResourceUrl: data.registrant_resource_url || '',
                    headline: data.headline || '',
                    company_url: data.company_url || '',
                    company_industry: data.company_industry || '',
                    country:data.country || '',
                    province:data.province || '',
                    city:data.city || '',
                    location:data.location || '',
                    postalCode:data.postal_code || '',
                });
                setFile(data?.profile_image_url);
                setRegistrantRegion(data?.regions);
                setEducation(data?.education);
                setExperiences(data?.experiences);
                setRegionDatas(datas?.map((da) => ({ value: da?.firm_region, label: da?.firm_region })));
                setSelectedCountry({ value: data?.profile_country, label: data?.profile_country });
            }
        } catch (error) {
            console.log("error:", error)
        }
    }


    useEffect(() => {
        handleRegistrantData()
    }, [registrantId,show])

    const handleRegistrantSubmit = async (e) => {
        e.preventDefault();

        let valid = true;

    // Validation for Registrant Name
    if (formData.registrant_name?.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        registrant_name: 'Registrant Name is required.',
      }));
      valid = false;
      return false;
    }else if(formData.registrant_name?.trim()?.length >=50 ){
        setErrors((prevErrors) => ({
            ...prevErrors,
            registrant_name: 'Max  Registrant Name length 50 characters.',
          }));
          valid = false;
          return false;
    }else if(formData.registrant_name?.trim().length>0){
        valid = true;
    }if(formData.linkedin_url?.trim() === ''){
        setErrors((prevErrors) => ({
            ...prevErrors,
            linkedin_url: 'LinkedIn Link is required.',
          }));
          valid = false;
          return false;
        } if (formData?.registrantResourceUrl?.trim() === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                registrantResourceUrl: '',
            }));
            valid = true;
        } else if (formData.registrantResourceUrl?.trim().length > 0) {
            const pattern = /^https:\/\/info\.securities-administrators\.ca\/nrsmobile\/NRSIndivRegistrationRecord\.aspx\?indivId=\d+$/;

            if (!pattern.test(formData.registrantResourceUrl)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    registrantResourceUrl: 'Please enter a valid link! Example: https://info.securities-administrators.ca/nrsmobile/NRSIndivRegistrationRecord.aspx?indivId=id',
                }));
                valid = false;
                return false;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    registrantResourceUrl: '',
                }));
                valid = true;
            }
        }
    if(formData.linkedin_url?.trim() === ''){
        setErrors((prevErrors) => ({
            ...prevErrors,
            linkedin_url: 'LinkedIn Link is required.',
          }));
          valid = false;
          return false;
    }else if(formData.linkedin_url?.trim().length>0){
        valid = true;
    }

    // if(formData.work_email?.trim() === ''){
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         work_email: 'Work Mail is required.',
    //       }));
    //       valid = false;
    //       return false;
    // }else if(formData.work_email?.trim().length>0){
    //     valid = true;
    // }
    

    if(valid === true){
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/registrant/edit/${nrdID}/${registrantId}`,
                {
                    linkedin_link: formData?.linkedin_url,
                    registrant_name: formData?.registrant_name,
                    current_job_title: formData?.current_job_title,
                    number_connections: Number(formData?.number_connections),
                    number_followers: Number(formData?.number_followers),
                    work_email: formData?.work_email,
                    personal_email: formData?.personal_email,
                    phone_number: formData?.phone_number,
                    company_description: formData?.company_description,
                    languages: formData?.languages,
                    skills: formData?.skills,
                    description: formData?.description,
                    current_company_name: formData?.current_company_name,
                    headline: formData?.headline,
                    company_url: formData?.company_url,
                    company_industry: formData?.company_industry,
                    registrant_resource_url: formData?.registrantResourceUrl,
                    profile_country: selectedCountry?.label,
                    regions: registrantRegion,
                    education: education,
                    experiences: experiences,
                    country: formData?.country,
                    province:formData?.province,
                    city:formData?.city,
                    postal_code:formData?.postalCode,
                    location : formData?.location
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

           if(response?.data?.message === "Registrant details updated successfully"){
            toast.success(`${response?.data?.message}`)
            setShow(false);
            setFormData({});
            setLinkedin('');
            setFile('');
            setIsReadonly(false);
            setNewExperince({});
            setNewVolunteerExperince({});
               // setNewRegistrantRegion({});
               setEditedRegion({});
            onEditDone();
            }
            // Handle the response if needed
        } catch (error) {
            if (error?.response?.data?.message === 'Server Error') {
                navigate("/not-found");
            } else if (error?.message === "Request failed with status code 401") {
                localStorage.removeItem('loginAdmin');
                navigate("/admin/login");
                toast.error('Unauthorized user!');
            }else{
                toast.error(error?.response?.data?.message || "Something went wrong.");
            } 
        }
    }
    };


    const handleLanguages = () => {
        if (newLanguage) {
            setFormData((prevData) => ({
                ...prevData,
                languages: [...prevData?.languages || [], newLanguage],
            }));
            setNewLanguage('')
        } else {
            setNewLanguage('')
        }
    };

    const handleDeleteLanguages = (index) => {
        let deletedCategory = formData.languages?.filter((language, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            languages: deletedCategory,
        }));
    };

    const handleSkills = () => {
        if (newSkill) {
            setFormData((prevData) => ({
                ...prevData,
                skills: [...prevData?.skills || [], newSkill],
            }));
            setNewSkill('')
        } else {
            setNewSkill('')
        }
    };

    const handleDeleteSkills = (index) => {
        let deletedCategory = formData.skills?.filter((language, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            skills: deletedCategory,
        }));
    };

    // useEffect(() => {
    //     const filteredCountries =[];
    //     setCountries(filteredCountries.map(country => ({ value: country.isoCode, label: country.name })));
    // }, []);

    // const handleCountryChange = (selectedOption) => {
    //     setSelectedCountry(selectedOption);
    // };






    // const handleRegionChange = (selectedOption) => {
    //     setRegionData(selectedOption);

    //     setNewRegistrantRegion({
    //         ...newRegistrantRegion,
    //         region: selectedOption.label,
    //     });
    // };


    const handleDelete = (index) => {
        const updatedExperince = [...experiences];
        const deletedExperiences = updatedExperince?.splice(index, 1)[0];
        setExperiences(updatedExperince)
    };

    // const handleDeleteRegion=(index)=>{
    //     let deletedCategory = newRegistrantRegion?.filter((language, i) => i !== index);
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         languages: deletedCategory,
    //     }));
    // }

    // const handleDeleteSkills = (index) => {
    //     let deletedCategory = formData.skills?.filter((language, i) => i !== index);
    //     console.log("deletedCategorydeletedCategory",deletedCategory)
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         skills: deletedCategory,
    //     }));
    // };


    // const handleDeleteRegion = (index) => {
    //     if (Array.isArray(newRegistrantRegion?.titles)) {
    //         let deletedRegion = newRegistrantRegion?.titles.filter((language, i) => i !== index);
    //         setNewRegistrantRegion((prevData) => ({
    //             ...prevData,
    //             titles: deletedRegion,
    //         }));
    //     }
    // };
    
    

    const addNewVolunteerExperince = (newVolunteerExperince) => {
        setVolunteerExperince([...volunteerExperince, newVolunteerExperince]);
    };

    const handleDeleteVolunteerExperince = (index) => {
        const updatedVolExperince = [...volunteerExperince];
        const deletedVolExperiences = updatedVolExperince?.splice(index, 1)[0];
        setVolunteerExperince(updatedVolExperince)
    };

    const handleEditVolunteerExperience = (index) => {
        const experienceToEdit = volunteerExperince[index];
        setNewVolunteerExperince(experienceToEdit);
        const updatedVolExperiences = [...volunteerExperince];
        updatedVolExperiences.splice(index, 1);
        setVolunteerExperince(updatedVolExperiences);
    };
    

    //   const addNewRegion = () => {
    //     setRegistrantRegion([...registrantRegion, newRegistrantRegion]);
    //     };

    //     const handleDeleteRegistrantRegion = (index) => {
    //         const updatedVolExperince = [...registrantRegion];
    //         const deletedVolExperiences = updatedVolExperince?.splice(index, 1)[0];
    //         setRegistrantRegion(updatedVolExperince)
    //   };





    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="true" keyboard={false} size="lg" centered className='add_registrantModal'>
                 <Modal.Header  closeButton className="modal_header">
                 <Modal.Title className="model_title py-2 text-center">

                     Edit Registrant

                 </Modal.Title>
             </Modal.Header>
                <Modal.Body className="modal_body">
                    <Container fluid >
                        <Row>

                            <Col lg={12} md={12}>
                                <div className="mb-4 personal_info d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <mask id="mask0_6055_3896" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <rect width="24" height="24" fill="#D9D9D9"></rect>
                                        </mask>
                                        <g mask="url(#mask0_6055_3896)">
                                            <path d="M12 12C10.625 12 9.44792 11.5104 8.46875 10.5312C7.48958 9.55208 7 8.375 7 7C7 5.625 7.48958 4.44792 8.46875 3.46875C9.44792 2.48958 10.625 2 12 2C13.375 2 14.5521 2.48958 15.5312 3.46875C16.5104 4.44792 17 5.625 17 7C17 8.375 16.5104 9.55208 15.5312 10.5312C14.5521 11.5104 13.375 12 12 12ZM2 22V18.5C2 17.7917 2.18229 17.1406 2.54688 16.5469C2.91146 15.9531 3.39583 15.5 4 15.1875C5.29167 14.5417 6.60417 14.0573 7.9375 13.7344C9.27083 13.4115 10.625 13.25 12 13.25C13.375 13.25 14.7292 13.4115 16.0625 13.7344C17.3958 14.0573 18.7083 14.5417 20 15.1875C20.6042 15.5 21.0885 15.9531 21.4531 16.5469C21.8177 17.1406 22 17.7917 22 18.5V22H2ZM4.5 19.5H19.5V18.5C19.5 18.2708 19.4427 18.0625 19.3281 17.875C19.2135 17.6875 19.0625 17.5417 18.875 17.4375C17.75 16.875 16.6146 16.4531 15.4688 16.1719C14.3229 15.8906 13.1667 15.75 12 15.75C10.8333 15.75 9.67708 15.8906 8.53125 16.1719C7.38542 16.4531 6.25 16.875 5.125 17.4375C4.9375 17.5417 4.78646 17.6875 4.67188 17.875C4.55729 18.0625 4.5 18.2708 4.5 18.5V19.5ZM12 9.5C12.6875 9.5 13.276 9.25521 13.7656 8.76562C14.2552 8.27604 14.5 7.6875 14.5 7C14.5 6.3125 14.2552 5.72396 13.7656 5.23438C13.276 4.74479 12.6875 4.5 12 4.5C11.3125 4.5 10.724 4.74479 10.2344 5.23438C9.74479 5.72396 9.5 6.3125 9.5 7C9.5 7.6875 9.74479 8.27604 10.2344 8.76562C10.724 9.25521 11.3125 9.5 12 9.5Z" fill="#0F66B4"></path>
                                        </g>
                                    </svg>
                                    <p className="fw-bold mx-3 mb-0 ">Personal Information</p>
                                </div>
                            </Col>
                            <Col xxl={3} lg={3} md={6} sm={12}>
                                <div
                                    className="firm_box d-flex justify-content-center align-items-center"
                                    style={{
                                        width: "131px",
                                        height: "131px",
                                        border: " 2px solid rgba(237, 240, 241, 1)",
                                        borderRadius: "8.037px",
                                        backgroundColor: "rgba(237, 240, 241, 1)",
                                    }}
                                >
                                    {file ? (
                                        <img
                                            className="firm_picture img-fluid"
                                            src={file}
                                            alt="logo "
                                            style={{
                                                width: "131px",
                                                height: "131px",
                                                borderRadius: "8.037px",
                                            }}
                                        />
                                    ) : (
                                        <img
                                            className="firm_picture img-fluid"
                                            src="/asset/photo_camera.png"
                                            alt="logo "
                                            style={{
                                                borderRadius: "8.037px",
                                                backgroundColor: "rgba(237, 240, 241, 1)",
                                            }}
                                        />
                                    )}

                                </div>
                            </Col>
                            <Col xxl={5} lg={5} md={6} sm={12}>
                                <div className="profile mt-4">
                                    <p className="text-start fw-bolder mb-0">
                                        Profile Image
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-start  mt-3 ">
                                    <Button className=" text-white btn_notice cursor-pointer">
                                        <div className="position-relative">
                                            <input type="file" className="position-absolute w-100 h-100 opacity-0 cursor-pointer" onChange={handleUpload} />
                                            Upload
                                        </div>
                                    </Button>

                                    {/* <Button className="btn_cancel mx-2" > Cancel</Button> */}
                                </div>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Registrant Name  <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            // className={`
                                            // ${((errorsRegistant.registrant_name) && (formData.registrant_name === '') || (errorsRegistant.registrant_name)) && 'error_active'}
                                            // ${(((errorsRegistant.registrant_name === '')) && (formData.registrant_name)) && 'sucess_active'}`}
                                            type="text"
                                            name="registrant_name"
                                            value={formData?.registrant_name}
                                            placeholder="Enter Registrant Name Here"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}>
                                            {errors.registrant_name}
                                        </span>

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder ">
                                        LinkedIn URL <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>

                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="url"
                                            disabled={isReadonly}
                                            name='linkedin_url'
                                            placeholder="LinkedIn URL "
                                            value={formData?.linkedin_url || ''}
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}>
                                            {errors.linkedin_url}
                                        </span>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder ">
                                        Current Job Title  <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>

                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="text"
                                            // className={`
                                            // ${((errorsRegistant.current_job_title) && (formData.current_job_title === '') || (errorsRegistant.current_job_title)) && 'error_active'}
                                            // ${(((errorsRegistant.current_job_title === '')) && (formData.current_job_title)) && 'sucess_active'}`}
                                            name="current_job_title"
                                            placeholder="Enter Current Job Title Here"
                                            value={formData?.current_job_title || ''}
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}>
                                            {/* {errorsRegistant.current_job_title} */}
                                        </span>
                                    </div>
                                </Form.Group>
                            </Col>





                            <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Country <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Select
                              className={`
                                    ${(((errors.country) && (formData.country === ''))) && 'error_active'}
                                    ${((errors.country === '') && (formData.country)) && 'sucess_active'}`}
                              id="country"
                              name="country"
                              label="country"
                              options={updatedCountries}
                            //   value={formData?.country}
                            value={formData?.country ? { label: formData.country, value: formData.country } : null}
                              onChange={handleCountryChange}

                            />

                            <span style={{ color: "red" }}>
                              {errors.country}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Province <i className="fa-solid fa-asterisk fa_asterisk" ></i>
                          </Form.Label>
                          <div className="input_field">
                            <Select
                              className={`
                                   ${(((errors.province) && (formData.province === '')) || (errors.province)) && 'error_active'}
                                   ${((errors.province === '') && (formData.province)) && 'sucess_active'}`}
                              id="province"
                              name="province"
                              options={updatedStates}
                            //   value={formData?.province}
                            value={formData?.province ? { label: formData.province, value: formData.province} : null}
                              onChange={handleStateChange}
                              isDisabled={isDisableds}
                            />
                            <span style={{ color: "red" }}>
                              {errors.province}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>


                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            City 
                            {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                            {/* <Form.Control
                              className={`
                                 ${((errors.city) && (formData.city === '') || (errors.city)) && 'error_active'}
                                 ${(((errors.city === '')) && (formData.city)) && 'sucess_active'}`}
                              type="text"
                              onKeyPress={handleKeyOnlyLatter}
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="city"
                              placeholder="Enter City"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.city}
                            /> */}
                            <Select
                            className={`
                                ${((errors.city) && (formData.city === '') || (errors.city)) && 'error_active'}
                                ${(((errors.city === '')) && (formData.city)) && 'sucess_active'}`}
                                isDisabled={isDisableds1}
                                value={formData?.city ? { label: formData.city, value: formData.city} : null}
                                name="city"
                                id='city'
                                onChange={handleCity}
                                options={cityData}
                                            />
                            <span style={{ color: "red" }}>
                              {errors.city}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16px", letterSpacing:"0.16px"}}>
                            Location 
                            {/* <i className="fa-solid fa-asterisk fa_asterisk" > */}


                            {/* </i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                   ${((errors.location) && (formData.location === '') || (errors.location)) && 'error_active'}
                                   ${(((errors.location === '')) && (formData.location)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              name="location"
                              placeholder="Enter Location"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.location}
                            />
                            <span style={{ color: "red" }}>
                              {errors.location}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="Name py-2 ">
                          <Form.Label className="form_label" style={{fontWeight:"bold", fontFamily: 'Raleway', fontSize: "16x", letterSpacing:"0.16px"}}>
                            Postal Code
                             {/* <i className="fa-solid fa-asterisk fa_asterisk" ></i> */}
                          </Form.Label>
                          <div className="input_field">
                            <Form.Control
                              className={`
                                  ${((errors.postalCode) && (formData.postalCode === '') || (errors.postalCode)) && 'error_active'}
                                  ${(((errors.postalCode === '')) && (formData.postalCode)) && 'sucess_active'}`}
                              type="text"
                              style={{
                                borderRadius: "5px",
                                border: "1.5px solid #E1DEDE",
                              }}
                              maxLength={10}
                              name="postalCode"
                              placeholder="Enter Postal Code"
                              onChange={(e) => { handleChange(e) }}
                              value={formData.postalCode}
                            />
                            <span style={{ color: "red" }}>
                              {errors.postalCode}
                            </span>
                          </div>
                        </Form.Group>
                      </Col>







                            <Col lg={6} md={6}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder ">
                                        Number Of Connections
                                        <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="number"
                                            name="number_connections"
                                            placeholder="Enter Number OF Connections Here"
                                            value={formData?.number_connections}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder ">
                                        Number Of Followers
                                        <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="number"
                                            name="number_followers"
                                            placeholder="Enter Number OF Followers Here"
                                            value={formData?.number_followers}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder ">
                                        Work Mail

                                        {/* <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg> */}
                                        <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="text"
                                            name="work_email"
                                            placeholder="Enter Current Job Title Here"
                                            value={formData?.work_email || ''}
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}>
                                            {errors.work_email}
                                        </span>
                                        <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder ">
                                        Personal Mail
                                        <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="text"
                                            name="personal_email"
                                            placeholder="Enter Current Job Title Here"
                                            value={formData?.personal_email || ''}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Registrant Phone Number  
                                        {/* <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg> */}
                                         <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            // className={`
                                            // ${((errorsRegistant.registrant_name) && (formData.registrant_name === '') || (errorsRegistant.registrant_name)) && 'error_active'}
                                            // ${(((errorsRegistant.registrant_name === '')) && (formData.registrant_name)) && 'sucess_active'}`}
                                            type="text"
                                            name="phone_number"
                                            value={formData?.phone_number || ''}
                                            placeholder="Enter Registrant Name Here"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}>

                                        </span>

                                    </div>
                                </Form.Group>
                            </Col>
                            {/* <Col lg={6} md={6}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Country
                                         <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Select
                                            id="country"
                                            name="country"
                                            label="country"
                                            placeholder="Select Country"
                                            options={countries}
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                        />

                                        <span style={{ color: "red" }}>
                                     
                                        </span>
                                    </div>
                                </Form.Group>
                            </Col> */}
                            <Col lg={6} md={6} sm={6} >
                                <Form.Label className="form_label fw-bolder ">
                                    Languages
                                    <span className="fw-normal optional text-secondary mx-2">
                                        (Optional)
                                    </span>
                                </Form.Label>
                                <div className=" position-relative">
                                    <input
                                        style={{ opacity: "0.9", width: "100%" }}
                                        className="py-2 form-control"
                                        name='languages'
                                        type="text"
                                        value={newLanguage}
                                        onChange={(e) => setNewLanguage(e.target.value)}
                                    />
                                    <i className="fa-solid fa-circle-plus catgorices_add" onClick={handleLanguages}></i>
                                </div>
                                <Col lg={12} md={12} sm={12}>
                                    <ul className=" d-flex align-items-center flex-wrap mt-2" style={{ gap: '13px' }} >
                                        {formData?.languages?.map((language, index) => (
                                            <>
                                                <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index}
                                                    style={{ padding: '10px', backgroundColor: '#C7E4FF', color: '#1E1E1E', borderRadius: '5px' }}>
                                                    {language}
                                                    <i className="fa-solid fa-xmark ms-2 " style={{ cursor: 'pointer' }} onClick={() => { handleDeleteLanguages(index) }} ></i>
                                                </li>

                                            </>))}
                                    </ul>
                                </Col>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <Form.Label className="form_label fw-bolder ">
                                    Skills
                                    <span className="fw-normal optional text-secondary mx-2">
                                        (Optional)
                                    </span>
                                </Form.Label>
                                <div className=" position-relative">
                                    <input
                                        style={{ opacity: "0.9", width: "100%" }}
                                        className="py-2 form-control"
                                        name='skills'
                                        type="text"
                                        value={newSkill}
                                        onChange={(e) => setNewSkill(e.target.value)}
                                    />
                                    <i className="fa-solid fa-circle-plus catgorices_add" onClick={handleSkills} ></i>
                                </div>
                                <Col lg={12} md={12} sm={12}>
                                    <ul className=" d-flex align-items-center flex-wrap mt-2" style={{ gap: '13px' }}>
                                        <>
                                            {formData?.skills?.map((skill, index) => (
                                                <>
                                                    <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index}
                                                        style={{ padding: '10px', backgroundColor: '#C7E4FF', color: '#1E1E1E', borderRadius: '5px' }}>
                                                        {skill}
                                                        <i className="fa-solid fa-xmark ms-2 " style={{ cursor: 'pointer' }} onClick={() => { handleDeleteSkills(index) }} ></i>
                                                    </li>

                                                </>))}

                                        </>

                                    </ul>
                                </Col>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Description  
                                        {/* <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg> */}
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control as="textarea" placeholder="Enter Description Here" name='description' value={formData?.description || ''} onChange={handleChange} rows={4} className='personal_description' />
                                        <span style={{ color: "red" }}>
                                        </span>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Registarnt CSA Link <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="text"
                                            className={`
                                                    ${((errors.registrantResourceUrl) || (errors.registrantResourceUrl)) && 'error_active'}
                                                    ${(((errors.registrantResourceUrl === '')) && (formData.registrantResourceUrl)) && 'sucess_active'}`}
                                            name="registrantResourceUrl"
                                            placeholder="Enter Registarnt CSA Link "
                                            value={formData?.registrantResourceUrl || ''}                                         
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}> {errors?.registrantResourceUrl}</span>

                                    </div>
                                </Form.Group>
                            </Col>                     
                            {/* education section  */}
                            <Col lg={12} md={12}>
                                <div className="mb-4 personal_info d-flex align-items-center mt-3">
                                    <i className="fa-solid fa-school mt-1" style={{ color: '#5794cb' }}></i>
                                    <p className="fw-bold mx-3 mb-0 ">Educations</p>
                                </div>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        School Name <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            className={`
                                ${((educationErrors.school_name) && (newEducation.school_name === '') || (educationErrors.school_name)) && 'error_active'}
                                ${(((educationErrors.school_name === '')) && (newEducation.school_name)) && 'sucess_active'}`}
                                            type="text"
                                            name="school_name"
                                            value={newEducation?.school_name}
                                            placeholder="Enter Title Here"
                                            onChange={handleEducation}
                                        />
                                        <span style={{ color: "red" }}>
                                            {educationErrors?.school_name}
                                        </span>

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder ">
                                        School LinkedIn URL  <span className="fw-normal optional text-secondary mx-2">(Optional)</span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            className={`
                                ${((educationErrors.linkedin_school_url) && (newEducation.linkedin_school_url === '') || (educationErrors.linkedin_school_url)) && 'error_active'}
                                ${(((educationErrors.linkedin_school_url === '')) && (newEducation.linkedin_school_url)) && 'sucess_active'}`}
                                            type="url"
                                            name='linkedin_school_url'
                                            placeholder="Enter School LinkedIn URL "
                                            value={newEducation?.linkedin_school_url}
                                            onChange={handleEducation}
                                        />
                                        <span style={{ color: "red" }}> {educationErrors?.linkedin_school_url}</span>
                                    </div>
                                </Form.Group>
                                <Button className='mt-2' onClick={addNewEducation}  > Add Education</Button>  
                            </Col>

                            {education?.length > 0 &&
                                <Col sm={12}>
                                    <div className=" table-responsive mt-2">
                                        <table className=" table table-striped w-100">
                                            <thead>
                                                <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>School Name </th>
                                                <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>School LinkedIn URL </th>
                                                <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Delete</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    education?.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.school_name}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.linkedin_school_url}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}><i className="fa-solid fa-trash" style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteRegistrantRegion(index, item)}></i></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            } 
                            {/* Experiences        */}
                            <Col lg={12} md={12}>
                                <div className="mb-4 personal_info d-flex align-items-center mt-3">
                                    <i className="fa-solid fa-hand-holding-heart" style={{ color: '#5794cb' }}></i>
                                    <p className="fw-bold mx-3 mb-0 "> Experiences</p>
                                </div>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Title <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            className={`
                                ${((experienceErrors.title) && (newExperince.title === '') || (experienceErrors.title)) && 'error_active'}
                                ${(((experienceErrors.title === '')) && (newExperince.title)) && 'sucess_active'}`}
                                            type="text"
                                            name="title"
                                            value={newExperince?.title}
                                            placeholder="Enter Title Here"

                                            onChange={handleExperince}
                                        />
                                        <span style={{ color: "red" }}>
                                            {newExperienceErrors.title}
                                        </span>

                                    </div>
                                </Form.Group>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Company Name <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            className={`
                                    ${((experienceErrors.company_name) && (newExperince.company_name === '') || (experienceErrors.company_name)) && 'error_active'}
                                    ${(((experienceErrors.company_name === '')) && (newExperince.company_name)) && 'sucess_active'}`}
                                            type="text"
                                            name="company_name"
                                            value={newExperince?.company_name}
                                            placeholder="Enter Company Name Here"

                                            onChange={handleExperince}
                                        />
                                        <span style={{ color: "red" }}>
                                            {newExperienceErrors.company_name}
                                        </span>

                                    </div>
                                </Form.Group>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Description
                                        <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control as="textarea"
                                            // personal_description
                                            className={`personal_description
                                                    ${((experienceErrors.company_description) && (newExperince.company_description === '') || (experienceErrors.company_description)) && 'error_active'}
                                                    ${(((experienceErrors.company_description === '')) && (newExperince.company_description)) && 'sucess_active'}`}
                                            placeholder="Enter Company Description Here"

                                            name='company_description'
                                            value={newExperince?.company_description}
                                            onChange={handleExperince}
                                            rows={4}
                                        />
                                        <span style={{ color: "red" }}>
                                            {newExperienceErrors.company_description}
                                        </span>
                                    </div>
                                </Form.Group>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Location <span className="fw-normal optional text-secondary mx-2">
                                            (Optional)
                                        </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="text"
                                            name="location"
                                            value={newExperince?.location}
                                            placeholder="Enter Title Here"

                                            onChange={handleExperince}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Date <span className="fw-normal optional text-secondary mx-2">(Optional) </span>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            type="text"
                                            name="date"

                                            value={newExperince?.date}
                                            placeholder="Enter Start Date Here  Example : Start Date - End Date"
                                            onChange={handleExperince}
                                        />
                                    </div>
                                </Form.Group>

                                <Button className='mt-2' onClick={addNewExperince} > Add Experiences</Button>
                            </Col>
                            {experiences?.length > 0 &&
                                <Col sm={12}>
                                    <div className=" table-responsive mt-2">
                                        <table className=" table table-striped w-100">
                                            <thead>
                                                <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Title </th>
                                                <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Company Name  </th>
                                                <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Description </th>
                                                <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Location </th>
                                                <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Date </th>
                                                <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Delete</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    experiences?.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="px-3" style={{ fontSize: '15px' }}> {item?.title}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.company_name}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.company_description}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.location}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.date}</td>
                                                                {/* <td className="text-center px-3" style={{ fontSize: '15px' }}><i className="fa-solid fa-trash" style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteExperinces(index, item)}></i></td> */}
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            }

                            {/* Experiences        */}

                         
                            <Col lg={12} md={12} className='mt-3'>
                                <div className='d-flex align-items-center personal_info'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                        <path d="M2.00391 15.0039V17.0039H18.0039V15.0039H2.00391ZM5.00391 4.00391V2.00391C5.00391 1.45391 5.19974 0.983073 5.59141 0.591406C5.98307 0.19974 6.45391 0.00390625 7.00391 0.00390625H13.0039C13.5539 0.00390625 14.0247 0.19974 14.4164 0.591406C14.8081 0.983073 15.0039 1.45391 15.0039 2.00391V4.00391H18.0039C18.5539 4.00391 19.0247 4.19974 19.4164 4.59141C19.8081 4.98307 20.0039 5.45391 20.0039 6.00391V17.0039C20.0039 17.5539 19.8081 18.0247 19.4164 18.4164C19.0247 18.8081 18.5539 19.0039 18.0039 19.0039H2.00391C1.45391 19.0039 0.983073 18.8081 0.591406 18.4164C0.19974 18.0247 0.00390625 17.5539 0.00390625 17.0039V6.00391C0.00390625 5.45391 0.19974 4.98307 0.591406 4.59141C0.983073 4.19974 1.45391 4.00391 2.00391 4.00391H5.00391ZM2.00391 12.0039H18.0039V6.00391H15.0039V8.00391H13.0039V6.00391H7.00391V8.00391H5.00391V6.00391H2.00391V12.0039ZM7.00391 4.00391H13.0039V2.00391H7.00391V4.00391Z" fill="#0F66B4" />
                                    </svg>
                                    <p className="fw-bold mx-3 mb-0">Company Information</p>
                                </div>
                            </Col>
                            <Col lg={12} md={12} >
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Headline 
                                        {/* <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg> */}
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control as="textarea" placeholder='Enter Company Headline Here' type='text' name='headline' rows={4} value={formData?.headline} onChange={handleChange} />
                                        <span style={{ color: "red" }}>
                                            {/* {errorsRegistant.registrant_name} */}
                                        </span>

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} >
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Current Company Name  <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            // className={`
                                            // ${((errorsRegistant.registrant_name) && (formData.registrant_name === '') || (errorsRegistant.registrant_name)) && 'error_active'}
                                            // ${(((errorsRegistant.registrant_name === '')) && (formData.registrant_name)) && 'sucess_active'}`}
                                            type="url"
                                            name="current_company_name"
                                            value={formData?.current_company_name || ''}
                                            placeholder="Enter Current Company Name Here"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}>
                                            {/* {errorsRegistant.registrant_name} */}
                                        </span>

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} >
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Company LinkedIn Url <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control

                                            type="text"
                                            name="company_url"
                                            value={formData?.company_url || ''}
                                            placeholder="Enter Current Company Name Here"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}>
                                            {/* {errorsRegistant.registrant_name} */}
                                        </span>

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} >
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Company Industry <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            // className={`
                                            // ${((errorsRegistant.registrant_name) && (formData.registrant_name === '') || (errorsRegistant.registrant_name)) && 'error_active'}
                                            // ${(((errorsRegistant.registrant_name === '')) && (formData.registrant_name)) && 'sucess_active'}`}
                                            type="text"
                                            name="company_industry"
                                            value={formData?.company_industry || ''}
                                            placeholder="Enter Current  Company Industry Here"
                                            onChange={handleChange}
                                        />
                                        <span style={{ color: "red" }}>
                                            {/* {errorsRegistant.registrant_name} */}
                                        </span>

                                    </div>
                                </Form.Group>
                            </Col>
                           

{/* 
                            <Col lg={12} md={12}>
                                <div className="mb-4 personal_info d-flex align-items-center mt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <mask id="mask0_6055_3896" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                            <rect width="24" height="24" fill="#D9D9D9"></rect>
                                        </mask>
                                        <g mask="url(#mask0_6055_3896)">
                                            <path d="M12 12C10.625 12 9.44792 11.5104 8.46875 10.5312C7.48958 9.55208 7 8.375 7 7C7 5.625 7.48958 4.44792 8.46875 3.46875C9.44792 2.48958 10.625 2 12 2C13.375 2 14.5521 2.48958 15.5312 3.46875C16.5104 4.44792 17 5.625 17 7C17 8.375 16.5104 9.55208 15.5312 10.5312C14.5521 11.5104 13.375 12 12 12ZM2 22V18.5C2 17.7917 2.18229 17.1406 2.54688 16.5469C2.91146 15.9531 3.39583 15.5 4 15.1875C5.29167 14.5417 6.60417 14.0573 7.9375 13.7344C9.27083 13.4115 10.625 13.25 12 13.25C13.375 13.25 14.7292 13.4115 16.0625 13.7344C17.3958 14.0573 18.7083 14.5417 20 15.1875C20.6042 15.5 21.0885 15.9531 21.4531 16.5469C21.8177 17.1406 22 17.7917 22 18.5V22H2ZM4.5 19.5H19.5V18.5C19.5 18.2708 19.4427 18.0625 19.3281 17.875C19.2135 17.6875 19.0625 17.5417 18.875 17.4375C17.75 16.875 16.6146 16.4531 15.4688 16.1719C14.3229 15.8906 13.1667 15.75 12 15.75C10.8333 15.75 9.67708 15.8906 8.53125 16.1719C7.38542 16.4531 6.25 16.875 5.125 17.4375C4.9375 17.5417 4.78646 17.6875 4.67188 17.875C4.55729 18.0625 4.5 18.2708 4.5 18.5V19.5ZM12 9.5C12.6875 9.5 13.276 9.25521 13.7656 8.76562C14.2552 8.27604 14.5 7.6875 14.5 7C14.5 6.3125 14.2552 5.72396 13.7656 5.23438C13.276 4.74479 12.6875 4.5 12 4.5C11.3125 4.5 10.724 4.74479 10.2344 5.23438C9.74479 5.72396 9.5 6.3125 9.5 7C9.5 7.6875 9.74479 8.27604 10.2344 8.76562C10.724 9.25521 11.3125 9.5 12 9.5Z" fill="#0F66B4"></path>
                                        </g>
                                    </svg>
                                    <p className="fw-bold mx-3 mb-0 "> Volunteer Experinces (optional)</p>
                                </div>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Title <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            
                                            type="text"
                                            name="title"
                                            value={newVolunteerExperince?.title}
                                            placeholder="Enter Title Here"
                                            onChange={handleVolExperince}
                                            disabled
                                        />
                                        <span style={{ color: "red" }}>
                                           
                                        </span>

                                    </div>
                                </Form.Group>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Company Name <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                            
                                            type="text"
                                            name="company_name"
                                            value={newVolunteerExperince?.company_name}
                                            placeholder="Enter Company Name Here"
                                            onChange={handleVolExperince}
                                            disabled
                                        />
                                        <span style={{ color: "red" }}>
                                           
                                        </span>

                                    </div>
                                </Form.Group>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Description  <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control 
                                        as="textarea" 
                                        placeholder="Enter Company Description Here"
                                         name='company_description'
                                          value={newVolunteerExperince?.company_description} 
                                          onChange={handleVolExperince}
                                           rows={4}
                                            className='personal_description'
                                            disabled />
                                        <span style={{ color: "red" }}>
                                        </span>
                                    </div>
                                </Form.Group>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Location <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                           
                                            type="text"
                                            name="location"
                                            value={newVolunteerExperince?.location}
                                            placeholder="Enter Title Here"
                                            onChange={handleVolExperince}
                                            disabled
                                        />
                                        <span style={{ color: "red" }}>
                                          
                                        </span>

                                    </div>
                                </Form.Group>
                                <Form.Group className="Name py-2">
                                    <Form.Label className="form_label fw-bolder">
                                        Date <svg
                                            className="ms-1 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                                fill="#F92828"
                                            />
                                        </svg>
                                    </Form.Label>
                                    <div className="input_field">
                                        <Form.Control
                                           
                                            type="date"
                                            name="date"
                                            value={newVolunteerExperince?.date}
                                            placeholder="Enter Date Here"
                                            onChange={handleVolExperince}
                                            max={new Date().toISOString().split("T")[0]}
                                            disabled
                                        />
                                        <span style={{ color: "red" }}>
                                           
                                        </span>

                                    </div>
                                </Form.Group>
                                <Button className='btn_add mt-2' onClick={addNewVolunteerExperince} disabled > Add Experiences</Button>
                            </Col>

                            {volunteerExperince?.length > 0 &&
                                <Col sm={12}>
                                    <div className=" table-responsive mt-2">
                                        <table className=" table table-striped w-100">
                                            <thead>
                                                <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Title </th>
                                                <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Company Name  </th>
                                                <th className="px-3 text-center" style={{ minWidth: '150px', fontSize: '15px' }}>Description </th>
                                                <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Location </th>
                                                <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Date </th>
                                                <th className="px-3 text-center" style={{ minWidth: '100px', fontSize: '15px' }}>Delete</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    volunteerExperince?.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="px-3" style={{ fontSize: '15px' }}> {item?.title}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.company_name}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.company_description}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.location}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>{item?.date}</td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}>
                                                <i className="fa-solid fa-edit" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleEditVolunteerExperience(index)}></i>
                                                                </td>
                                                                <td className="text-center px-3" style={{ fontSize: '15px' }}><i className="fa-solid fa-trash" style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteRegistrantRegion(index, item)}></i></td>
                                                                
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            } */}

                            {/* region select */}

                            {/* <Col lg={12} md={12}> */}
                            {/* <Form.Group className="Name py-2">
                <Form.Label className="form_label fw-bolder">
                    Region <i className="fa-solid fa-asterisk fa_asterisk"></i>
                </Form.Label>
                <div className="input_field">
                    <Select
                        id="region"
                        name="region"
                        placeholder="Select Region"
                        options={regionDatas}
                        value={regionData}
                        onChange={handleRegionChange}
                    />
                </div>
            </Form.Group>
        </Col>
        <Col lg={12} md={12}>
            <Form.Group className="Name py-2">
                <Form.Label className="form_label fw-bolder">
                    Title <svg
                    className="ms-1 mb-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    >
                    </svg>
                </Form.Label>
                <div className="input_field d-flex align-items-center gap-2">
                    <Form.Control
                        type="text"
                        name="titles"
                        value={newTitle}
                        placeholder="Enter Title Here"
                        onChange={(e) => setNewTitle(e.target.value)}
                    />
                    <i className="fa-solid fa-circle-plus" onClick={handleTitle}></i>
                    <span style={{ color: "red" }}>
                    </span>
                </div>
            </Form.Group>
        </Col>
        <Col lg={12} md={12}>

            <Form.Group className="Name py-2">
                <Form.Label className="form_label fw-bolder">
                    Term and Condition <svg
                    className="ms-1 mb-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    >
                    </svg>
                </Form.Label>
                <div className="input_field">
                    <Form.Control as="textarea"
                        placeholder="Enter Term and Condition"
                        name='term_and_condition'
                        value={newRegistrantRegion?.term_and_condition}
                        onChange={handleRegistrantRegion}
                        rows={4}
                        className='term_and_condition'
                    />
                    <span style={{ color: "red" }}> 
                    </span>
                </div>
            </Form.Group>
        </Col>
        <Button className='btn_add mt-2' onClick={addNewRegion}>Add Region</Button> */}

<Col lg={12} md={12}>
                                <div className="mb-4 personal_info d-flex align-items-center mt-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                        <path d="M2.00391 15.0039V17.0039H18.0039V15.0039H2.00391ZM5.00391 4.00391V2.00391C5.00391 1.45391 5.19974 0.983073 5.59141 0.591406C5.98307 0.19974 6.45391 0.00390625 7.00391 0.00390625H13.0039C13.5539 0.00390625 14.0247 0.19974 14.4164 0.591406C14.8081 0.983073 15.0039 1.45391 15.0039 2.00391V4.00391H18.0039C18.5539 4.00391 19.0247 4.19974 19.4164 4.59141C19.8081 4.98307 20.0039 5.45391 20.0039 6.00391V17.0039C20.0039 17.5539 19.8081 18.0247 19.4164 18.4164C19.0247 18.8081 18.5539 19.0039 18.0039 19.0039H2.00391C1.45391 19.0039 0.983073 18.8081 0.591406 18.4164C0.19974 18.0247 0.00390625 17.5539 0.00390625 17.0039V6.00391C0.00390625 5.45391 0.19974 4.98307 0.591406 4.59141C0.983073 4.19974 1.45391 4.00391 2.00391 4.00391H5.00391ZM2.00391 12.0039H18.0039V6.00391H15.0039V8.00391H13.0039V6.00391H7.00391V8.00391H5.00391V6.00391H2.00391V12.0039ZM7.00391 4.00391H13.0039V2.00391H7.00391V4.00391Z" fill="#0F66B4" />
                                    </svg>
                                    <p className="fw-bold mx-3 mb-0 "> Region</p>
                                </div>
                            </Col>


<Col lg={12} md={12}>
                <Form.Group className="Name py-2">
                    <Form.Label className="form_label fw-bolder">
                        Region <i className="fa-solid fa-asterisk fa_asterisk"></i>
                    </Form.Label>
                    <div className="input_field">
                        <Select
                            id="region"
                            name="region"
                            placeholder="Select Region"
                            options={regionDatas}
                            value={{ label: editedRegion.region, value: editedRegion.region }}
                            onChange={handleRegionChange}
                        />
                    </div>
                </Form.Group>
            </Col>
            <Col lg={12} md={12}>
                <Form.Group className="Name py-2">
                    <Form.Label className="form_label fw-bolder">
                        Category <i className="fa-solid fa-asterisk fa_asterisk"></i>
                    </Form.Label>
                        <CreatableSelect
                            isMulti
                            options={registarntCategoryOptions}
                            value={selectedOptions}
                            onChange={handleCategoryChange}
                            placeholder="Select  Categories "
                            noOptionsMessage={() => 'No Category Available'}
                            formatCreateLabel={(inputValue) => `Create "${inputValue}"`}

                        />
                    
                        {/* <Form.Control
                            type="text"
                            name="titles"
                            value={editedRegion.titles || ''}
                            placeholder="Enter Title Here"
                            onChange={handleTitlesChange}
                            onInput={(e) => {
                                if (e.target.value.length > 30) {
                                  e.target.value = e.target.value.slice(0, 30);
                                }
                              }}
                        />
                        <i className="fa-solid fa-circle-plus" onClick={handleTitle}></i>
                        <span style={{ color: "red" }}>
                        </span> */}
                    
                </Form.Group>

                {/* <Col lg={12} md={12} sm={12}>
                                    <ul className=" d-flex align-items-center flex-wrap mt-2" style={{ gap: '13px' }} >
                                        {newRegistrantRegion.titles?.map((language, index) => (
                                            <>
                                                <li className=" position-relative d-flex align-items-center justify-content-between flex-nowrap" key={index}
                                                    style={{ padding: '10px', backgroundColor: '#C7E4FF', color: '#1E1E1E', borderRadius: '5px' }}>
                                                    {language}
                                                    <i className="fa-solid fa-xmark ms-2 " style={{ cursor: 'pointer' }} onClick={() => { handleDeleteRegion(index) }} ></i>
                                                </li>
                                         
                                            </> ))} 
                                        </ul>
                </Col>     */}
            </Col>
            <Col lg={12} md={12}>
                <Form.Group className="Name py-2">
                    <Form.Label className="form_label fw-bolder">
                    Registrant terms and conditions <svg
                            className="ms-1 mb-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                        >
                            
                          
                        </svg>
                    </Form.Label>
                    <div className="input_field">
                        <Form.Control as="textarea"
                            placeholder="Enter Term and Condition"
                            name='term_and_condition'
                            value={editedRegion.term_and_condition}
                            onChange={handleTermAndConditionChange}
                            rows={4}
                            className='term_and_condition'
                        />
                        <span style={{ color: "red" }}>
                            {/* Validation error message */}
                        </span>
                    </div>
                </Form.Group>
                
                            </Col>
                <Button className='btn_add mt-2' onClick={handleUpdateRegion}>{regionButton} Region</Button>
            {/* <Col lg={12} md={12}>
                <Form.Group className="Name py-2">
                    <Form.Label className="form_label fw-bolder">
                        Office Address <svg
                            className="ms-1 mb-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                        >
                            
                        </svg>
                    </Form.Label>
                    <div className="input_field">
                        <Form.Control
                         type='text'
                            placeholder="Enter Office Address"
                            name='office_address'
                            value={editedRegion.office_address}
                            onChange={handleOfficeAddress}
                          
                            className='office_address'
                        />
                        <span style={{ color: "red" }}>
                            
                        </span>
                    </div>
                </Form.Group>
               
            </Col> */}
            {registrantRegion?.length>0 ?
           
                           
                            <Col lg={12} md={12}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Region</th>
                                            <th>Title</th>
                                            <th>Term and Condition</th>
                                            {/* <th>Office Address</th> */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registrantRegion?.map((item, index) => (
                                            <tr key={index} >
                                                <td>{item?.region}</td>
                                                <td>{item?.titles?.join(', ')}</td>
                                                <td>{item?.term_and_condition}</td>
                                                {/* <td>{item?.office_address}</td> */}
                                                <td>
                                                    <i className="fa-solid fa-edit" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleEditRegistrantRegion(index, item)}></i>
                                                    <i className="fa-solid fa-trash" style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }} onClick={() => handleDeleteRegistrantRegion(index)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Col>
                             :""}


                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-start border-0">
                    <Button className="btn-primary btn_notice" onClick={handleRegistrantSubmit} disabled={isDisabled}>
                        {/* onClick={addRegistrant}     */}
                        Save
                    </Button>
                    <Button className="btn_cancel" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};
export default RegistrantEdit;

