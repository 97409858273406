import React, { useState,useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";

const UserInvoice = () => {
  let token;
  let id;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userData = JSON.parse(window.localStorage.getItem("login"));
  const [noData, setNoData] = useState('');

  const navigate = useNavigate();
  useEffect(() => { 
    redirectToLogin();    
    document.title = "PCMA || Member Invoices";
  invoiceApi(); },
  []);


  const redirectToLogin = () => {
     token = null;
     id = null;
    if (userData) {token = userData.token; id = userData?.user_data?._id;}
    if (token === null) {navigate('/login');}
  };
  
const convertUnixTimeToCanadaDate = (unixTimestamp, canadaTimeZone) => {
  const utcDate = new Date(unixTimestamp * 1000);
  const canadaDate = new Date(utcDate.toLocaleString('en-US', { timeZone: canadaTimeZone }));
  const canadaDateString = canadaDate.toISOString().split('T')[0];
  return canadaDateString;
};

  const handleViewBillButton = (data) => {
    window.open(data,'_blank');
  };

  const invoiceApi = async () => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/invoice-download`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setData(response?.data);
      setIsLoading(false);
      setNoData('')
  
    } catch (error) {
      setIsLoading(false);
      if (error?.message === "Request failed with status code 401") {
        localStorage.removeItem("login");
        navigate("/");
      }
      setData([])
      setNoData(error?.response?.data?.message)
    }
  };
  
  return (
    <Container fluid className="pt-4">
      <div className=" j_account_information" style={{ background: "#137CD9" }}>
        <span className="px-3 d-flex align-items-center" style={{ color: "white",fontSize:'20px' }}>
          Invoices
        </span>
      </div>

      <div className="g-2 row" style={{ paddingBottom: "10px" }}>
        <div
         
          className=" col-10 py-3 d-flex justify-content-start align-items-center"
        >
          <p>In this section, you can manage all your invoicing needs with ease. You can - access a complete list of your invoices,
             allowing you to keep track of all your billing activities, and download the invoices.</p>
          {/* <span style={{minWidth:'130px'}}>Filter by status:</span>
          <div style={{ width: "100%" }}>
            <Select
              id="status"
              name="status"
              label="status"
              // options={updatedCountries}
              // value={countydefaltValue}
              // onChange={handleCountryChange}
            />
          </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive" style={{borderRadius:'6px'}}>
          <table className="userinvoice_table" style={{ width: "100%" }}>
            <thead style={{ background: "blue" }}>
              <tr className="table-heading-row">
                <th width="140px" className="py-2">Order Date</th>
                <th  width="200px">Status</th>
                <th  width="200px">Name on Invoice</th>
                <th  width="200px">Invoice Type</th>
                <th  width="140px">Annual Membership Fee</th>
                <th width="140px">Total Fee </th>
                <th width="140px" className="text-center">Action</th>
              </tr>
            </thead>
            {isLoading ? (
  <tbody>
    <tr>
      <td colSpan={7}>
        <div className="d-flex align-items-center justify-content-center">
          <BallTriangle height={100} width={100} color="#0f66b4" visible={true} />
        </div>
      </td>
    </tr>
  </tbody>
) : noData?.length>0 ? (
  <tbody>
    <tr>
      <td colSpan={7}>
        <div className="d-flex align-items-center justify-content-center">
          {noData}
        </div>
      </td>
    </tr>
  </tbody>
) : (
            <tbody>
              {data?.map((item) => (
                <tr key={item.id}>
                  {/* <td>   <input type="checkbox" name="" id="" /></td> */}
                  <td>{item.period_start}</td>
                  <td>{item.billing_status.charAt(0).toUpperCase() + item.billing_status.slice(1).toLowerCase()}</td>
                  <td>{item.customer_name_in_invoice}</td>
                  <td>{item.invoice_type.charAt(0).toUpperCase() + item.invoice_type.slice(1).toLowerCase()}</td>
                  <td><p className="p-0 mb-0" style={{whiteSpace: 'nowrap'}}>$ {item.total_amount?.toFixed(2)}</p></td>
                  <td><p className="p-0 mb-0" style={{whiteSpace: 'nowrap'}}>$ {item.paid_amount?.toFixed(2)}</p></td>
                  <td style={{ border: "0px", whiteSpace:"wrap", display:'flex',}} onClick={() => {  handleViewBillButton(item.invoice_download);  }} className="d-flex justify-content-center" >
                    {item.billing_status === "paid" ?
                     <>
                    <span  style={{border: "0px", whiteSpace:"wrap", background:'#F8FAFC',columnGap: '5px',}} >
                     <i className="fa fa-download" style={{color:'#137CD9', cursor:'pointer'}}  aria-hidden="true"></i>
                    {"     "} 
                  </span>
                  </>
                     :
                     <button
                     style={{
                       border: "0px",
                       width:'50%'
                     }}
                   >
                     {item.billing_status}
                   </button>
                     }
                    
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          </table>
          </div>
        </div>

         {/* <div className="col-lg-10  py-3">
          <Button
            style={{
              borderRadius: "4px",
              border: "0px",
              background: "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)",
              padding: '5px 30px',
            }}
          >
            PAY Selected Invoice
          </Button>
        </div> */}
      </div>
    </Container>
  );
};

export default UserInvoice;
