import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchEvent = createAsyncThunk('fetchEvent', async (currentPage) => {
    try {
        const response = await fetch(`https://pcma1dev.wpenginepowered.com/wp-json/event/v1/events`);
      return response.json();
    } catch (error) {
      console.error("Error fetching category:", error);
      throw error; 
    }
});
  
const eventSlice = createSlice({
    name:'events',
    initialState:{
        isLoading:false,
        data:[],
        isError:false,
    },
    extraReducers : (builder) => {
        builder.addCase(fetchEvent.pending, (state,action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchEvent.fulfilled, (state,action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchEvent.rejected, (state,action) => {
          state.isError=true;
   
        });

    }
});
export default eventSlice.reducer;