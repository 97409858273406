import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from '../../components/navbar/Navbar';
import RegistrantCard from "../../components/registrantDashboard/RegistrantCard";
import RegistrantMap from "../../components/registrantDashboard/RegistrantMap";
import { Container, Row, Col, } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from "react-hot-toast";
import { BallTriangle } from 'react-loader-spinner';
import CustomPagination from "../../components/pagination/CustomPagination";
import Select from 'react-select';
import { GoogleMap, useLoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from '../../redux/slice/registrantNearmeLocation'
const center = {
    lat: 43.6534817,
    lng: -79.3839366,
};

const RegistrantNearme = () => {
    let token;
    const render = useRef(0);
    const navigate = useNavigate()
    const logindata = JSON.parse(window.localStorage.getItem("login"));

    const [isDownloading, setIsDownloading] = useState(false);


    let localUserId;

    const dispatch = useDispatch();
    const updatedAddress = useSelector((state) => state?.registrantnearmeaddress?.address);
    const [data, setData] = useState([]);
    const [registrantPage, setRegistrantPage] = useState(1);
    const [goToPage, setGoTOPage] = useState(registrantPage);
    const [search, setSearch] = useState(updatedAddress || 'toronto');
    const [kilometer, setKilometer] = useState('50');

    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [onfocas, placeholderDataHide] = useState(false);
    const [gotopag, setGotopag] = useState(false);
    const [registrantBookmarkData, setRegistrantBookmarkData] = useState([]);
    const blockInvalidChar = e => ['e', 'E', '+', '-']?.includes(e.key) && e.preventDefault();

    useEffect(() => {
        redirectToLogin();
    },);


    const handleKilometerChange = (event) => {
        setRegistrantPage(1)
        setKilometer(event.value);
    };

    const kilometerOptions = [
        { value: '50', label: '50km' },
        { value: '100', label: '100km' },
        { value: '200', label: '200km' },
        { value: '500', label: '500km' },
        { value: '1000', label: '1000km' },
    ];



    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: 'white',
            fontFamily: 'Raleway',
            fontSize: '14px',
            fontWeight: 600,
        }),
        option: (styles) => ({
            ...styles,
            fontFamily: 'Raleway',
            fontSize: '14px',
            fontWeight: 600,
            color: 'black'
        }),
    };

    useEffect(() => {
        api()
    }, [registrantPage, kilometer]);


    useEffect(() => {
        // if (province?.value?.length > 0 || category?.value?.length > 0 || registrant?.value?.length > 0 || searchTerm?.length > 0) {
        //     setDisable(false);
        // } else {
        //     setDisable(true);
        // }
        if (render.current !== 0) {
            const debounceTimer = setTimeout(() => {
                if (registrantPage === 1) {
                    api();
                } else if (search.length > 1) {
                    setRegistrantPage(1);
                }
            }, 1500);
            return () => clearTimeout(debounceTimer);
        } else {
            render.current++;
        }
    }, [search]);




    const redirectToLogin = () => {
        token = logindata?.token || null;
        if (logindata?.user_data?._id) { localUserId = logindata?.user_data?._id; }
        if (token === null) { navigate('/login'); }
    };


    const fetchData = async () => {
        try {
            if (token) {
                const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/logged-user-data`, {
                    params: { id: localUserId },
                    headers: { Authorization: `Bearer ${token}` },
                });
                const bookmarkRegistrant = response?.data?.registrant_bookmark?.map(firm => firm.registrant_id);
                setRegistrantBookmarkData(bookmarkRegistrant);
            } else {
                navigate("/");
                toast.error(`Please login again`);
            }
        } catch (error) {
            // console.error('Error fetching data:', error);
            if (error?.response?.status === 401) {
                localStorage.removeItem('login');
                navigate("/");
                toast.error(`Unauthorized user !`);
            } else {
                toast.error('Error fetching user data');
            }
        }
    };
    useEffect(() => {
        fetchData();
    }, [token, localUserId]);


    const api = async () => {
        if (token) {
            try {
                setIsLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/registrant/nearby`,
                    {
                        params: {
                            "page": registrantPage,
                            "per_page": 12,
                            "radius": Number(kilometer),
                            "location": search,
                            // "kilometer":kilometer
                        },
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })

                if (response?.data?.totalCount !== 0) {
                    setData(response?.data)
                    setCount(response?.data?.totalCount)
                }
                setIsLoading(false)

            } catch (error) {
                setData([]);
                setCount(0);
                setIsLoading(false)

            }
        }
    }
    // console.log("responseresponse",count)
    // console.log("Data",data)



    const handlePageChange = (page) => {
        setRegistrantPage(page);
        setGoTOPage(page);
        window.scrollTo(0, 0);
    };

    const handleSearch = (e) => {
        setSearch(e.target.value)

    }
    const handlePageSearch = (val) => {
        if (gotopag === true) {
            const inputPage = parseInt(val);
            // console.log(inputPage)

            if ((registrantPage == val) || val == null) {
                if (registrantPage >= data?.totalPages) {
                    toast.error(`Page ${registrantPage + 1} does not exist`)
                }
                else {
                    setRegistrantPage(registrantPage + 1);
                    setGoTOPage(registrantPage + 1);
                    window.scrollTo(0, 0);
                }
            }
            else {
                if (!isNaN(inputPage) && inputPage > 0 && inputPage <= data?.totalPages) {
                    setRegistrantPage(inputPage);
                } else if (inputPage >= data?.totalPages) {
                    toast.error(`Page ${inputPage} does not exist`)
                }
                placeholderDataHide(false)
            }
        }
        setGotopag(false);
    };

    const handleReset = () => {
        setSearch('')
        setKilometer('50');

    };

    const downloadData = async () => {
        setIsDownloading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/registrant/nearby/export`, {
                params: {
                    radius: kilometer,
                    location: search,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });


            let csvResponseData = response?.data;

            if (csvResponseData && typeof csvResponseData === 'string') {
                const rows = csvResponseData.split('\n');
                const csvData = rows.map(row => row.split(',').map(cell => cell.replace(/"/g, '').trim()));

                const blob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'registrant.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.warn('Data is not a string or is undefined.');
            }
            setIsDownloading(false)

        } catch (error) {
            console.log("errror:", error)
            setTimeout(() => {
                setIsDownloading(false)
            }, 1500);
        }
    }










    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyB36pInVxWlfi62te8OAyFfLvodIB9dtrM',
        libraries: ['places'],
    });

    const [marker, setMarker] = useState(center);


    const [latLng, setLatLng] = useState(center);

    const autocompleteRef = useRef(null);
    const mapRef = useRef(null);

    const onMapClick = useCallback((event) => {
        const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };
        setMarker(newLatLng);
        setLatLng(newLatLng);
        geocodeLatLng(newLatLng.lat, newLatLng.lng);
    }, []);

    const geocodeLatLng = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat, lng };

        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const addressComponents = results[0].address_components;
                    const location = results[0].formatted_address;
                    let city = '';
                    let state = '';
                    let country = '';
                    let postalCode = '';

                    for (let component of addressComponents) {
                        const types = component.types;
                        if (types.includes('locality')) {
                            city = component.long_name;
                        }
                        if (types.includes('administrative_area_level_1')) {
                            state = component.long_name;
                        }
                        if (types.includes('country')) {
                            country = component.long_name;
                        }
                        if (types.includes('postal_code')) {
                            postalCode = component.long_name;
                        }
                    }

                    //   setAddress({ location, city, state, country, postalCode });
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    };



    const onPlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
            const newLatLng = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            setMarker(newLatLng);
            setLatLng(newLatLng);
            mapRef?.current?.panTo(newLatLng);
            geocodeLatLng(newLatLng.lat, newLatLng.lng);
        }
        setSearch(place?.formatted_address)



        dispatch(setAddress({
            address: place?.formatted_address,
        }));
    };

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps</div>;
    // console.log('registrantPage',registrantPage)

    return (
        <>
            <div className="d-flex align-items-center justify-content-between flex-column" style={{ height: '100vh' }}>
                <div className="slider_img_div_box" >
                    <Navbar />
                    <Container fluid className=" mb-4">
                        <div className=" mb-2 mb-lg-4 bg-white shadow-sm py-3 px-2" style={{ borderRadius: '10px' }}>
                            <div className="row">

                                <div className="col-12">
                                    <div className="row align-items-center  justify-content-center">
                                        <div className="d-flex align-items-start  justify-content-md-start justify-content-start flex-row  col-md-6 ">
                                            <div className="col-lg-6 col-md-6  col-sm-6  col-sx-6" >
                                                <label
                                                    style={{
                                                        color: '#BBB',
                                                        fontFamily: 'Raleway',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 600,
                                                        letterSpacing: '0.14px',
                                                    }}
                                                >
                                                    Address
                                                </label>
                                                <div className="nearme_user_search searchOutline ">
                                                    <Autocomplete
                                                        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                                                        onPlaceChanged={onPlaceChanged}
                                                    ><>
                                                            <input
                                                                type="search"
                                                                name=""
                                                                id=""
                                                                className="adminSearchBar"
                                                                placeholder="Search"
                                                                value={search}
                                                                onChange={handleSearch}
                                                            />
                                                            <i className="fa-solid fa-magnifying-glass"></i>
                                                        </>
                                                    </Autocomplete>

                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4  col-sm-6  col-sx-6 px-2 ">
                                                <label
                                                    style={{
                                                        color: '#BBB',
                                                        fontFamily: 'Raleway',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 600,
                                                        letterSpacing: '0.14px',
                                                            
                                                    }}
                                                >
                                                    Kilometer
                                                </label>
                                                <Select
                                                    value={kilometer}
                                                    onChange={handleKilometerChange}
                                                    options={kilometerOptions}
                                                    styles={colourStyles}
                                                    placeholder={kilometer}
                                                    className=""
                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex col-md-6 justify-content-end align-items-center mt-3 mt-md-0">

                                            <button
                                                className="reset-filter "
                                                onClick={handleReset}
                                                style={{
                                                    color: '#FF4740',
                                                    backgroundColor: '#FFF0EB',
                                                    whiteSpace: "nowrap",
                                                    // cursor: disable ? "not-allowed" : "pointer",
                                                }}
                                            //   disabled={disable}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8 16C10.0444 16 11.8259 15.3222 13.3444 13.9667C14.863 12.6111 15.7333 10.9185 15.9556 8.88889H14.1333C13.9259 10.4296 13.2407 11.7037 12.0778 12.7111C10.9148 13.7185 9.55556 14.2222 8 14.2222C6.26667 14.2222 4.7963 13.6185 3.58889 12.4111C2.38148 11.2037 1.77778 9.73333 1.77778 8C1.77778 6.26667 2.38148 4.7963 3.58889 3.58889C4.7963 2.38148 6.26667 1.77778 8 1.77778C9.02222 1.77778 9.97778 2.01481 10.8667 2.48889C11.7556 2.96296 12.5037 3.61481 13.1111 4.44444H10.6667V6.22222H16V0.888889H14.2222V2.97778C13.4667 2.02963 12.5444 1.2963 11.4556 0.777778C10.3667 0.259259 9.21482 0 8 0C6.88889 0 5.84815 0.211111 4.87778 0.633333C3.90741 1.05556 3.06296 1.62593 2.34444 2.34444C1.62593 3.06296 1.05556 3.90741 0.633333 4.87778C0.211111 5.84815 0 6.88889 0 8C0 9.11111 0.211111 10.1519 0.633333 11.1222C1.05556 12.0926 1.62593 12.937 2.34444 13.6556C3.06296 14.3741 3.90741 14.9444 4.87778 15.3667C5.84815 15.7889 6.88889 16 8 16Z" fill="#FF4740" />
                                                </svg>

                                                Reset
                                            </button>

                                            <div className="" style={{ paddingLeft: "10px" }}>
                                                {count !== 0 && isDownloading == false ? (
                                                    <button
                                                        onClick={() => { downloadData() }}
                                                        className="export-data"
                                                        style={{
                                                            color: '#fff',
                                                            backgroundColor: '#0F66B4',
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M9 0L9 8.15L11.6 5.55L13 7L8 12L3 7L4.4 5.55L7 8.15V0H9ZM14 16C14.55 16 15.0208 15.8042 15.4125 15.4125C15.8042 15.0208 16 14.55 16 14V11H14V14L2 14V11H0V14C0 14.55 0.195833 15.0208 0.5875 15.4125C0.979166 15.8042 1.45 16 2 16L14 16Z" fill="#D9EDFF" />
                                                        </svg>
                                                        Export
                                                    </button>
                                                ) : (
                                                    <button
                                                        disabled={isDownloading}
                                                        className="export-data"
                                                        style={{
                                                            color: '#fff',
                                                            backgroundColor: '#0F66B4',
                                                            opacity: isDownloading ? 0.7 : 0.6,
                                                            cursor: isDownloading ? 'not-allowed' : 'pointer',
                                                        }} > {" "}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M9 0L9 8.15L11.6 5.55L13 7L8 12L3 7L4.4 5.55L7 8.15V0H9ZM14 16C14.55 16 15.0208 15.8042 15.4125 15.4125C15.8042 15.0208 16 14.55 16 14V11H14V14L2 14V11H0V14C0 14.55 0.195833 15.0208 0.5875 15.4125C0.979166 15.8042 1.45 16 2 16L14 16Z" fill="#D9EDFF" />
                                                        </svg>{" "}
                                                        {isDownloading ? "Loading" : 'Export'}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="account-information mb-3">
                            <div>
                                <div className="  d-flex align-items-center px-4 justify-content-between">
                                    <span className="p-1"> Total Data - {count}</span>
                                </div>
                            </div>

                        </div>
                        <span className="d-inline-block mb-3" style={{ color: '#0F67B6', fontSize: '18px', fontWeight: '600', fontFamily: `'lato',sans-serif`, cursor: 'pointer' }} onClick={() => { navigate(-1) }}><i className="fa-solid fa-arrow-left-long me-2"></i> Go Back</span>

                        <Row>



                            {isLoading || (!isLoaded) ?
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    className="d-flex justify-content-center align-content-center"

                                    style={{ height: "79vh" }}
                                >
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        radius={5}
                                        color="#0f66b4"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperClassclassName={{}}
                                        wrapperStyle=""
                                        visible={true}
                                    />
                                </Col>
                                :

                                (count == 0 ? <div className="d-flex justify-content-center w-100"> <h5>No Data Found</h5></div> :
                                    <>
                                        <Col lg={8} className=" pt-2 pt-lg-0  dashboard_list_scroll ">
                                            <Row className="g-3 pb-3 areaWrapping">
                                                {data?.registrants?.map((val, itemIndex) => (
                                                    <>
                                                        <Col xs={12} sm={6} md={4} lg={6} key={itemIndex}>
                                                            <RegistrantCard data={val} index={itemIndex} fetchData={fetchData} registrantBookmarkData={registrantBookmarkData} />
                                                        </Col>
                                                        {/* <Col xs={12} sm={6} md={4} lg={6} key={val.id}>
                                                <div>
                                                    <RegistarntCards registrant_name={val?.registrant_name} currentjob={val?.current_job_title} personal_email={val?.personal_email} work_email={val?.work_email}
                                                        linkedin_identifier={val?.linkedin_identifier} linkedin_link={val?.linkedin_link} phone_number={val?.phone_number} city={val?.city} _id={val?._id} profile_image_url={val?.profile_image_url} />
                                                </div>
                                            </Col> */}
                                                    </>
                                                ))}


                                                {/* <Col xs={12} sm={6} md={4} lg={6}>
                                        <div>
                                            <RegistarntCards />
                                        </div>  
                                    </Col> */}
                                            </Row>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="map-container mt-4  mt-lg-0 h-100">
                                                <RegistrantMap data={data?.registrants} />
                                            </div>
                                        </Col>
                                        <Row className="py-4">
                                            <Col xxl={8} lg={8} md={12} sm={12} className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between py-2" style={{ gap: '10px' }}>
                                                <div>

                                                    <CustomPagination
                                                        maxWidth={9}
                                                        total={data?.totalPages}
                                                        current={registrantPage}
                                                        onPageChange={handlePageChange}
                                                    />

                                                </div>

                                                <div>
                                                    <ul className=" list-unstyled new_side_pagination ">
                                                        <li style={{ whiteSpace: 'nowrap' }}>Go to page</li>
                                                        <li>
                                                            <label htmlFor="pageNEmber">
                                                                <input type="number" name="" id="pageNEmber"
                                                                    placeholder={onfocas ? '' : registrantPage}
                                                                    onFocus={() => placeholderDataHide(true)}
                                                                    onBlur={() => { placeholderDataHide(false); setGotopag(true) }}
                                                                    onChange={(e) => {
                                                                        setGoTOPage(e.target.value);
                                                                    }}
                                                                    onKeyDown={blockInvalidChar}
                                                                />

                                                            </label>

                                                        </li>
                                                        <li>
                                                            <div onClick={() => { handlePageSearch(goToPage) }}>
                                                                <i className="fa-solid fa-arrow-right"></i>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }

                        </Row>



                    </Container>
                </div>
            </div>
        </>
    );
};

export default RegistrantNearme;