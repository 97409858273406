import {createSlice,createAsyncThunk, configureStore} from '@reduxjs/toolkit';
import axios from 'axios';
export const fetchCategory = createAsyncThunk('fetchCategory', async (payload) => {
    try {
      let { provience, firm } = payload;

      if(provience === undefined || provience === "All" ){
        provience = '';
      }

      if(firm === undefined || firm === "All"){
        firm='';
      }


      const tokenData = JSON.parse(localStorage.getItem('login'));
      let token =tokenData?.token;
      if(token){      
   
      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/filterd`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', 
        },
      });
      return response.data;
    }
    
    } catch (error) {
      console.error("Error fetching category:", error);
      throw error; 
    }
  });
const categorySlice = createSlice({
    name:'category',
    initialState:{
        isLoading:false,
        data:null,
        isError:false,
    },
    extraReducers : (builder) => {
        builder.addCase(fetchCategory.pending, (state,action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCategory.fulfilled, (state,action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchCategory.rejected, (state,action) => {
          state.isError=true;
   
        });

    }
});
export default categorySlice.reducer;