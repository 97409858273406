import { createSlice } from '@reduxjs/toolkit';

const pageNumberSlice = createSlice({
  name: 'pageNumber',
  initialState: { value: 1 },
  reducers: {
    setPageNumber(state, action) {
      state.value = action.payload;
    },
  },
});

export const { setPageNumber } = pageNumberSlice.actions;
export default pageNumberSlice.reducer;
