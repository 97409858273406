import React, { useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

const NoInternet = () => {


    const navigate = useNavigate();
    useEffect(() => {
        document.title = "PCMA || No Internet";
    }, []);

    const pathname = window.location.pathname;
    const pathSegments = pathname?.split('/');

    const handleRefresh = () => {
        window.location.reload();
        if (pathSegments[1]?.includes("admin")) {
            navigate("/admin");
        } else {
            navigate("/");
        }
    };

    // const handleGoBack = () => {
    //     if (pathSegments[1].includes("admin")) {
    //         navigate("/admin");
    //     } else {
    //         navigate("/");
    //     }
    // };



    return (
        <div className='error-container'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div className='row align-items-center justify-content-center'>
                                <div className='col-md-6 '>
                                    <div className='d-flex justify-content-center align-content-center'>
                                        <img src='/asset/no-internet.png' className='img-fluid' alt='' />
                                    </div>

                                </div>
                                <div className='col-lg-6 col-sm-12'>
                                    <div className='error_contant'>
                                        <h1>No Internet!</h1>
                                        <p>Please connect to internet.</p>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <button className='refresh_btn'>
                                                <i className="fa-solid fa-arrow-rotate-right me-2" onClick={handleRefresh}></i>Refresh</button>
                                            {pathSegments[1] ? <Link to={"/admin"} className='ms-2 goback_btn'>Go Back</Link>: <Link to={'/'} className='ms-2 goback_btn' >Go Back</Link>}
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default NoInternet;