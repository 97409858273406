// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Dropdown, Modal, Fade } from "react-bootstrap";
// import { useNavigate, Link } from "react-router-dom";
// import Select from "react-select";
// import ResponsivePagination from 'react-responsive-pagination';
// import MemberCard from '../../components/memberDashboard/MemberCard';
// import Footer from '../../components/footer/Footer';
// import MemberMap from '../../components/memberDashboard/MemberMap';
// import toast from "react-hot-toast";
// import axios from 'axios';
// import { BallTriangle } from 'react-loader-spinner';
// import CustomPagination from '../../components/pagination/CustomPagination';


// const MemberSearch = ({ memberPage, setMemberPage, searchD, setSearchD }) => {
//     const loginData = JSON.parse(window.localStorage.getItem("login"));
//     let token;
//     let userId;
//     let userName;
//     let labelValue1 = '', labelValue2 = '', labelValue3 = '', labelValue4 = '', labelValue5 = '';
//     let filterValue1 = '', filterValue2 = '', filterValue3 = '', filterValue4 = '', filterValue5 = '';
//     let ExportValue1 = '', ExportValue2 = '', ExportValue3 = '', ExportValue4 = '', ExportValue5 = '';
//     const navigate = useNavigate();
//     const [isloading, setIsLoading] = useState(true);
//     const [isDownloading, setIsDownloading] = useState(false);
//     const [userAddress, setUserAddress] = useState('')
//     const [profileImage, setProfileImage] = useState('');
//     const [showModal, setShowModal] = useState(false);
//     const [notificationName, setNotificationName] = useState('');
//     const [notificationDate, setNotificationDate] = useState('');
//     const [notificationmessage, setNotificationmessage] = useState('');
//     const [noticeDatas, setNoticeData] = useState([])
//     const [search, setSearch] = useState(window.history.state?.search || searchD || '');
//     const [totalPages, setTotalPages] = useState(null);
//     const [totalMember, setTotalMember] = useState([]);
//     const [totalMembers, setTotalMembers] = useState('');
//     const [noData, setNoData] = useState(false);
//     const [goToPage, setGoTOPage] = useState(memberPage);
//     const [newPage, setNewPage] = useState(null);
//     const [countryOption, setCountryOption] = useState([]);
//     const [provinceOption, setProvinceOption] = useState([]);
//     const [cityOption, setCityOption] = useState([]);
//     const [gotopag, setGotopag] = useState(false);
//     // const [postalcodeOption, setPostalcodeOption] = useState([]);
//     const [membershipTypeOption, setMembershipTypeOption] = useState([]);
//     const [country, setCountry] = useState(window.history.state?.country || ' ');
//     const [province, setProvince] = useState(window.history.state?.province || '');
//     const [city, setCity] = useState(window.history.state?.city || "");
//     // const [postalCode, setPostalCode] = useState(window.history.state?.postalCode ||"");
//     const [membershipType, setMembershipType] = useState(window.history.state?.membershipType || "");
//     const [resetDisable, setResetDisable] = useState(true);
//     const blockInvalidChar = e => ['e', 'E', '+', '-']?.includes(e.key) && e.preventDefault();
//     const [onfocas, placeholderDataHide] = useState(false);
//     const capitalizeFirstLetter = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };
//     const handleClose = () => setShowModal(false);
//     const options1 = countryOption?.filter(Boolean)?.map(option => ({ value: option, label: option }));
//     const options2 = provinceOption?.filter(Boolean)?.map(option => ({ value: option, label: option }));
//     const options3 = cityOption?.filter(Boolean)?.map((option) => ({ value: option, label: option }));
//     // const options4 = postalcodeOption?.filter(Boolean)?.map(option => ({ value: option, label: option }));
//     const options5 = membershipTypeOption?.filter(Boolean)?.map(option => ({ value: option, label: option }));
//     if (loginData) { token = loginData?.token; }
//     if (loginData?.user_data?._id) { userId = loginData?.user_data?._id; }
//     if (loginData?.user_data?.name) { userName = loginData?.user_data?.name; }
//     useEffect(() => {
//         document.title = "PCMA || Members Dashboard";
//     }, []);
//     useEffect(() => {
//         const fetchNoticeData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/all-notice-data-user`, {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 });
//                 if (response?.data?.message === "Unauthorized User") {
//                     localStorage.removeItem("login");
//                     navigate("/");
//                     toast.error("Unauthorized user !");
//                 } else {
//                     setNoticeData(response?.data?.notice_data?.data || []);
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 if (error?.response?.status === 401) {
//                     localStorage.removeItem('login');
//                     navigate("/");
//                     toast.error(`Unauthorized user !`);
//                 } else if (error?.response?.status === 500) {
//                     toast.error('Server error occurred. Please try again later.');
//                 } else {
//                     toast.error('Error fetching notice data');
//                 }
//             }
//         };

//         if (token) {
//             fetchNoticeData();
//         } else {
//             toast.error("Please login again");
//         }
//     }, [token, navigate]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 if (token) {
//                     const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/logged-user-data`, {
//                         params: { id: userId },
//                         headers: { Authorization: `Bearer ${token}` },
//                     });
//                     setUserAddress(response?.data?.address);
//                     setProfileImage(response?.data?.profile_picture);
//                 } else {
//                     navigate("/");
//                     toast.error(`Please login again`);
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 if (error?.response?.status === 401) {
//                     localStorage.removeItem('login');
//                     navigate("/");
//                     toast.error(`Unauthorized user !`);
//                 } else {
//                     toast.error('Error fetching user data');
//                 }
//             }
//         };

//         fetchData();
//     }, [token, userId]);

//     const formatNoticeData = (dateString) => {
//         const date = new Date(dateString);
//         const today = new Date();
//         const yesterday = new Date(today);
//         yesterday.setDate(yesterday.getDate() - 1);

//         if (date.toDateString() === today.toDateString()) {
//             return 'Today';
//         } else if (date.toDateString() === yesterday.toDateString()) {
//             return 'Yesterday';
//         } else {
//             return date.getDate() + (date.getDate() % 10 === 1 && date.getDate() !== 11 ? 'st' : date.getDate() % 10 === 2 && date.getDate() !== 12 ? 'nd' : date.getDate() % 10 === 3 && date.getDate() !== 13 ? 'rd' : 'th') + ' ' + date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
//         }
//     };

//     const handleNoticeModal = (data) => {
//         setShowModal(true);
//         const noticeDate = formatNoticeData(data?.updated_at);
//         setNotificationDate(noticeDate)
//         setNotificationName(data?.name);
//         setNotificationmessage(data?.message)
//     }

//     const fetchMemberData = () => {
//         setIsLoading(true);
//         if (country?.label) {
//             labelValue1 = country.label;
//         }
//         if (province?.label) {
//             labelValue2 = province.label;
//         }
//         if (city?.label) {
//             labelValue3 = city.label;
//         }
//         // if (postalCode?.label) {
//         //     labelValue4 = postalCode.label;
//         // }
//         if (membershipType?.label) {
//             labelValue5 = membershipType.label;
//         }
//         axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/member-search?search_value=${search}&page=${memberPage}&country=${labelValue1}&province=${labelValue2}&city=${labelValue3}&membership_type=${labelValue5}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         })
//             .then(response => {
//                 if (response?.data?.message === "Unauthozied User") {
//                     localStorage.removeItem('login');
//                     navigate("/");
//                     toast.error(`Unauthozied user !`);
//                 } else if (response?.data?.message === "No data found") {
//                     setTotalMember([]);
//                     setNoData(true)
//                 } else {
//                     setNoData(false)
//                     setTotalMember(response?.data?.users);
//                     setTotalMembers(response?.data?.pagination?.total);
//                     setTotalPages(response?.data?.pagination?.last_page);
//                 }
//                 setIsLoading(false);


//             })
//             .catch(error => {
//                 if (error?.data?.message === "Unauthozied User") {
//                     localStorage.removeItem('login');
//                     navigate("/");
//                     toast.error(`Unauthozied user !`);
//                 } else {
//                     setIsLoading(false);
//                     console.error('Error fetching data:', error);
//                 }
//             });
//     };

//     useEffect(() => {
//         if (country?.value?.length > 0 || province?.value?.length > 0 || city?.value?.length > 0 || membershipType?.value?.length > 0 || search?.length > 0) { //postalCode?.value?.length>0
//             setResetDisable(false);
//         } else {
//             setResetDisable(true);
//         }
//         let timerOut = setTimeout(() => {
//             fetchMemberData();
//         }, 500)
//         return () => clearTimeout(timerOut);
//     }, [country, province, city, membershipType, search, memberPage]);// postalCode

//     const memberFilter = () => {
//         if (country?.label) {
//             filterValue1 = country?.label;
//         }
//         if (province?.label) {
//             filterValue2 = province?.label;
//         }
//         if (city?.label) {
//             filterValue3 = city?.label;
//         }
//         // if (postalCode?.label) {
//         //     filterValue4 = postalCode?.label;
//         // }
//         if (membershipType?.label) {
//             filterValue5 = membershipType?.label;
//         }
//         axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/member-filter?country=${filterValue1}&province=${filterValue2}&city=${filterValue3}&membership_type=${filterValue5}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json',
//             },
//         })
//             .then(response => {
//                 setCountryOption(response?.data?.country);
//                 setProvinceOption(response?.data?.province);
//                 setCityOption(response?.data?.city);
//                 // setPostalcodeOption(response?.data?.postal_code);
//                 setMembershipTypeOption(response?.data?.membership_type);
//             })
//             .catch(error => {
//                 if (error?.data?.message === "Unauthozied User") {
//                     localStorage.removeItem('login');
//                     navigate("/");
//                     toast.error(`Unauthozied user !`);
//                 } else {
//                     console.error('Error fetching data:', error);
//                 }
//             });

//     };

//     useEffect(() => {
//         memberFilter();
//     }, [country, province, city, membershipType]) //city postalCode


//     useEffect(() => {
//         const newHistoryState = {
//             search,
//             country,
//             province,
//             city,
//             //   postalCode,
//             membershipType
//         };
//         window.history.replaceState(newHistoryState, '');
//     }, [search, country, province, city, membershipType]); //postalCode

//     const memberExportCSV = () => {
//         setIsDownloading(true);
//         if (country?.label) {
//             ExportValue1 = country.label;
//         }
//         if (province?.label) {
//             ExportValue2 = province.label;
//         }
//         if (city?.label) {
//             ExportValue3 = city.label;
//         }
//         // if (postalCode?.label) {
//         //     ExportValue4 = postalCode.label;
//         // }
//         if (membershipType?.label) {
//             ExportValue5 = membershipType.label;
//         }
//         axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/member-export?search_value=${search}&page=${memberPage}&country=${ExportValue1}&province=${ExportValue2}&city=${ExportValue3}&membership_type=${ExportValue5}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },

//         }).then(response => {
//             let CSVData = response?.data
//             const blob = new Blob([CSVData], { type: 'text/csv' });
//             const url = window.URL.createObjectURL(blob);
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', 'PCMA Member Details.csv');
//             document.body.appendChild(link);
//             link.click();
//             setIsDownloading(false);
//         }).catch(error => {
//             if (error?.data?.message === "Unauthozied User") {
//                 localStorage.removeItem('login');
//                 navigate("/");
//                 toast.error(`Unauthozied user !`);
//                 setIsLoading(false);
//                 setIsDownloading(false);
//             } else {
//                 setIsLoading(false);
//                 setIsDownloading(false);
//                 console.error('Error fetching data:', error);
//             }

//         });
//     };


//     const handlePageChange = (page) => {
//         setMemberPage(page);
//         setGoTOPage(page);
//         window.scrollTo(0, 0);
//     };

//     const handlePageSearch = (val) => {
//         if (gotopag === true) {
//             const inputPage = parseInt(val);
//             if ((memberPage == val) || val == null) {
//                 if (memberPage >= totalPages) {
//                     toast.error(`Page ${memberPage + 1} does not exist`)
//                 }
//                 else {
//                     setMemberPage(memberPage + 1);
//                     setGoTOPage(memberPage + 1);
//                     window.scrollTo(0, 0);
//                 }
//             }
//             else {
//                 if (!isNaN(inputPage) && inputPage > 0 && inputPage <= totalPages) {
//                     setMemberPage(inputPage);
//                 } else if (inputPage >= totalPages) {
//                     toast.error(`Page ${inputPage} does not exist`)
//                 }
//                 placeholderDataHide(false)
//             }
//             setGotopag(false);
//         }
//     };

//     const handleSearch = (event) => {
//         let searchValue = event.target.value;
//         setMemberPage(1)
//         setGoTOPage(1);
//         setSearch(searchValue);
//     };


//     const handleCountry = (selectedOption1) => {
//         setCountry(selectedOption1);
//         setResetDisable(false);
//         setMemberPage(1);
//         setGoTOPage(1);

//     };

//     const handleProvince = (selectedOption2) => {
//         setProvince(selectedOption2);
//         setResetDisable(false);
//         setMemberPage(1);
//         setGoTOPage(1);

//     };

//     const handleCity = (selectedOption3) => {
//         // setCity(selectedOption3);
//         if (selectedOption3) {
//             const capitalizedCity = selectedOption3?.label?.charAt(0)?.toUpperCase() + selectedOption3?.label?.slice(1);
//             setCity({ ...selectedOption3, label: capitalizedCity });
//         } else {
//             setCity(null);
//         }

//         setResetDisable(false);
//         setMemberPage(1);
//         setGoTOPage(1);


//     };

//     // const handlePostalCode = (selectedOption4) => {
//     //     setPostalCode(selectedOption4);
//     //     setResetDisable(false);
//     //     setMemberPage(1);
//     //     setGoTOPage(1);
//     // };
//     const handleMembershipType = (selectedOption5) => {
//         setMembershipType(selectedOption5);
//         setResetDisable(false);
//         setMemberPage(1);
//         setGoTOPage(1);

//     };

//      const handleResetSearch = () => {
//            setSearch('');
//            setSearchD('');
//      };


//     const handleReset = () => {
//         setIsLoading(true);
//         setNoData(false);
//         setSearch('');
//         setCountry('');
//         setProvince('');
//         setCity('');
//         // setPostalCode('');
//         setMembershipType('');
//         setMemberPage(1);
//         setGoTOPage(1);
//         setResetDisable(true);
//         setSearchD('');
//     };
//     const handleBack = () => {
//         setMemberPage(1);
//     };

//     const logout = () => {
//         localStorage.removeItem('login');
//         toast.success('You have logged out successfully.');
//         navigate("/");
//     };
//     const colourStyles = {
//         option: (styles, { isFocused }) => {
//             return {
//                 ...styles,
//                 cursor: isFocused ? "pointer" : null,
//             };
//         }
//     };

//     return (
//         <div div className='d-flex justify-content-between flex-column' style={{ height: '100vh' }}>
//             <div className="slider_img_div_box">
//                 <Container fluid className=" mb-3">
//                     <Row className="g-2" style={{ paddingBottom: "10px" }}>
//                         <Col className="col-2  order-1 d-flex align-items-center justify-content-start py-3">
//                             <Link to="/">
//                                 <img
//                                     src="./asset/logo.png"
//                                     className="header_img_logo"
//                                     alt=""
//                                 />
//                             </Link>
//                         </Col>
//                         <Col className="col-12 col-md-6 col-xl-5  order-3 order-md-2 d-flex align-items-center justify-content-start">
//                             <div
//                                 className="search_box d-flex align-items-center  justify-content-start"
//                                 style={{
//                                     border: "2px solid rgba(159, 194, 225, 0.80)",
//                                     borderRadius: "6px",
//                                     width: "100%",
//                                     padding: '8px',
//                                     backgroundColor: "#FFFFFF",
//                                 }}

//                             >
//                                 <div className="dropdown search_dropdown_menu">
//                                     <a className="btn  dropdown-toggle" style={{ backgroundColor: 'rgba(15, 102, 180, 0.10)', color: '#0F66B4' }} href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
//                                         Members
//                                     </a>

//                                     <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
//                                         <li><Link className="dropdown-item" to="/firm"> Firm</Link></li>
//                                         <li><Link className="dropdown-item" to="/registrant-search">Registrants</Link></li>
//                                     </ul>
//                                 </div>


//                                 <form className="d-flex w-100">
//                                     <input
//                                         type="search"
//                                         className="top-search"
//                                         style={{ width: "100%" }}
//                                         placeholder="Search By PCMA Members"
//                                         value={search}
//                                         onChange={handleSearch}
//                                         onKeyPress={(e) => {
//                                             if (e.key === "Enter") {
//                                                 e.preventDefault();
//                                                 if (search?.length > 0) {
//                                                     fetchMemberData();
//                                                 }

//                                             }
//                                         }}
//                                     />
//                                       <button type="button" className="reset-search-button me-2" onClick={handleResetSearch}><i className="fa-solid fa-arrow-rotate-right me-2"></i> Reset </button> 
//                                     <button
//                                         type="button"
//                                         className="top-search-button"
//                                         style={{ cursor: 'auto' }}
//                                     >
//                                         <i className="fa-solid fa-magnifying-glass"></i>
//                                     </button>
//                                 </form>
//                             </div>
//                         </Col>
//                         <Col className=" col-10  col-md-4 col-xl-5   order-2 order-md-3 d-flex align-items-center justify-content-end">
//                             <div className="icons-top-bar icons_top_bar d-flex align-items-center justify-content-around"></div>
//                             <div className="dropdown align-items-center mt-3">
//                                 <div
//                                     data={noticeDatas?.length}
//                                     className="notice_svg me-3"
//                                     id="dropdownMenuButton1"
//                                     data-bs-toggle="dropdown"
//                                     aria-expanded="false"
//                                 >
//                                     <svg
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         width="30"
//                                         height="30"
//                                         style={{ cursor: "pointer" }}
//                                         viewBox="0 0 30 30"
//                                         fill="none"
//                                     >
//                                         <path
//                                             d="M23.4375 12.1375V11.2563C23.4375 6.42 19.6575 2.5 15 2.5C10.3425 2.5 6.5625 6.42 6.5625 11.2563V12.1375C6.56401 13.1894 6.26381 14.2198 5.6975 15.1062L4.3125 17.2625C3.04875 19.2313 4.01375 21.9075 6.2125 22.53C11.9576 24.1587 18.0424 24.1587 23.7875 22.53C25.9863 21.9075 26.9513 19.2313 25.6875 17.2638L24.3025 15.1075C23.7358 14.2211 23.4351 13.1908 23.4363 12.1388L23.4375 12.1375Z"
//                                             fill="#A7ADB4"
//                                         />
//                                         <path
//                                             d="M9.375 22.75C10.1937 24.935 12.4025 27.5 15 27.5C17.5975 27.5 19.8062 24.935 20.625 22.75"
//                                             fill="#A7ADB4"
//                                         />
//                                     </svg>
//                                 </div>
//                                 <ul
//                                     className="dropdown-menu notice_data py-0"
//                                     aria-labelledby="dropdownMenuButton1"
//                                 >
//                                     <li className="p-3 border-bottom">
//                                         <p className="mb-0 d-flex align-items-center justify-content-between">
//                                             Notifications
//                                             <span>
//                                                 <i className="fa-solid fa-bars-staggered"></i>
//                                             </span>
//                                         </p>
//                                     </li>

//                                     <li>
//                                         <ul className=" list-unstyled p-0 notice_data_item_box">
//                                             {noticeDatas?.map((noticeData, index) => {
//                                                 return (
//                                                     <li key={index}>
//                                                         <Link
//                                                             className="dropdown-item"
//                                                             onClick={() => {
//                                                                 handleNoticeModal(noticeData);
//                                                             }}
//                                                         >
//                                                             <p className="notice_data_item mb-0">
//                                                                 {noticeData?.name}
//                                                             </p>
//                                                         </Link>
//                                                     </li>
//                                                 );
//                                             })}
//                                         </ul>
//                                     </li>
//                                 </ul>
//                             </div>

//                             {/* <div className="d-flex flex-row top-bar-dashboard">
//                                 <Dropdown>
//                                     <Dropdown.Toggle
//                                         variant="success"
//                                         id="dropdown-basic"
//                                         className="p-0 ms-2 drop_down_dasboard d-flex justify-content-between align-items-center mt-2"
//                                         style={{
//                                             backgroundColor: "transparent",
//                                             border: "transparent",
//                                         }}
//                                     >
//                                         <div className="data-user text-dark d-flex justify-content-center flex-column me-3">
//                                             <h5 className="text-end mb-0">
//                                                 {capitalizeFirstLetter(userName)}
//                                             </h5>
//                                             <h6 className="mb-0 text-end">{userAddress}</h6>
//                                         </div>

//                                         {profileImage?.length > 0 ? (
//                                             <span
//                                                 className='j_header_profile_img'
//                                                 style={{
//                                                     border: "1px solid black",
//                                                     borderRadius: "50%",
//                                                     transition: '0.3s'
//                                                 }}
//                                             >
//                                                 <img
//                                                     className="fluid"
//                                                     src={`${profileImage}`}
//                                                     alt="logo "
//                                                     style={{
//                                                         borderRadius: "8px",
//                                                         height: "3rem",
//                                                         width: "3rem",
//                                                         borderRadius: "100px",
//                                                     }}
//                                                 />
//                                             </span>
//                                         ) : (
//                                             <span
//                                                 className='j_header_profile_img'
//                                                 style={{
//                                                     transition: '0.3s',
//                                                     border: "1px solid black",
//                                                     borderRadius: "50%",
//                                                 }}
//                                             >
//                                                 <img
//                                                     src="./asset/users.png"
//                                                     className="fluid"
//                                                     alt=""
//                                                     style={{
//                                                         borderRadius: "8px",
//                                                         height: "3rem",
//                                                         width: "3rem",
//                                                         borderRadius: "100px",
//                                                     }}
//                                                 />
//                                             </span>
//                                         )}
//                                     </Dropdown.Toggle>
//                                     <Dropdown.Menu>
//                                         <Dropdown.Item
//                                             className="d-flex justify-content-between align-items-center"
//                                             href="/bookmarks"
//                                         >
//                                             Bookmark{" "}
//                                             <span>
//                                                 <i
//                                                     style={{ fontSize: '14px', color: "#898b8d" }}
//                                                     className="fa-solid fa-bookmark"
//                                                 ></i>
//                                             </span>
//                                         </Dropdown.Item>
//                                         <Dropdown.Item
//                                             className="d-flex justify-content-between align-items-center"
//                                             href="/settings"
//                                         >
//                                             Manage Profile {" "}
//                                             <span>
//                                                 <i
//                                                     style={{ fontSize: '14px', color: "#898b8d" }}
//                                                     className="fa-solid fa-gear"
//                                                 ></i>
//                                             </span>
//                                         </Dropdown.Item>
//                                         <Dropdown.Item href="/">
//                                             {" "}
//                                             <button
//                                                 className="logout w-100"
//                                                 onClick={() => logout()}
//                                             >
//                                                 Logout
//                                             </button>
//                                         </Dropdown.Item>
//                                     </Dropdown.Menu>
//                                 </Dropdown>
//                             </div> */}

//                             <div className="d-flex flex-row top-bar-dashboard">
//                                 <div className="dropdown">
//                                     <button
//                                         className="btn dropdown-toggle p-0 ms-2 drop_down_dasboard d-flex justify-content-between align-items-center mt-2"
//                                         type="button"
//                                         id="dropdown-basic"
//                                         data-bs-toggle="dropdown"
//                                         aria-expanded="false"
//                                         style={{
//                                             backgroundColor: "transparent",
//                                             border: "transparent",
//                                         }}
//                                     >
//                                         <div className="data-user text-dark d-flex justify-content-center flex-column me-3">
//                                             <h5 className="text-end mb-0">
//                                                 {capitalizeFirstLetter(userName)}
//                                             </h5>
//                                             <h6 className="mb-0 text-end">{userAddress}</h6>
//                                         </div>

//                                         {profileImage?.length > 0 ? (
//                                             <span
//                                                 className='j_header_profile_img'
//                                                 style={{
//                                                     border: "1px solid black",
//                                                     borderRadius: "50%",
//                                                     transition: '0.3s'
//                                                 }}
//                                             >
//                                                 <img
//                                                     className="fluid"
//                                                     src={`${profileImage}`}
//                                                     alt="Profile"
//                                                     style={{
//                                                         borderRadius: "8px",
//                                                         height: "3rem",
//                                                         width: "3rem",
//                                                         borderRadius: "100px",
//                                                     }}
//                                                 />
//                                             </span>
//                                         ) : (
//                                             <span
//                                                 className='j_header_profile_img'
//                                                 style={{
//                                                     transition: '0.3s',
//                                                     border: "1px solid black",
//                                                     borderRadius: "50%",
//                                                 }}
//                                             >
//                                                 <img
//                                                     src="./asset/users.png"
//                                                     className="fluid"
//                                                     alt="Default Profile"
//                                                     style={{
//                                                         borderRadius: "8px",
//                                                         height: "3rem",
//                                                         width: "3rem",
//                                                         borderRadius: "100px",
//                                                     }}
//                                                 />
//                                             </span>
//                                         )}
//                                     </button>
//                                     <ul className="dropdown-menu" aria-labelledby="dropdown-basic">
//                                         <li>
//                                             <a
//                                                 className="dropdown-item d-flex justify-content-between align-items-center"
//                                                 href="/bookmarks"
//                                             >
//                                                 Bookmark{" "}
//                                                 <span>
//                                                     <i
//                                                         style={{ fontSize: '14px', color: "#898b8d" }}
//                                                         className="fa-solid fa-bookmark"
//                                                     ></i>
//                                                 </span>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a
//                                                 className="dropdown-item d-flex justify-content-between align-items-center"
//                                                 href="/settings"
//                                             >
//                                                 Manage Profile {" "}
//                                                 <span>
//                                                     <i
//                                                         style={{ fontSize: '14px', color: "#898b8d" }}
//                                                         className="fa-solid fa-gear"
//                                                     ></i>
//                                                 </span>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <span
//                                                 className="dropdown-item"
//                                                 // href="/"
//                                                 onClick={() => logout()}
//                                             >
//                                                 <button className="logout w-100">Logout</button>
//                                             </span>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>

//                         </Col>

//                     </Row>
//                 </Container>
//                 <Container fluid className=" mb-4">
//                     <div className=" mb-2 mb-lg-4 bg-white shadow-sm py-3 px-3" style={{ borderRadius: '10px' }}>
//                         <div className="row">
//                             <div className="col-lg-9 ">
//                                 <div className=" d-flex align-items-center justify-content-start justify-content-sm-start  flex-wrap" style={{ gap: '8px' }}>
//                                     {/* <div className=" me-2 registran_filter_text h-100" >
//                                         <div className="d-flex h-100 pt-3 align-items-center  filter_div_txt">
//                                             <p className="me-2 mb-0 "><svg xmlns="http://www.w3.org/2000/svg" style={{color:'#0f66b4'}} width="30" height="30" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
//                                                             <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
//                                                 </svg></p>
//                                             <p className="mb-0">
//                                                 filter <br /> <span>by</span>
//                                             </p>
//                                         </div>
//                                     </div> */}
//                                     <div className={` filter_div ${country?.value ? 'active' : ''}`}>
//                                         <label htmlFor="">
//                                             Country
//                                         </label>
//                                         <Select
//                                             className="pointer-cursor"
//                                             value={country}
//                                             options={options1}
//                                             onChange={handleCountry}
//                                             styles={colourStyles}
//                                         />
//                                     </div>
//                                     <div className={` filter_div ${province?.value ? 'active' : ''}`}>
//                                         <label htmlFor="">
//                                             Province
//                                         </label>
//                                         <Select
//                                             className="pointer-cursor"
//                                             isDisabled={country?.value?.length > 0 ? false : true}
//                                             value={province}
//                                             options={options2}
//                                             onChange={handleProvince}
//                                             styles={colourStyles}
//                                         />
//                                     </div>
//                                     <div className={` filter_div ${city?.value ? 'active' : ''}`}>
//                                         <label htmlFor="">City</label>
//                                         <Select
//                                             className="pointer-cursor"
//                                             isDisabled={province?.value?.length > 0 ? false : true}
//                                             value={city}
//                                             options={options3}
//                                             onChange={handleCity}
//                                             styles={colourStyles}
//                                         />
//                                     </div>
//                                     {/* <div className={` filter_div ${selectedOption1?.value ? 'active' : ''}`}>
//                                         <label htmlFor="">
//                                             Postal Code
//                                         </label>
//                                         <Select
//                                             className="pointer-cursor"
//                                             // isDisabled={city?.value?.length > 0 ? false : true}
//                                             value={postalCode}  
//                                             options={options4}
//                                             onChange={handlePostalCode}
//                                             styles={colourStyles} 
//                                         />
//                                     </div> */}
//                                     <div className={` filter_div ${membershipType?.value ? 'active' : ''}`}>
//                                         <label htmlFor="">
//                                             Membership Type
//                                         </label>
//                                         <Select
//                                             className="pointer-cursor"
//                                             //  isDisabled={postalCode?.value?.length > 0? false : true}
//                                             value={membershipType}
//                                             options={options5}
//                                             onChange={handleMembershipType}
//                                             styles={colourStyles}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-12 col-lg-3 d-flex align-items-center justify-content-md-end justify-content-end">
//                                 <div>
//                                     <label htmlFor="">

//                                     </label>
//                                     <button className="reset-filter " style={{ color: '#FF4740', backgroundColor: '#FFF0EB', whiteSpace: "nowrap", cursor: resetDisable ? "not-allowed" : "pointer", }} disabled={resetDisable} onClick={() => handleReset()} >
//                                         <i className=" svg_size fa-solid fa-rotate-right" style={{ color: '#FF4740', }}></i>Reset</button>
//                                 </div>
//                                 <div>
//                                     <label htmlFor="">

//                                     </label>

//                                     <div className="" style={{ paddingLeft: "10px" }}>
//                                     {isDownloading ? (<button className="export-data" style={{color: '#fff',backgroundColor: '#0F66B4', cursor: 'not-allowed'}} disabled={isDownloading} >Loading...</button>):( 
//                                                         <button className="export-data" style={{color: '#fff',backgroundColor: '#0F66B4', cursor:'pointer'}}  onClick={() => { memberExportCSV() }}>
//                                                          <i className="fa-solid fa-download" style={{color: '#fff',}}></i>Export</button>)}
//                                     </div>

//                                 </div>
//                             </div>

//                         </div>

//                     </div>
//                     {isloading ? (
//                         <>
//                             <Col lg={12} md={12} sm={12} className="d-flex justify-content-center align-content-center" style={{ height: "79vh" }}>

//                                 <BallTriangle height={100} width={100} radius={5} color="#0f66b4" ariaLabel="ball-triangle-loading" wrapperClassclassName={{}} wrapperStyle="" visible={true} />
//                             </Col>
//                         </>
//                     ) :
//                         <>
//                             {noData == true ? (<>
//                                 <div><p className=" text-center fw-bolder fs-3">No Data Found</p></div>
//                             </>) : (<>
//                                 <div className="account-information mb-3">
//                                     <div>
//                                         <div className="  d-flex align-items-center px-4 justify-content-between">
//                                             <span className="p-1"> Total {totalMembers > 0 ? "PCMA Executives and Board of Directors" : "PCMA Executives and Board of Director"} : {totalMembers}</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                                     <Link to="/" onClick={handleBack} className="d-inline-block mb-3 text-decoration-none" style={{ color: '#0F67B6', fontSize: '18px', fontWeight: '600', fontFamily: `'lato',sans-serif` }}><i className="fa-solid fa-arrow-left-long me-2"></i> Go Back</Link>
//                                 <Row className=' d-flex justify-content-center justify-content-lg-start'>
//                                     <Col lg={8} className=" pt-2 pt-lg-0  dashboard_list_scroll ">
//                                         <Row className="g-2 pb-3">
//                                             {totalMember?.map((items) => (
//                                                 <>
//                                                     <Col md={12} key={items?._id}>
//                                                         <MemberCard data={items} index={items?._id} />
//                                                     </Col>
//                                                 </>
//                                             ))}


//                                         </Row>
//                                     </Col>
//                                     <Col lg={4}>
//                                         <div className="map-container mt-4  mt-lg-0 h-100">
//                                             <MemberMap data={totalMember} />
//                                         </div>
//                                     </Col>
//                                     <Row className="py-4">

//                                         {totalMember?.length > 0 && (
//                                             <Col xxl={8} lg={8} md={12} sm={12} className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between py-2" style={{ gap: '10px' }}>
//                                                 <div className="pagination_section">
//                                                     <CustomPagination
//                                                         maxWidth={5}
//                                                         total={totalPages}
//                                                         current={memberPage}
//                                                         onPageChange={(page) => handlePageChange(page)}
//                                                     />

//                                                 </div>

//                                                 <div>
//                                                     <ul className="list-unstyled new_side_pagination ">
//                                                         <li style={{ whiteSpace: 'nowrap' }}>Go to page</li>
//                                                         <li>
//                                                             <label htmlFor="pageNEmber">
//                                                                 <input type="number" name="" id="pageNEmber"
//                                                                     placeholder={onfocas ? '' : memberPage}
//                                                                     value={goToPage ? goToPage : " "}
//                                                                     onFocus={() => placeholderDataHide(true)}
//                                                                     onBlur={() => { placeholderDataHide(false); setGotopag(true) }}
//                                                                     onChange={(e) => {
//                                                                         // setGoTOPage(e.target.value);
//                                                                         if (e.target.value > totalPages) {
//                                                                             toast.error(`Page ${e.target.value} does not exist`);
//                                                                             setGoTOPage("")
//                                                                         } else {
//                                                                             // setRegistrantPage(e.target.value);
//                                                                             setGoTOPage(e.target.value)
//                                                                         }
//                                                                     }}
//                                                                     onKeyDown={blockInvalidChar}
//                                                                 />

//                                                             </label>

//                                                         </li>
//                                                         <li>
//                                                             <div onClick={() => { handlePageSearch(goToPage) }}>
//                                                                 <i className="fa-solid fa-arrow-right"></i>
//                                                             </div>
//                                                         </li>
//                                                     </ul>
//                                                 </div>
//                                             </Col>

//                                         )}
//                                     </Row>
//                                 </Row>
//                             </>)}
//                         </>
//                     }
//                 </Container>
//             </div>
//             <Footer />
//             <div className="d-flex justify-content-center align-items-center">
//                 <Modal show={showModal} onHide={handleClose} centered size="lg" id="notificaion-modal">
//                     <Modal.Header closeButton>
//                         <Modal.Title>
//                             <span style={{ color: "#0F66B4" }}>Notifications</span>{" "}
//                         </Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body className="model-content">
//                         <div className="notification-img">
//                             <div
//                                 className="img_container "

//                             >
//                                 <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     width="36"
//                                     height="36"
//                                     viewBox="0 0 39 39"
//                                     fill="none"
//                                     className=""
//                                 >
//                                     <path
//                                         d="M14.6091 26.6064L19.5 23.6384L24.3909 26.6064L23.095 21.0467L27.4425 17.2844L21.7155 16.8246L19.5 11.5575L17.2844 16.8246L11.5575 17.2844L15.905 21.0467L14.6091 26.6064ZM19.5 38.3947L13.8984 32.8768H6.12316V25.1015L0.605225 19.5L6.12316 13.8984V6.12316H13.8984L19.5 0.605225L25.1015 6.12316H32.8768V13.8984L38.3947 19.5L32.8768 25.1015V32.8768H25.1015L19.5 38.3947ZM19.5 33.7128L23.6802 29.5326H29.5326V23.6802L33.7128 19.5L29.5326 15.3197V9.46736H23.6802L19.5 5.28711L15.3197 9.46736H9.46736V15.3197L5.28711 19.5L9.46736 23.6802V29.5326H15.3197L19.5 33.7128Z"
//                                         fill="#0F66B4"
//                                     />
//                                 </svg>
//                             </div>
//                             <span>{notificationDate}</span>
//                         </div>

//                         <div className="modal_body">
//                             <h4>{notificationName}</h4>
//                             <div className="notification_message overflow-auto">
//                                 <p>
//                                     {notificationmessage}
//                                 </p>
//                             </div>
//                         </div>
//                     </Modal.Body>

//                 </Modal>
//             </div>
//         </div>
//     );
// };
// export default MemberSearch;