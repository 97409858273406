import React, { useState,useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import axios from "axios";
import toast from "react-hot-toast";

const UserManagement = () => {
  const navigate = useNavigate()
  let token = '';
    const [isLoading, setIsLoading] = useState(true);
    const addUser=()=>{
      navigate('/settings/usermanagement/adduser')
    }
    const userData = JSON.parse(window.localStorage.getItem("login"));

    const redirectToLogin = () => {
      token = userData ? userData.token : null;
     if (token === null) {
       navigate('/login');
     }
   };

   useEffect(() => {
    redirectToLogin();
  })

  const [isHovered, setIsHovered] = useState(false);

    const userDatas = useSelector((state) => state.user);
    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false)
      }, 2000);
     
    }, [userDatas?.secondary_details])

    const handleResend=async(username)=>{
    try{
      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/generate-new-otp/${username}`, {
        headers: { Authorization: `Bearer ${token}`, },
      })
      toast.success(response?.data?.message)

    }catch(error){
    toast.error(error?.response?.data?.message)
    }
    }

    return <>
        <Container fluid className="pt-4">
            <div className=" j_account_information" style={{ background: "#137CD9" }}>
                <span className="px-3 d-flex align-items-center" style={{ color: "white", fontSize: '20px' }}>
                    User Management
                </span>
            </div>
            <Row className="g-2 py-2" style={{ paddingBottom: "10px" }}>
                <Col sm={12} md={12} lg={12} className="d-flex justify-content-end">
                {userDatas?.secondary_details?.length>=2? 
                <span
                // onClick={addUser}
                style={{
                  // cursor: "pointer",
                  background: "#0F66B4",
                  color: "white",
                  fontWeight: "500",
                  display:'inline-block'
                }}
                className=" user_firm_details_back_btn  position-static"
                disabled
              >
                Add Sub User
              </span>
                
                : 
                <span
                          onClick={addUser}
                          style={{
                            cursor: "pointer",
                            background: "#0F66B4",
                            color: "white",
                            fontWeight: "500",
                            display:'inline-block'
                          }}
                          className=" user_firm_details_back_btn  position-static"
                        >
                          Add Sub User
                        </span>
                }
                </Col>
            </Row>
            

            <Row className="g-2 py-2" style={{ paddingBottom: "10px" }}>
            <Col sm={12} md={12} lg={12}>
          <div className='table-responsive' style={{ borderRadius: '6px' }}>
            <table className="userinvoice_table" style={{ width: '100%' }}>
              <thead style={{ background: 'blue' }} >
                <tr className="table-heading-row" >

                  <th>UserName</th>
                  <th>Role</th>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Email</th>
                  <th className='py-2'>Organization</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {
                isLoading ?
                  <tbody>
                    <tr>
                      <td colSpan={7}>
                        <div className=' d-flex align-items-center justify-content-center'>
                          <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#0f66b4"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody> :

<tbody>
{userDatas?.secondary_details?.length === 0 ? (
  <tr>
    <td colSpan="8" style={{ textAlign: 'center' }}>
      No Data Found
    </td>
  </tr>
) : (
  userDatas?.secondary_details.map(item => (
    <tr key={item.id}>
      <td>{item.username}</td>
      <td>{item?.role}</td>
      <td>{item?.name}</td>
      <td>{item.gender}</td>
      <td>{item.email}</td>
      <td>{item.organization}</td>
      <td>{item.status === true ? "Active" : "InActive"}</td>
      <td
        onClick={() => handleResend(item.username)}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: isHovered ? '#0f66b4' : 'initial'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        Regenerate Password
      </td>
    </tr>
  ))
)}
</tbody>
  
              }
            </table>
          </div>
        </Col>

            </Row>
        </Container>


    </>
};

export default UserManagement;
