// registrantSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';


export const fetchRegistrants = createAsyncThunk(
  'registrants/fetchRegistrants',
  async (data) => {

    let obj ={
      "page": data?.page,

      "category": data?.category,
      "is_only_category":data?.is_only_category,

      "search": data?.search,

      "is_head_office":data?.is_head_office,
      "country": data?.country,
      "province": data?.province,
      "city": data?.city,
      
      // "location":data?.location,
      "latitude":data?.latitude ,
      "longitude":data?.longitude,
      "radius":data?.radius,
    }
    if(data?.token){

    try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/registrant-search`, {
          headers: {
            Authorization: `Bearer ${data?.token}`,
          },
          params: obj,
        });
        
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  // else{
  //   toast.error("Please Login Again")
  // }
  }
);

const registrantSlice = createSlice({
  name: 'registrants',
  initialState: {
    registrants: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegistrants.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRegistrants.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.registrants = action.payload;
        state.error = null;
      })
      .addCase(fetchRegistrants.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default registrantSlice.reducer;
