import React, { useEffect, useRef, useState } from 'react';
import { Container, } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import LeftSidebar from '../../components/sidebar/LeftSidebar';
import AdminTopBar from './AdminTopBar';
import '../../styles/leftsidebar.css';
import '../../styles/adminglobal.css'

const DasboardLayout = () => {
  
  const [sidebarToggleData, setSidebarToggleData] = useState(false);
  const sidebarRef = useRef(null);
  const sidbarToggle = (e) => {
    if (e === true) {setSidebarToggleData(true)}
    else {setSidebarToggleData(false)}
  }

  useEffect(() => {
  
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarToggleData(false);
      }
    }

    if (sidebarToggleData) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [sidebarToggleData]);


  return (
    <div className="dashboard-container ">
      <div ref={sidebarRef}>
      <AdminTopBar sidbarToggle={sidbarToggle} sidebarToggleData={sidebarToggleData}  />
      <div className={`left_sidebar  ${sidebarToggleData ? "active" : ""}`}  >
        <LeftSidebar sidbarToggle={sidbarToggle}/>
      </div>
      </div>
      <div className="main_content ">
        <Container
          fluid
          className=""
          style={{
            backgroundColor: "#F8FAFC",
          }}
        >
          <Outlet />
        </Container>
      </div>
    </div>
  );
};

export default DasboardLayout;
