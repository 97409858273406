import React, { useEffect, useState } from 'react';

const DescriptionComponent = ( props) => {
  const [expanded, setExpanded] = useState(false);
  const [showBtn,setShowBtn] = useState(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderDescription = () => {
    if (expanded) {
      return props?.description;
    } else {
        const words = props?.description;
      // Extract the first 200 characters
      const truncatedDescription = words?.slice(0, 200);
      if(props?.description?.length>200){
      return `${truncatedDescription}...`;
      }else{
        return `${truncatedDescription}`;
      }
    }
  };
   useEffect(()=>{
     if(props?.description?.length>200){
        setShowBtn(true);
     }else{
        setShowBtn(false);
     }
   },[props?.description])

  return (
    <div>
      <p className='about_user'>{renderDescription()} {showBtn ? <span onClick={toggleExpanded} className='registrantdetail_readbtn m-0 '>Read{expanded ? 'Less' : 'More'}</span> : ''}</p> 
    </div>
  );
};

export default DescriptionComponent;