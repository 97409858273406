import React, { useEffect,useState } from 'react';
import { Container, Row, Col,Dropdown, Modal } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';
import '../../styles/leftsidebar.css';
import '../../styles/adminglobal.css';
import { toast } from "react-hot-toast";
import axios from 'axios'
import Navbar from '../../components/navbar/Navbar';

const UserTopBar = ({sidbarToggle,sidebarToggleData}) => {
    let token;
    let profileid;
   let userName;

   const navigate = useNavigate();

   const userData = JSON.parse(window.localStorage.getItem("login"));
   if (userData) {token = userData?.token;} else { token = null; }
 
   if (userData?.user_data?.name) {
     userName = userData?.user_data?.name;
   }
   if (userData?._id) {
    profileid = userData?._id;
  }

   


   const [noticeDatas, setNoticeData] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [notificationName, setNotificationName] = useState("");
   const [notificationpurpose, setNotificationpurpose] = useState("");
   const [notificationmessage, setNotificationmessage] = useState("");
   const [username, setUsername] = useState("");
   const [profile_picture, setprofile_picture] = useState("");
   const [userAddress, setUserAddress] = useState('');

   const [isLoading, setIsLoading] = useState(true);

 

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);



const getallData = (props) => {
        setIsLoading(true);
        if (profileid !== undefined) {
          axios
            .get(`${process.env.REACT_APP_PCMA_BACKEND}/api/profile-settings`, {
              params: {
                _id: profileid,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              if (response?.data?.message === "Unauthozied User") {
                localStorage.removeItem("login");
                navigate("/");
               toast.error("Unauthozied user !");
              }
              const profiledata = response?.data;
              setUsername(profiledata?.username);
              setUserAddress(profiledata?.address);
              setprofile_picture(profiledata?.profile_picture);
              setIsLoading(false);
            })
            
            .catch((error) => {
              setIsLoading(false);
              console.error(`Error: ${error}`);
              if (error?.message === "Request failed with status code 401") {
                localStorage.removeItem("login");
                navigate("/");
              }
            });
        }
  };

 useEffect(() => {
        getallData();
      }, []);


      const handleNoticeModal = (data) => {
        setShowModal(true);
        setNotificationpurpose(data?.purpose);
        setNotificationName(data?.name);
        setNotificationmessage(data?.message);
      };


   
  const fetchNoticData = async () => {
    if(token){

    
   try {
     const response = await axios.get(
       `${process.env.REACT_APP_PCMA_BACKEND}/api/all-notice-data-user`,
       {
         headers: {
           Authorization: `Bearer ${token}`,
         },
       }
     );
     if (response?.data?.message === "Unauthozied user") {
       localStorage.removeItem("login");
       navigate("/");
       toast.error(`Unauthozied user !`);
     }
     setNoticeData(response?.data?.notice_data?.data);
   } catch (error) {
     console.error("Error fetching data:", error);
   }}else{
    toast.error("Please login again")
    navigate('/login')
   }
 };

 useEffect(() => {
   fetchNoticData();
 }, []);
  

    return (
   <>

      <section className="admin_header">
              <Container fluid>
            

              <Navbar sidbarToggle={sidbarToggle}  sidebarToggleData={sidebarToggleData}/>
              
          
              </Container>
        </section>

      
   </>
    );
};

export default UserTopBar;
