import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchUserData = createAsyncThunk(
  'userData/fetch',
  async ({ localUserId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/logged-user-data`, {
        params: { id: localUserId },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return rejectWithValue(error.message); 
    }
  }
);

const initialState = {
  isLoading: false,
  name: '',
  address: '',
  profilePicture: '',
  bookmark_details:'',
  flag:'',
  error: null,
  role:'',
  _id:'',
  organization:'',
  secondary_details:[],
  registrant_bookmark:[]
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.name = action.payload.name;
      state.address = action.payload.address;
      state.profilePicture = action.payload.profile_picture;
      state.bookmark_details = action.payload.bookmark_details;
      state.flags = action.payload.flags;
      state.role = action.payload.role;
      state._id = action.payload._id
      state.organization = action.payload.organization
      state.secondary_details = action.payload.secondary_details
      state.registrant_bookmark = action.payload.registrant_bookmark
      state.error = null;
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
