import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";

const UserLogoDownload = () => {


  useEffect(() => {
    document.title = "PCMA || Member Logo";
  }, []);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/asset/pcmalogo1.png"; // Replace with the actual path to your image
    link.download = "pcmalogo1.png"; // Specify the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div fluid className="container-fluid px-sm-3 pt-4">
      <div className="row g-2" style={{ paddingBottom: "10px" }}>
        <div className="col-12">
          <div className="j_download_logo_setting">
            <h2 className="mb-0 text-center text-sm-start">Member Logo</h2>
            <p className="my-3 text-center text-sm-start ">
              Please download PCMA Member logo and place it on your company’s
              website or <br />in your email signature. Please contact us if you need a
              different format.
            </p>
            <div className="mt-4">
              <img
                src="/asset/pcmalogo1.png"
                alt="pcmalogo"
                className="img-fluid"
              />
            </div>

            <div className="p-2 mt-3">
              <button
                onClick={handleDownload}
                style={{
                  borderRadius: "4px",
                  border: "0px",
                  color: "white",
                  padding: '5px 15px',
                  background: "linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)",
                }}
              >
                {" "}
                Download Logo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogoDownload;
