import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';




export const fetchBlogs = createAsyncThunk('fetchBlogs', async ({ id, currentPage , sortBlog }) => {
    const loginData = JSON.parse(localStorage.getItem('login'));
let token = loginData?.token;
if (loginData) {
    token = loginData.token; 
    }
    
    try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/blog-posts/${id}?page=${currentPage}&sort_by=${sortBlog}`, {
            headers: {
                 Authorization: `Bearer ${token}`,
            },
      });
      return response.data;
    } catch (error) {
      throw error; 
    }
});

const blogsSlice = createSlice({
    name:'blogs',
    initialState:{
        isLoading:false,
        data:[],
        error:null,
    },
    extraReducers : (builder) => {
        builder.addCase(fetchBlogs.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
            state.data = [];
        });

        builder.addCase(fetchBlogs.fulfilled, (state,action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchBlogs.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.error?.message;
        });
    }
});
export default blogsSlice.reducer;