import React from "react";
import Navbar from "../../components/navbar/NavbarGlobal";
import Footer from "../../components/footer/Footer";
import { Link,useNavigate } from "react-router-dom";


function MemberShipInformationModal() {
    const navigate = useNavigate();
    const handleContinue =()=>{
        navigate('/login')
    }
    return (
        <div className=" d-flex align-items-center justify-content-between flex-column" style={{ height:'100vh'}}>
            <Navbar />
            <div className="container mb-3" style={{ marginTop: '200px' }}>


                <div className="info_modal_box shadow-lg">

                   
               
                            
                                <div className="modal_header border-0 d-flex align-items-center justify-content-center pb-0">
                                <i className="fa-solid fa-circle-check" style={{fontSize:'53px', color:'#039855'}}></i>
                                </div>
                                <div className="modal_body border-0 my-4">
                                    <div className="securely_modal_body">
                                        <h5 className="mb-0">Payment Done!</h5>
                                        <p>Your payment is successfully done.</p>
                                    </div>
                                </div>
                                <div className="modal_footer border-0 d-block ">
                                    <div className="securely_modal_btn  d-flex flex-column flex-sm-row  align-items-center justify-content-center">
                                        <button   type="button" className="btn btn-primary" onClick={handleContinue}>Continue</button>
                                    </div>
                                </div>
                            
           
                    

                </div>
            </div>





            <Footer />
        </div>

    );
}

export default MemberShipInformationModal;
