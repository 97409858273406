import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const AlternateModal = ({ modalOpen, onClose, names }) => {
    return (
        <Modal
            show={modalOpen} 
            onHide={onClose}
            // backdrop="true"
            keyboard={false} >
            <Modal.Header  style={{backgroundColor:'#147DDB',color:'white'}}>
            <Modal.Title  style={{color:'white'}} >Alternate Names</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-5'>                        
                    <ul style={{listStyle:"disc"}}>
                    {names.map((name, index) => (
                        <li key={index}>{name}</li>
                    ))}
                </ul>
                <button className="btn_notice mt-3" onClick={onClose}>Close</button>
              </Modal.Body>
            
      </Modal>
    
  );
};
export default AlternateModal;