// import React, { useState, useEffect } from 'react';
// import { BallTriangle } from 'react-loader-spinner';
// import { useParams, useNavigate, Link } from "react-router-dom";
// import { Container, Row, Col } from "react-bootstrap";
// import axios from 'axios';
// import toast from "react-hot-toast";
// import ResponsivePagination from 'react-responsive-pagination';
// import Navbar from '../../components/navbar/Navbar';
// import MemberDetailsMap from '../../components/memberDashboard/MemberDetailMap';
// import Footer from '../../components/footer/Footer';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchBlogs } from '../../redux/slice/blogs';
// import CustomPagination from '../../components/pagination/CustomPagination';
// const MemberDetails = () => {

//     let { id } = useParams();
//     const navigate = useNavigate()
//     const dispatch = useDispatch()
//     const loginData = JSON.parse(window.localStorage.getItem("login"));
//     let token;
//     const [isloading, setIsLoading] = useState(true);
//     const [memberData, setMemberData] = useState([]);
//     const [memberName, setMemberName] = useState('');
//     const [memberPic, setMemberPic] = useState('');
//     const [memberType, setMemberType] = useState('');
//     const [memberEmail, setMemberEmail] = useState('');
//     const [memberPhoneCode, setMemberPhoneCode] = useState('');
//     const [memberPhone, setMemberPhone] = useState('');
//     const [memberGender, setMemberGender] = useState('');
//     const [memberOrgaization, setMemberOrgaization] = useState('');
//     const [memberAddress, setMemberAddress] = useState('');
//     const [memberAddressContact, setMemberAddressContact] = useState('');
//     const [memberWebsite, setMemberWebsite] = useState('');
//     const [memberCountry, setMemberCountry] = useState(''); 
//     const [memberCity, setMemberCity] = useState('');
//     const [memberPostalCode, setMemberPostalCode] = useState('');
//     const [memberFax, setMemberFax] = useState(''); 
//     const [memberProvince, setMemberProvince] = useState('');
//     const [memberComDescription, setMemberComDescription] = useState('');
//     const [memberindustryPreferences, setMemberIndustryPreferences] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [sortName, setShortName] = useState(null);
//     const blogsState = useSelector((state) => state?.blogs);

//     const blogsData = blogsState?.data?.data;
//     const isLoading = blogsState?.isLoading;
//     const lastPage = blogsState?.data?.last_page;
//     const noData =useSelector((state) => state?.blogs.error);

//     if (loginData) { token = loginData?.token;}
//     const capitalize = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };
//     useEffect(() => {
//          document.title = "PCMA || Member Details";
//     }, []);

//     useEffect(() => {
//         dispatch(fetchBlogs({ id: id, currentPage: currentPage }));
//     }, [id, currentPage, dispatch]);
 

//     const fetchMemberDetails = () => {
//         axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/members-data/${id}`,
//             {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             }).then(response => {
//                 const memberProfile = response?.data;
//                     if (response?.data?.message === "Unauthozied User") {
//                         localStorage.removeItem('login');
//                         navigate("/");
//                         toast.error(`Unauthozied user !`);
//                     } else {
//                         setMemberData(memberProfile);
//                         setMemberName(memberProfile?.name); 
//                         setMemberPic(memberProfile?.profile_picture)
//                         setMemberType(memberProfile?.membership_type);
//                         setMemberEmail(memberProfile?.email);
//                         setMemberPhone(memberProfile?.phone);
//                         setMemberPhoneCode(memberProfile?.phone_code);
//                         setMemberGender(memberProfile?.gender);
//                         setMemberOrgaization(memberProfile?.organization);
//                         setMemberAddress(memberProfile?.address);
//                         setMemberAddressContact(memberProfile.address_cont);
//                         setMemberWebsite(memberProfile?.website);
//                         setMemberCity(memberProfile?.city);
//                         setMemberPostalCode(memberProfile?.postal_code);
//                         setMemberFax(memberProfile.fax);
//                         setMemberCountry(memberProfile?.country);
//                         setMemberComDescription(memberProfile.company_description);
//                         setMemberProvince(memberProfile?.province);
//                         setMemberIndustryPreferences(memberProfile?.industry_preferences)
//                     }
//                     setIsLoading(false);
//                 })
//                 .catch(error => {
//                     setIsLoading(false);
//                     console.error('Error fetching data:', error);
//                 });
//     };
//     useEffect(() => {
//             fetchMemberDetails()
//         }, []);
//     useEffect(() => {
//         let firstNameLatter =  memberName?.charAt(0) ;
//         let lasttNameLatter =  memberName?.split(' ')[1]?.charAt(0)?.toUpperCase();
//         if (lasttNameLatter === undefined) {
//             lasttNameLatter= ''
//             setShortName(firstNameLatter + lasttNameLatter)
//         }
//         else{
//             setShortName(firstNameLatter + lasttNameLatter)
//         }
//         }, [memberName]);
   
//     const handleBack = () => {
//         if (window.history.length === 1) {
//             navigate('/login');
//         } else {
//             navigate(-1);
//         }
//     };

//     const handlePageChange = (data) => {
//         setCurrentPage(data);   
//    };

//     return (
//         <>
//             {isloading && isLoading ? (
//                 <>
//                 <Container fluid>
//                     <Row
//                         className="g-2 pb-3 d-flex justify-content-center align-content-center "
//                         style={{ height: "90vh" }}
//                     >
//                         <Col
//                             lg={12}
//                             md={12}
//                             sm={12}
//                             className="d-flex justify-content-center align-content-center"
//                         >
//                             <BallTriangle
//                                 height={100}
//                                 width={100}
//                                 radius={5}
//                                 color="#0f66b4"
//                                 ariaLabel="ball-triangle-loading"
//                                 wrapperClass={{}}
//                                 wrapperStyle=""
//                                 visible={true}
//                             />
//                         </Col>
//                     </Row>
//         </Container> 
//                 </>
//         ):( <>         
//         <div className="container-fluid px-sm-4 px-xxl-5">
//             <Navbar classData='hide_menu_icon' />
//             <div className="row">
//                 <div className='col-12'>
//                     <span onClick={handleBack} style={{cursor: "pointer",color: "#0F66B4",fontWeight: "500",}} className="p-1">
//                                     <i className="fa-solid fa-arrow-left-long me-2"></i>Go Back
//                     </span>            
//                 </div>          
//                 <div className="col-lg-8 pt-2 pt-lg-0 dashboard_list_scroll mt-2"  style={{ overflowX:'clip', border: '2px solid rgba(15, 102, 180, 0.20)', borderRadius: '10px' }}>
//                     <div className="row g-2" >
//                         <div className=" col-12 registant-detail-section-w-image registant-detail-section-w-image-container">
//                     </div>
//                     <div className="row p-sm-5">
//                         <div className="col-12">
//                             <div className="general-info">
//                                 <h1>General Information</h1>
//                             </div>    
//                         </div>
//                         <div className="col-lg-12  ">
//                             <div className=' d-sm-flex align-items-center my-3'>
//                             <div >
//                                         {memberPic ? 
//                                         <img style={{
//                                            borderRadius: "100px",
//                                             width: '100px',
//                                             height:'100px',
//                                             cursor: "pointer",
//                                         }} src={memberPic} className='img-fluid' /> :  <div className="Registrant_short_name_box"><p className="mb-0" style={{textTransform:'capitalize'}}>{sortName}</p></div>}
//                             </div>   
//                             <div className="member_card_info ms-2 ms-sm-5 my-2 mt-sm-0">
//                                 <h1 className="mb-0">{memberName}</h1>
//                                 <p className="mb-0">{memberType}</p>
//                             </div> 
//                             </div>
//                         </div>
//                         <div className="col-12">
//                             <nav className="toggle_info">
//                                 <div className="selection_button nav nav-tabs" id="nav-tab" role="tablist">
//                                 <button className="member_button nav-link active" id="nav-member_info-tab" data-bs-toggle="tab" data-bs-target="#nav-member" type="button" role="tab" aria-controls="nav-member" aria-selected="true">Member Information </button>
//                                 <button className="blog_button nav-link" id="nav-blog-tab" data-bs-toggle="tab" data-bs-target="#nav-blog" type="button" role="tab" aria-controls="nav-blog" aria-selected="false">Blog List</button>
//                                 </div>
//                             </nav>
//                             <div className="tab-content" id="nav-tabContent">
//                                 <div className="tab-pane fade show active" id="nav-member" role="tabpanel" aria-labelledby="nav-member_info-tab">
//                                     <div className="row">
//                                         <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-6">
//                                             <div className='general_infomation pt-4 pb-4'>
//                                                 <h5 className=''>GENDER</h5> 
//                                                 {memberGender ? (<p className='mb-0'>{capitalize(memberGender)}</p>):(<p className='mb-0'>NA</p>)}     
//                                             </div>   
//                                         </div>
//                                         <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-6">
//                                             <div className='general_infomation pt-4 pb-4'>
//                                                 <h5 className=''>EMAIL</h5> 
//                                                  <p className='mb-0'><Link className='text-decoreation-none' to={`mailto:${memberEmail}`} >{memberEmail}</Link></p>
//                                             </div>    
//                                         </div> 
//                                         <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-6">
//                                             <div className='general_infomation  pt-4 pb-4'>
//                                                 <h5 className=''>PHONE</h5> 
//                                                  <p className='mb-0'>{memberPhoneCode} {memberPhone}</p>       
//                                             </div>    
//                                         </div> 
//                                         <div className='col-12'>
//                                         <div className="general-info">
//                                             <h1>Professional Information</h1>
//                                                 </div>                  
//                                         </div>
//                                         <div className="col-lg-3">
//                                             <div className='general_infomation pt-4 pb-4'>
//                                                 <h5 className=''>ORGANIZATION </h5> 
//                                                  <p className='mb-0'>{memberOrgaization}</p>       
//                                             </div>   
//                                         </div>
//                                         <div className="col-lg-3">
//                                             <div className='general_infomation  pt-4 pb-4'>
//                                                 <h5 className=''>ADDRESS</h5> 
//                                                     <p className='mb-0'>{memberAddress}</p>       
//                                             </div>    
//                                         </div> 
//                                         <div className="col-lg-3">
//                                             <div className='general_infomation  pt-4 pb-4'>
//                                                 <h5 className=''>ADDRESS CONTACT</h5> 
//                                                 {memberAddressContact ? (<p className='mb-0'>{memberAddressContact}</p>):(<p className='mb-0'>NA</p>)}           
//                                             </div>    
//                                         </div>
//                                          <div className="col-lg-3">
//                                             <div className='general_infomation  pt-4 pb-4'>
//                                                 <h5 className=''>WEBSITE LINK</h5> 
//                                                     {memberWebsite ? (<p className='mb-0'><Link className='text-decoreation-none' to={memberWebsite?.includes("http") ? memberWebsite : "https://" + memberWebsite} target='_blank'>{memberWebsite?.replace(/^https?:\/\//, '')}</Link></p>) : (<p className='mb-0'>NA</p>)}               
//                                             </div>    
//                                         </div>
//                                         <div className='col-12'></div>
//                                         <div className="col-lg-3">
//                                             <div className='general_infomation pt-4 pb-4'>
//                                                 <h5 className=''>COUNTRY</h5>
//                                                 <p className='mb-0'>{memberCountry}</p>
//                                             </div>
//                                         </div> 
//                                         <div className="col-lg-3">
//                                             <div className='general_infomation  pt-4 pb-4'>
//                                                 <h5 className=''>PROVINCE</h5>
//                                                 <p className='mb-0'>{memberProvince}</p>
//                                             </div>
//                                         </div>                 
//                                           <div className="col-lg-3">
//                                             <div className='general_infomation pt-4 pb-4'>
//                                                 <h5 className=''>CITY/TOWN</h5> 
//                                                     <p className='mb-0'>{memberCity}</p>       
//                                             </div>   
//                                         </div>
//                                         <div className="col-lg-3">
//                                             <div className='general_infomation  pt-4 pb-4'>
//                                                 <h5 className=''>COUNTRY POSTAL CODE</h5> 
//                                                     <p className='mb-0'>{memberPostalCode}</p>       
//                                             </div>    
//                                         </div> 
//                                         <div className="col-lg-3">
//                                             <div className='general_infomation  pt-4 pb-4'>
//                                                 <h5 className=''>FAX</h5> 
//                                                 {!memberFax == null ? (<p className='mb-0'>{memberFax}</p>):(<p className='mb-0'>NA</p>)}        
//                                             </div>    
//                                         </div>                                               
//                                         <div className='col-12'>
//                                         <div className='general_infomation  pt-4 pb-4'>            
//                                             <h5>COMPANY DESCRIPTION</h5> 
//                                             <div className='overflow-auto'>        
//                                             <p className='mb-0 w-100' style={{maxHeight:'66px'}}>{memberComDescription}</p>              
//                                             </div>             
//                                         </div>           
//                                         </div>
//                                         <div className='col-12'>
//                                             <div className='general_infomation pt-4 pb-4'>
//                                                 <h5>INDUSTRY PREFERENCES</h5>   
//                                                 <div className="d-flex align-items-center flex-wrap" style={{ gap: '10px' }}>
//                                                     {memberindustryPreferences?.map((memberindustryPreferences, index) => (
//                                                         <span className=" d-inline-flex " key={index} style={{ backgroundColor: "#E7F0F8", padding: '15px 16px', borderRadius: '5px', color: '#0F66B4', fontSize: '15px', }}>{memberindustryPreferences}</span>
//                                                     ))}                    
//                                                 </div>            
//                                             </div>         
                                                        
//                                         </div>           
                                                    
                                                    
//                                     </div>
//                                 </div>
//                                 <div className="tab-pane fade show" id="nav-blog" role="tabpanel" aria-labelledby="nav-blog-tab">
//                                    {isLoading ? (
//                                          <i className="fa-solid fa-spinner fa-spin" aria-label="Loading..."></i>
//                                      ) : noData ? (<>
//                                         <div className='d-flex align-items-center justify-content-center mt-5'>
//                                             <p className='mb-0 '>There are no blogs available right now!</p>
//                                         </div>
//                                      </>) : (<>   
                                                            
//                                         <div className='blogs p-2'>
//                                             <ul className='list-unstyled blogs_list'>
//                                                 {blogsData?.map((items, index)=>(                       
//                                                     <li className='d-flex align-items-center justify-content-between align-items-center' key={index}>
//                                                         <Link to={items?.url} target='_blank' className='text-decoration-none'>{items.title}</Link>             
//                                                     </li>
//                                                 ))}         
//                                             </ul> 
//                                         </div>           
//                                     <div className='mt-2 d-flex align-items-center justify-content-start'>
//                                         <CustomPagination
//                                             total={lastPage}
//                                             current={currentPage}
//                                             onPageChange={(page) => handlePageChange(page)}
//                                         />                   
//                                     </div>
//                                 </>)}                    
//                                 </div>
//                             </div>
//                         </div>
//                     </div> 
//                 </div>  
//                 </div>
//                 <div className="col-lg-4 pt-2 pt-lg-0 map-container">
//                 {/* Google Maps        */}
//                     <MemberDetailsMap members={memberData} />           
//                 </div>        
                
//             </div>
//         </div>
//         <Footer/>            
//         </>)}            
//         </>
        
//     )
    
// };
// export default MemberDetails;