import React, { useEffect, useRef, useState } from 'react';
import { Container, } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import LeftSidebar from '../../components/sidebar/CustomerLeftsidebar';
import UserTopBar from './UserTopBar';
import '../../styles/leftsidebar.css';
import '../../styles/adminglobal.css'
import { useDispatch, useSelector } from 'react-redux';
import { clearAddress } from '../../redux/slice/registrantNearmeLocation';


const UserDashboardLayout = () => {
  const [sidebarToggleData, setSidebarToggleData] = useState(false);
  const sidebarRef = useRef(null);
  const sidbarToggle = (e) => {
    if (e === true) {
      setSidebarToggleData(true)
    }
    else {
      setSidebarToggleData(false)
    }
  }


  const dispatch = useDispatch();

  dispatch(clearAddress());
  
  useEffect(() => {
  
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarToggleData(false);
      }
    }

    if (sidebarToggleData) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [sidebarToggleData]);

  const userDatas = useSelector((state) => state.user);
  const role = userDatas?.role;


  return (


    <div className="dashboard-container ">
      <div ref={sidebarRef}>
      <UserTopBar sidbarToggle={sidbarToggle} sidebarToggleData={sidebarToggleData} />

      <div className={`left_sidebar  ${sidebarToggleData ? "active" : ""}`} style={{ paddingTop: "7.6rem" }}>
        <LeftSidebar role={role} />
      </div>
      </div>

      <div className="main_content d-flex  justify-content-between flex-column pt-2" style={{ height: 'calc(100vh - 70px)' }}>
        <Container
          fluid
          className="main_content_first_container"
          style={{
            backgroundColor: "#F8FAFC",
            paddingTop: '22px'
          }}
        >
          <Outlet />


        </Container>
        <div className='container-fluid'>
          <div className="row">
          <div
        className="d-lg-flex justify-content-between px-5"
        style={{ backgroundColor: "#2678C0" }}
      >
       
        <div className=" d-flex flex-column flex-wrap flex-sm-row aliign-items-center justify-content-center justify-content-lg-start  trams_and_conditions py-1">
        <p className="p-sm-2 ps-0  text-white text-center text-lg-start  mb-0" style={{ fontSize: "14px" }}>
          All Rights Reserved.
        </p>
        <div className="d-flex flex-column  flex-sm-row aliign-items-center justify-content-center justify-content-md-start  trams_and_conditions py-1">
                      <span className=" d-inline-block align-items-center pe-sm-3 text-center"><a href="https://pcma1dev.wpenginepowered.com/terms-and-condition/">Terms & Conditions</a></span>
                      <span className=" d-inline-block align-items-center px-sm-3 text-center" style={{borderLeft:'1px solid #ffffff80',borderRight:'1px solid #ffffff80'}}><a href="https://pcma1dev.wpenginepowered.com/privacy-policy-page/">Privacy Policy</a></span>
                      <span className=" d-inline-block align-items-center ps-sm-3 text-center"><a href="https://pcma1dev.wpenginepowered.com/insclusivity/">Inclusivity & Accessibility</a></span>
                    </div>
                    </div>
        <p className="p-sm-2 text-white text-center    mb-0" style={{ fontSize: "14px" }}>
          Made with {"  "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
          >
            <path
              d="M8 14.68L6.84 13.624C2.72 9.888 0 7.416 0 4.4C0 1.928 1.936 0 4.4 0C5.792 0 7.128 0.648 8 1.664C8.872 0.648 10.208 0 11.6 0C14.064 0 16 1.928 16 4.4C16 7.416 13.28 9.888 9.16 13.624L8 14.68Z"
              fill="white"
            />
          </svg>{" "}
          {"  "}
          by{" "}
          <a href="https://klizos.com/the-joygiver-page/" target="/blank">
            <img src="/asset/logo7.png" alt="" style={{ maxHeight: "20px" }} />{" "}
          </a>{" "}
          | A Partner and Sponsor of PCMA
        </p>
      </div>
          </div>
        </div>
      </div>
    </div>



  );
};

export default UserDashboardLayout;
