import { Navigate, useLocation } from "react-router-dom";

const RequiredAuth = ({ children }) => {
  //   const { isAuth } = useSelector((state) => state.authReducer);
  const token = localStorage.getItem("payment_status");
  const data = JSON.parse(localStorage.getItem("login"));
  const location = useLocation();

  if (token && data?.token) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  }
};

export default RequiredAuth;