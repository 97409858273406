import React, { useState,useEffect } from "react";
import Navbar from "../../components/navbar/NavbarGlobal";
import "../../styles/Global.css";
import { Form, Button } from "react-bootstrap";
import { useNavigate} from "react-router-dom";
import  Footer  from "../../components/footer/Footer";
import toast from "react-hot-toast";
import axios from "axios";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const MemberType = () => {
  const navigate = useNavigate();
   useEffect(() => {
    document.title = "PCMA || PCMA Membership";
  }, []);

  const [selectedOption, setSelectedOption] = useState("");
  const [firmdata,setFirmData] = useState('');
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState('');

  const handleRadioChange = (item,index) => {
    setDataId(index)
    setFirmData(item);
    setSelectedOption(item.subscription_name);
  };

  const handleSubmit = (event) => {
    event.preventDefault();;
    localStorage.setItem("registerOption", JSON.stringify(firmdata));
    if (selectedOption) {
      navigate("/register-page");
    }else{
      toast.error("Please select an option")
    }
  };

  useEffect(() => {
    api()
  }, [])
  
  const api = async()=>{
    try{
      const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/pacakges `)
      setData(response?.data)
    }catch(error){
      console.log("Error",error)
    }
  }

  return (
    <>
      <Navbar />
      <div className="container-fluid mb-3 slider_img_div_box" style={{maxWidth:'1690px', padding:'0px 20px', marginTop: '115px' }}>
        <div className="row register_main p-0">
          <div className="col-lg-6 col-xl-7 pcma-register-part1 pe-lg-5">
            <h1 className="heading-text mb-4">PCMA Membership</h1>
            <p>
              PCMA Canada is a leading force in the private capital markets,
              managing various aspects such as events, fundraising, education,
              and advocacy. They collaborate closely with their members to stay
              informed about market trends, foster efficiency, and advocate for
              the industry. During challenging times, PCMA remains committed to
              educating and uniting professionals in the sector. They take pride
              in being the largest private capital markets community in Canada
              and work tirelessly to ensure their voice remains strong. PCMA's
              mission, driven by their dedicated team, is to provide top-tier
              services, promote industry awareness, and support economic
              progress. They firmly believe in the enduring strength of private
              capital markets, even in tough times, and are dedicated to helping
              the industry not only survive but thrive. Their goal is to empower
              members, advocate for their interests, and contribute to the
              nation's economic resilience. In essence, PCMA is your trusted
              industry association, committed to your success.
            </p>
            <div className="row p-0">
              <div className="col-6 p-0">
                <img
                  src="/asset/registerimage1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-6 p-0">
                <img
                  src="/asset/registerimage2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-5 pcma-register-part2 mt-4 mt-lg-0">
            <h3>Choose Membership Type</h3>

            <Form onSubmit={handleSubmit}>
          
              <div className="radio" >
                 {/*
              
  {data?.map((item, index)=>{
               
                <div className="radio-button d-sm-flex align-items-center justify-content-between py-2"  key={index}>
                  <div className="d-flex align-items-center">
                    <Form.Check
                    className="mb-0 optiondata"
                      type="radio"
                      id={`accountingFirm-${index}`}
                      name="fav_language"
                      value={item.subscription_name}
                      checked={selectedOption === item.subscription_name}
                      onChange={handleRadioChange}
                    />
                    <p className="firm-option px-2">
                      <label htmlFor="accountingFirm">{item?.subscription_name}</label>
                       <span><i className="fa-solid fa-circle-info"></i></span></p>
                  </div>
                  <div className="firm-option-balance">$ 750 + taxes</div>
                </div>   
                   } )}
              </div>
               */}


{data?.map((item, index) => (
  <div className="radio-button d-sm-flex align-items-center justify-content-between py-2" key={index}>
    <div className="d-flex ">
      <Form.Check
        className="mb-0 optiondata"
        type="radio"
        id={`accountingFirm-${index}`}
        name="fav_language"
        value={item?.subscription_name}
        checked={selectedOption === item?.subscription_name}
        onChange={()=>handleRadioChange(item,index)}
      />
      <p className="firm-option d-flex align-items-center px-2 justify-content-start">
        <label htmlFor={`accountingFirm-${index}`}>{item?.subscription_name}</label>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className="tool_tip show d-block fade" >
               <div className="tooltip_container">
              <div className="tooltip_header px-1 py-2">
                <p className="mb-0 text-center fw-bold">{item?.subscription_name}</p>
              </div>
              <div className="tooltip_body px-2 py-2">
               <p className="mb-0 text-center" ><strong>${item?.unit_amount} + taxes</strong></p>
                </div>
                </div>
              </Tooltip>
        
          }
        >
        <span style={{cursor:'pointer'}}><i className="fa-solid fa-circle-info"></i></span>
        </OverlayTrigger>
      </p>
    </div>
    {item?.is_popular && (<>
        <div className="most_popular py-1 px-2">
          <p className="mb-0" style={{fontSize:'13px', whiteSpace:'nowrap'}}> Most popular</p> 
      </div>
    </>)} 
   
    <div className={`firm-option-balance ${index === dataId?' d-block': 'd-none' } d-sm-block`}>{`$ ${item.unit_amount} + taxes`}</div> 
  </div>
))}

</div>
              <Button
                type="submit"
                className="button1 register-continue-button"
              >
                Continue
              </Button>
            </Form>
          </div>
        </div>
      </div>
     <Footer Width='1690px' Padding="0px"/>
    </>
  );
};

export default MemberType;
